import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environment/environment';
import { Router } from '@angular/router';
import { FormatS3UrlPipe } from 'src/app/shared/pipes/format-s3-url.pipe';
import { Clipboard } from '@angular/cdk/clipboard';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private subject$ = new Subject<any>();
  public api_url = environment.apiUrl;
  public key = environment.secretKey;
  public s3BaseUrl = environment.infra === "aws" ? environment.s3BaseUrl : environment.GCPBaseUrl;
  public videoLength$: BehaviorSubject<any> = new BehaviorSubject(null);
  public folderPathUrl: any;
  public folderIds: any;
  public folderRoute: any;
  public s3Url = environment.s3Url;
  public previousHitURL: any = ''; //
  private formatS3UrlPipe = new FormatS3UrlPipe();

  user: any = '';

  //Regular expression
  public patterns: any = {
    contactNumber: '^[0-9]{10}$',
    name: '^[a-zA-Z]*$',
    email: '^(?!.* )[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$',
    password:
      '^(?!.* )(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*_`~:;+=-]).{8,20}$',
    profAgency: '^[a-zA-Z0-9@.-_ ]+$',
  };

  getAccessToken() {
    throw new Error('Method not implemented.');
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private clipboard: Clipboard
  ) {
    this.checkExpiration();
  }
  post(url: any, data: any, httpOptions: any) {
    // now returns an Observable of Config
    return this.http.post<any>(this.api_url + url, data, httpOptions);
  }

  postMap(url: any, data: any, httpOptions: any) {
    // now returns an Observable of Config
    return this.http.post<any>(url, data, httpOptions);
  }

  get(url: any) {
    return this.http.get<any>(this.api_url + url);
  }

  getbyId(url: any, params: any) {
    return this.http.get<any>(this.api_url + url, params);
  }

  patch(url: any, params: any) {
    return this.http.patch<any>(this.api_url + url, '', { params: params });
  }

  put(url: any, params: any) {
    return this.http.put<any>(this.api_url + url, params);
  }

  delete(url: any) {
    return this.http.delete<any>(this.api_url + url);
  }

  logout() {}

  public isLoggedIn(): boolean {
    const user = localStorage.getItem('userDetails');
    return !!user;


  }
  // getLoggedUser(){
  //     if(this.user){
  //        return this.user;
  //     } else{

  //     }
  // }

  emit(event: any) {
    this.subject$.next(event);
  }

  on(eventName: string, action: any): Subscription {
    return this.subject$
      .pipe(
        filter((e: any) => e.name === eventName),
        map((e: any) => e['value'])
      )
      .subscribe(action);
  }

  getUserdata() {
    if (localStorage.getItem('userDetails') != null) {
      const userDataObj = JSON.parse(localStorage.getItem('userDetails') || '');
      if (userDataObj && userDataObj.data) {
        return this.decrypt(userDataObj.data);
      }
    } else {
      if (localStorage.getItem('logout-event') != null) {
        this.router.navigate(['/auth/login']);
      } else if (window.location.href.indexOf('folderPath') > -1) {
        this.folderPathUrl = window.location.href;
        this.folderRoute = this.folderPathUrl
          .split('?')[0]
          .replace(window.location.origin, '')
          .trim();
        this.folderIds = this.folderPathUrl.split('=')[1];
        this.router.navigate(['/auth/login']);
      } else {
        /**Bug 175001: DAM : Photographer : The Access Denied page does not appear when a non-logged in user clicks on the shared link with no access him.
         * the url hit by user is going to be stored inside the "previousHitURL" which is going to
         * be used to redirect the user to that url after login is completed.
         **/
        if (
          !window.location.href.includes('/auth/login') &&
          this.previousHitURL.length === 0
        ) {
          this.previousHitURL = window.location.href;
          this.router.navigate(['/auth/login']);
        }
      }
    }
  }
  setUserdata(data: any) {
    const expiration = new Date();
    expiration.setDate(expiration.getDate()+1); // Add one day

    const userData = {
      data: this.encrypt(data),
      expiration: expiration.getTime()
    };

    localStorage.setItem('userDetails', JSON.stringify(userData));
  }

  cleartUserdata() {
    localStorage.removeItem('userDetails');
  }

  isValidSearch(searchText: any) {
    const pattern = new RegExp('\\s*\\S+(?: \\S+)*\\s*');
    return pattern.test(searchText);
  }

  public encrypt(txt: string): any {
    return CryptoJS.AES.encrypt(JSON.stringify(txt), this.key).toString();
  }

  public decrypt(txtToDecrypt: string) {
    const bytes = CryptoJS.AES.decrypt(txtToDecrypt, this.key);
    try {
      return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    } catch (err) {
      return null;
    }
  }

  // Check expiration and clear user data if expired
  private checkExpiration() {
    const userData = localStorage.getItem('userDetails');
    if (userData) {
      const userDataObj = JSON.parse(userData);
      if (Date.now() >= userDataObj.expiration) {
        localStorage.removeItem('userDetails'); // Clear expired data
      }
    }
  }

  //common function for copyLinkToClipboard
  copyLinkToClipboard(asset: any): void {
    const linkToCopy = this.formatS3UrlPipe.transform(
      this.s3BaseUrl + '' + asset.assetPath + '/' + asset.assetName
    );
    this.clipboard.copy(linkToCopy);
  }
}
