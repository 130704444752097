import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { CommonUtils } from 'src/app/common/utils/common.utils';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css'],
})
export class PaginatorComponent implements OnChanges {
  @ViewChild('pp') paginator: Paginator;
  @Input() totalRecords: any;
  @Input() totalItems: any;
  @Input() currentPage: any;
  @Input() limit: any;
  @Input() itemsPerPage: any = 10;
  myparams: any = {
    limit: this.itemsPerPage == 10 ? 10 : this.itemsPerPage,
    pageno: 1,
  };
  current_page_count: number = 0;
  prev_page_count: any = 1;
  commonUtils = new CommonUtils();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['totalItems']) {
      if (
        this.totalItems < (this.itemsPerPage == 10 ? 10 : this.itemsPerPage)
      ) {
        this.current_page_count = this.totalItems;
      } else {
        this.current_page_count = this.limit;
      }
    }
  }

  constructor() {}

  @Output() pageChanged: EventEmitter<string> = new EventEmitter<string>();
  OnpageChanged(event: any) {
    if (this.currentPage !== event.page) {
      this.pageChanged.emit(event);
      this.myparams = {
        limit: this.limit,
        pageno: event.page,
      };
      this.commonUtils.scrollToAppMain();
      if (
        this.totalItems < (this.itemsPerPage == 10 ? 10 : this.itemsPerPage)
      ) {
        this.current_page_count = this.totalItems;
        this.prev_page_count = 1;
      } else {
        this.current_page_count = this.limit * event.page;
        this.prev_page_count = this.limit * event.page - this.limit + 1;
        if (this.current_page_count > this.totalItems) {
          this.current_page_count = this.totalItems;
        }
      }
    }
  }

  disableRightClick(event: Event): void {
    event.preventDefault();
  }
}
