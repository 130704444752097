import { AppDataService } from './app.data.service';
import { AppService } from './app.service';
import { ClientadminService } from './clientadmin.service';
import { EventEmitterService } from './event-emitter.service';
import { HeaderService } from './header.service';
import { LoaderService } from './loader.service';
import { LoggerService } from './logger.service';
import { S3UploadServices } from './s3-upload-services.service';

export * from './app.service';
export * from './loader.service';
export * from './logger.service';
export * from './event-emitter.service';
export * from './header.service';
export * from './s3-upload-services.service';
export * from './clientadmin.service';
export * from './app.data.service';

export const services = [
  AppService,
  LoaderService,
  LoggerService,
  EventEmitterService,
  HeaderService,
  ClientadminService,
  S3UploadServices,
  AppDataService,
];
