<div class="col asset-master-top-bar-wrapper" *ngIf="userType!='mediaUploader'">
  <div class="d-flex justify-content-end d-grid gap-3">
    <div class="form-group d-flex align-items-center">
      <input class="form-check-input small" type="checkbox" value="" id="selectAll" (click)='selectAll($event)'
        [(ngModel)]="selectAllCheck">
      <label class="nh-SelectAll-label" for="selectAll">Select All</label>
    </div>
    <app-btn-dropdown aria-labelledby="dropdownMenuLink" title="Page Size" [options]="pageSizeOptions"
      (filterSelected)="changeSize($event)" [customBtnTemplate]="pageSizeFilterBtnTemplate"
      [base]="selectedFilter.pageSize">
    </app-btn-dropdown>
    <ng-template #pageSizeFilterBtnTemplate let-data="data">
      <a class="filter-btn" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <span>Page Size</span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
      </a>
    </ng-template>
    <app-btn-dropdown aria-labelledby="dropdownMenuButton1" title="Tag status" [options]="tagStatusOptions"
      (filterSelected)="selectAssetStatusForSorting($event)" [multiselect]="true" [base]="selectedFilter.assetStatus"
      [customBtnTemplate]="tagStatusFilterBtnTemplate">
    </app-btn-dropdown>
    <ng-template #tagStatusFilterBtnTemplate let-data="data">
      <a class="filter-btn position-relativerole" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
        <span>Tag status</span>
        <img src="./assets/images/icons/down-arrow-black.svg" class="ms-1">
        <img *ngIf="selectedFilter.assetStatus && selectedFilter.assetStatus.length>0" class="active"
          src="./assets/images/icons/ic_checked_circle.svg" width="12px" alt="">
      </a>
    </ng-template>
  </div>
</div>
<div *ngIf="totalRecords !== 0" class="zoom-range-slider z-1 p-1">
  <app-grid-zoom-slider [value]="rangeSliderValue" (valueChange)="onChangeRangeSlider($event)">
  </app-grid-zoom-slider>
</div>
<div [ngClass]="(rangeSliderValue == 3 ? 'assets-card-lg' : '') || (rangeSliderValue == 1 ? 'assets-card-sm' : '')"
  class="row gx-4 row-cols-1 row-cols-md-2 row-cols-xl-3 row-cols-xxl-3 card-sec mx-0 image-grid">
  <ng-container *ngFor="let asset of assetDetails ; let i=index">
    <div (mouseleave)="handleMouseLeave()" class="col mt-4 cardmenu" (click)="disableContextMenu()"
      oncontextmenu="return false;">
      <div class="h-100" (contextmenu)="getRightClickPermission($event,asset)"
        (click)="searchResult(asset.id, {assetPath:asset.assetPath,rangeSlider:rangeSliderValue})">
        <div class="card">
          <div class="cardimage">
            <a class="card-hidden-cta"></a>
            <div *ngIf="asset.assetType==0"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img [src]="appService?.s3BaseUrl+''+asset?.assetPath+'/'+asset?.thumbnailAssetName | formatS3Url"
                class="media assetprofilebg" alt="" width="" height="">
              <img [src]="appService?.s3BaseUrl+''+asset?.assetPath+'/'+asset?.thumbnailAssetName | formatS3Url"
                class="media mw-100 mh-100 w-auto h-auto m-auto end-0 bottom-0" alt="" width="" height="">
            </div>
            <div *ngIf="asset.assetType==1 && asset.thumbnailAssetName!==''"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img [src]="appService?.s3BaseUrl+''+asset?.assetPath+'/'+asset?.thumbnailAssetName | formatS3Url"
                class="media mw-100 mh-100 w-auto h-auto m-auto end-0 bottom-0" alt="" width="" height="">
              <img *ngIf="asset.assetType==1 && asset.thumbnailAssetName!==''" src="assets/images/ic_play_circle.svg"
                alt="Play" class="m-auto z-1" width="38px" height="38px">
            </div>
            <div *ngIf="asset.assetType==1 && asset.thumbnailAssetName===''"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img src="assets/images/icons/Img.svg" class="media mw-100 mh-100 " alt="" width="" height="">
            </div>
            <div *ngIf="asset.assetType==2"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img src="assets/images/icons/document/{{tagArray[i].fileExt}}_icon.svg" class="media w-100 h-100 " alt=""
                width="" height="">
            </div>
            <div *ngIf="asset.assetType==3"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img src="assets/images/icons/audio_icon.svg" alt="Audio" class="media" width="" height="">
            </div>
            <div *ngIf="asset.assetType==4"
              class="card-image-wrap d-flex flex-column justify-content-center align-items-center">
              <img src="assets/images/icons/Others_icon.svg"
                class="media mw-100 mh-100 w-100 h-100 bottom-0 end-0 m-auto" alt="" width="" height="">
            </div>
            <span class="card-top-overlay"></span>
            <span class="card-top-overlay"></span>
            <div class="card-img-overlay d-flex flex-column z-1 card-overlay-cta">
              <div class="m-0 d-flex justify-content-end">
                <div *ngIf="userType!='mediaUploader'">
                  <div class="badge card-tag completed rounded-0" *ngIf="asset.assetTag==1">Completed</div>
                  <div class="badge card-tag pending rounded-0" *ngIf="asset.assetTag==0">Pending</div>
                </div>
                <div class="d-flex">
                  <span class="ms-auto">
                    <div class="form-check">
                      <input class="form-check-input form-check-input1" type="checkbox"
                        (click)="[$event.stopPropagation()]" value="{{asset}}" binary="true" id="{{asset.id}}"
                        [(ngModel)]="asset.isSelected" (change)="assetSelected(asset)">
                    </div>
                  </span>
                </div>
              </div>
              <div class="mt-auto d-flex justify-content-between card-icon position-relative">
                <div class="d-flex gap-2 flex-wrap position-relative align-items-end" *ngIf="userType!='mediaUploader'">
                  <div
                    [ngClass]="{'openSharePopUp':(openSharePopUpAsset==asset.assetName), 'closeSharePopUp':(openSharePopUpAsset!=asset.assetName)}"
                    (click)="[$event.stopPropagation(), openSharePopUpAsset='' ]">
                    <share-buttons [theme]="'circles-dark'" [include]="['whatsapp','email']" [show]="3"
                      [url]="appService?.s3BaseUrl+''+asset.assetPath+'/'+asset.assetName | formatS3Url "
                      [title]="'Share'" (click)="saveShareLink(asset)"></share-buttons>
                    <share-button button="functionButton" (click)="[copyLinkToClipboard(asset), saveShareLink(asset)]"
                      class="copy-link-wrapper" title="Copy Link"></share-button>
                    <span class="gdrive" (click)="[ShareAssetsOnGdrive(asset), saveShareLink(asset)]">
                      <svg width="19" height="18" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_812_151258)">
                          <path
                            d="M2.11538 21.4262L3.34936 23.5576C3.60577 24.0063 3.97436 24.3589 4.40705 24.6153L8.8141 16.9871H0C0 17.4839 0.128205 17.9807 0.384615 18.4294L2.11538 21.4262Z"
                            fill="#0066DA"></path>
                          <path
                            d="M13.9904 8.01273L9.58333 0.384521C9.15064 0.640932 8.78205 0.993496 8.52564 1.44221L0.384615 15.5448C0.132921 15.9838 0.000335116 16.481 0 16.9871H8.8141L13.9904 8.01273Z"
                            fill="#00AC47"></path>
                          <path
                            d="M23.5737 24.6153C24.0064 24.3589 24.375 24.0063 24.6314 23.5576L25.1442 22.6762L27.5961 18.4294C27.8526 17.9807 27.9808 17.4839 27.9808 16.9871H19.166L21.0417 20.673L23.5737 24.6153Z"
                            fill="#EA4335"></path>
                          <path
                            d="M13.9905 8.01282L18.3976 0.384615C17.9649 0.128205 17.4681 0 16.9553 0H11.0258C10.513 0 10.0162 0.144231 9.5835 0.384615L13.9905 8.01282Z"
                            fill="#00832D"></path>
                          <path
                            d="M19.1668 16.9871H8.81428L4.40723 24.6153C4.83992 24.8717 5.33671 24.9999 5.84953 24.9999H22.1316C22.6444 24.9999 23.1412 24.8557 23.5739 24.6153L19.1668 16.9871Z"
                            fill="#2684FC"></path>
                          <path
                            d="M23.5255 8.4935L19.455 1.44221C19.1986 0.993496 18.83 0.640932 18.3973 0.384521L13.9902 8.01273L19.1665 16.9871H27.9646C27.9646 16.4903 27.8364 15.9935 27.58 15.5448L23.5255 8.4935Z"
                            fill="#FFBA00"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_812_151258">
                            <rect width="27.9808" height="25" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </div>
                  <div>
                    <div>
                      <button type="button" title="Share" (click)="openSharePopUp(asset.assetName,$event)"
                        *ngIf="selectedAssets.length<2" class="btn btn-icon-overlay  justify-content-center">
                        <i class="nm_icon">
                          <img src="assets/images/icons/ic_share.svg">
                        </i>
                      </button>
                      <div class="dropdown-menu card_share_tooltip w-auto">
                        <div class="d-flex justify-content-between w-auto">
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M21.252 3.63507C18.9178 1.2889 15.8054 0 12.4911 0C5.66642 0 0.106914 5.55952 0.100974 12.3842C0.100974 14.57 0.671179 16.6964 1.75219 18.5792L0 25L6.56924 23.2775C8.38083 24.2635 10.4181 24.7862 12.4911 24.7862H12.497C19.3217 24.7862 24.8812 19.2267 24.8871 12.3961C24.8812 9.08767 23.5923 5.97529 21.252 3.63507ZM12.4911 22.6895C10.6379 22.6895 8.82631 22.1905 7.24636 21.2521L6.87217 21.0264L2.97576 22.048L4.0152 18.2466L3.77167 17.8546C2.73817 16.2153 2.19767 14.3205 2.19767 12.3782C2.19767 6.71181 6.81871 2.09076 12.497 2.09076C15.2471 2.09076 17.8308 3.16584 19.779 5.1081C21.7213 7.05631 22.7904 9.64006 22.7904 12.3901C22.7845 18.0744 18.1634 22.6895 12.4911 22.6895ZM18.1397 14.9798C17.8308 14.8254 16.3103 14.077 16.0252 13.9701C15.7401 13.8691 15.5322 13.8156 15.3302 14.1245C15.1223 14.4334 14.5284 15.1342 14.3502 15.3362C14.172 15.5441 13.9879 15.5678 13.679 15.4134C13.3701 15.259 12.3723 14.9323 11.1903 13.875C10.2696 13.0554 9.65192 12.0397 9.46779 11.7308C9.2896 11.422 9.44997 11.2556 9.6044 11.1012C9.74101 10.9646 9.91326 10.7389 10.0677 10.5607C10.2221 10.3825 10.2756 10.2518 10.3766 10.044C10.4775 9.83607 10.43 9.65788 10.3528 9.50345C10.2756 9.34902 9.65786 7.82252 9.39651 7.2048C9.14705 6.59896 8.89164 6.68211 8.70158 6.67617C8.52339 6.66429 8.3155 6.66429 8.10761 6.66429C7.89972 6.66429 7.5671 6.74151 7.282 7.05037C6.9969 7.35923 6.20099 8.10763 6.20099 9.63412C6.20099 11.1606 7.3117 12.6277 7.46613 12.8356C7.62056 13.0435 9.64598 16.1677 12.7524 17.5101C13.4889 17.8308 14.0651 18.0209 14.5165 18.1635C15.2589 18.401 15.9301 18.3654 16.4647 18.2882C17.0587 18.1991 18.2941 17.5398 18.5554 16.8152C18.8108 16.0905 18.8108 15.4728 18.7336 15.3421C18.6564 15.2115 18.4485 15.1342 18.1397 14.9798Z"
                                  fill="white" />
                              </svg>
                            </i>
                          </a>
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <svg width="28" height="25" viewBox="0 0 28 25" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_812_151258)">
                                  <path
                                    d="M2.11538 21.4262L3.34936 23.5576C3.60577 24.0063 3.97436 24.3589 4.40705 24.6153L8.8141 16.9871H0C0 17.4839 0.128205 17.9807 0.384615 18.4294L2.11538 21.4262Z"
                                    fill="#0066DA" />
                                  <path
                                    d="M13.9904 8.01273L9.58333 0.384521C9.15064 0.640932 8.78205 0.993496 8.52564 1.44221L0.384615 15.5448C0.132921 15.9838 0.000335116 16.481 0 16.9871H8.8141L13.9904 8.01273Z"
                                    fill="#00AC47" />
                                  <path
                                    d="M23.5737 24.6153C24.0064 24.3589 24.375 24.0063 24.6314 23.5576L25.1442 22.6762L27.5961 18.4294C27.8526 17.9807 27.9808 17.4839 27.9808 16.9871H19.166L21.0417 20.673L23.5737 24.6153Z"
                                    fill="#EA4335" />
                                  <path
                                    d="M13.9905 8.01282L18.3976 0.384615C17.9649 0.128205 17.4681 0 16.9553 0H11.0258C10.513 0 10.0162 0.144231 9.5835 0.384615L13.9905 8.01282Z"
                                    fill="#00832D" />
                                  <path
                                    d="M19.1668 16.9871H8.81428L4.40723 24.6153C4.83992 24.8717 5.33671 24.9999 5.84953 24.9999H22.1316C22.6444 24.9999 23.1412 24.8557 23.5739 24.6153L19.1668 16.9871Z"
                                    fill="#2684FC" />
                                  <path
                                    d="M23.5255 8.4935L19.455 1.44221C19.1986 0.993496 18.83 0.640932 18.3973 0.384521L13.9902 8.01273L19.1665 16.9871H27.9646C27.9646 16.4903 27.8364 15.9935 27.58 15.5448L23.5255 8.4935Z"
                                    fill="#FFBA00" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_812_151258">
                                    <rect width="27.9808" height="25" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </i>
                          </a>
                          <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                            <i class="d-flex justify-content-center align-items-center">
                              <svg width="26" height="19" viewBox="0 0 26 19" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M1.05843 2.55538C1.37428 1.16381 2.61881 0.125 4.10596 0.125H22.856C24.3431 0.125 25.5876 1.16381 25.9035 2.55538L13.481 10.1469L1.05843 2.55538ZM0.980957 4.33919V15.4384L10.0484 9.88044L0.980957 4.33919ZM11.5456 10.7954L1.28076 17.0873C1.7819 18.1442 2.85858 18.875 4.10596 18.875H22.856C24.1033 18.875 25.18 18.1442 25.6812 17.0873L15.4163 10.7954L13.481 11.9781L11.5456 10.7954ZM16.9135 9.88044L25.981 15.4384V4.33919L16.9135 9.88044Z"
                                  fill="white" />
                              </svg>
                            </i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown-menu card_share_tooltip w-auto">
                      <div class="d-flex justify-content-between w-auto">
                        <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                          <i class="d-flex justify-content-center align-items-center">
                            <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M21.252 3.63507C18.9178 1.2889 15.8054 0 12.4911 0C5.66642 0 0.106914 5.55952 0.100974 12.3842C0.100974 14.57 0.671179 16.6964 1.75219 18.5792L0 25L6.56924 23.2775C8.38083 24.2635 10.4181 24.7862 12.4911 24.7862H12.497C19.3217 24.7862 24.8812 19.2267 24.8871 12.3961C24.8812 9.08767 23.5923 5.97529 21.252 3.63507ZM12.4911 22.6895C10.6379 22.6895 8.82631 22.1905 7.24636 21.2521L6.87217 21.0264L2.97576 22.048L4.0152 18.2466L3.77167 17.8546C2.73817 16.2153 2.19767 14.3205 2.19767 12.3782C2.19767 6.71181 6.81871 2.09076 12.497 2.09076C15.2471 2.09076 17.8308 3.16584 19.779 5.1081C21.7213 7.05631 22.7904 9.64006 22.7904 12.3901C22.7845 18.0744 18.1634 22.6895 12.4911 22.6895ZM18.1397 14.9798C17.8308 14.8254 16.3103 14.077 16.0252 13.9701C15.7401 13.8691 15.5322 13.8156 15.3302 14.1245C15.1223 14.4334 14.5284 15.1342 14.3502 15.3362C14.172 15.5441 13.9879 15.5678 13.679 15.4134C13.3701 15.259 12.3723 14.9323 11.1903 13.875C10.2696 13.0554 9.65192 12.0397 9.46779 11.7308C9.2896 11.422 9.44997 11.2556 9.6044 11.1012C9.74101 10.9646 9.91326 10.7389 10.0677 10.5607C10.2221 10.3825 10.2756 10.2518 10.3766 10.044C10.4775 9.83607 10.43 9.65788 10.3528 9.50345C10.2756 9.34902 9.65786 7.82252 9.39651 7.2048C9.14705 6.59896 8.89164 6.68211 8.70158 6.67617C8.52339 6.66429 8.3155 6.66429 8.10761 6.66429C7.89972 6.66429 7.5671 6.74151 7.282 7.05037C6.9969 7.35923 6.20099 8.10763 6.20099 9.63412C6.20099 11.1606 7.3117 12.6277 7.46613 12.8356C7.62056 13.0435 9.64598 16.1677 12.7524 17.5101C13.4889 17.8308 14.0651 18.0209 14.5165 18.1635C15.2589 18.401 15.9301 18.3654 16.4647 18.2882C17.0587 18.1991 18.2941 17.5398 18.5554 16.8152C18.8108 16.0905 18.8108 15.4728 18.7336 15.3421C18.6564 15.2115 18.4485 15.1342 18.1397 14.9798Z"
                                fill="white" />
                            </svg>
                          </i>
                        </a>
                        <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                          <i class="d-flex justify-content-center align-items-center">
                            <svg width="28" height="25" viewBox="0 0 28 25" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_812_151258)">
                                <path
                                  d="M2.11538 21.4262L3.34936 23.5576C3.60577 24.0063 3.97436 24.3589 4.40705 24.6153L8.8141 16.9871H0C0 17.4839 0.128205 17.9807 0.384615 18.4294L2.11538 21.4262Z"
                                  fill="#0066DA" />
                                <path
                                  d="M13.9904 8.01273L9.58333 0.384521C9.15064 0.640932 8.78205 0.993496 8.52564 1.44221L0.384615 15.5448C0.132921 15.9838 0.000335116 16.481 0 16.9871H8.8141L13.9904 8.01273Z"
                                  fill="#00AC47" />
                                <path
                                  d="M23.5737 24.6153C24.0064 24.3589 24.375 24.0063 24.6314 23.5576L25.1442 22.6762L27.5961 18.4294C27.8526 17.9807 27.9808 17.4839 27.9808 16.9871H19.166L21.0417 20.673L23.5737 24.6153Z"
                                  fill="#EA4335" />
                                <path
                                  d="M13.9905 8.01282L18.3976 0.384615C17.9649 0.128205 17.4681 0 16.9553 0H11.0258C10.513 0 10.0162 0.144231 9.5835 0.384615L13.9905 8.01282Z"
                                  fill="#00832D" />
                                <path
                                  d="M19.1668 16.9871H8.81428L4.40723 24.6153C4.83992 24.8717 5.33671 24.9999 5.84953 24.9999H22.1316C22.6444 24.9999 23.1412 24.8557 23.5739 24.6153L19.1668 16.9871Z"
                                  fill="#2684FC" />
                                <path
                                  d="M23.5255 8.4935L19.455 1.44221C19.1986 0.993496 18.83 0.640932 18.3973 0.384521L13.9902 8.01273L19.1665 16.9871H27.9646C27.9646 16.4903 27.8364 15.9935 27.58 15.5448L23.5255 8.4935Z"
                                  fill="#FFBA00" />
                              </g>
                              <defs>
                                <clipPath id="clip0_812_151258">
                                  <rect width="27.9808" height="25" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </i>
                        </a>
                        <a class="dropdown-item w-auto d-flex justify-content-center align-items-center" href="#">
                          <i class="d-flex justify-content-center align-items-center">
                            <svg width="26" height="19" viewBox="0 0 26 19" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M1.05843 2.55538C1.37428 1.16381 2.61881 0.125 4.10596 0.125H22.856C24.3431 0.125 25.5876 1.16381 25.9035 2.55538L13.481 10.1469L1.05843 2.55538ZM0.980957 4.33919V15.4384L10.0484 9.88044L0.980957 4.33919ZM11.5456 10.7954L1.28076 17.0873C1.7819 18.1442 2.85858 18.875 4.10596 18.875H22.856C24.1033 18.875 25.18 18.1442 25.6812 17.0873L15.4163 10.7954L13.481 11.9781L11.5456 10.7954ZM16.9135 9.88044L25.981 15.4384V4.33919L16.9135 9.88044Z"
                                fill="white" />
                            </svg>
                          </i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn btn-icon-overlay  justify-content-center" title="Download"
                    (click)="redirectToNewTab(asset , $event)" *ngIf="selectedAssets.length<2">
                    <i class=" nm_icon">
                      <img src="assets/images/icons/ic_download.svg">
                    </i>
                  </button>
                </div>
                <div class="d-flex gap-2 flex-wrap position-relative" *ngIf="userType!='mediaUploader'">
                  <button
                    *ngIf="selectedAssets.length<2 && (asset?.assetType==0 || asset?.assetType==1 || asset?.assetType==3)"
                    title="Preview" type="button" class="btn btn-icon-overlay justify-content-center ms-2"
                    (click)="openSliderModal(asset, $event)">
                    <i class="nm_icon">
                      <img alt="Preview" src="assets/images/icons/view.svg" />
                    </i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="card-event pb-1">
              <small class="text-muted font-regular event-details d-flex justify-content-between gap-1">
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-start align-items-center">
                  <img src="assets/images/icons/icon_calendar.svg">
                  <p class="m-0">{{ asset.eventTime ? (asset.eventTime | date) : (asset.createdAt | date) }}</p>
                </span>
                <span *ngIf="userType!='mediaUploader' && asset.eventLocation" class="m-0 p-0"></span>
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-center align-items-center"
                  *ngIf="userType!='mediaUploader' && asset.eventLocation">
                  <img src="assets/images/icons/icon_location.svg">
                  <p class="m-0">{{asset.eventLocation}}</p>
                </span>
                <span class="m-0 p-0"></span>
                <span class="border-0 d-flex m-0 p-0 flex-grow-1 justify-content-end align-items-center">
                  <img src="assets/images/icons/icon_file_size.svg">
                  <p class="m-0">{{asset.assetSize | formatBytes:2}}</p>
                </span>
              </small>
            </div>
            <div class="card-title mb-0 pb-2 pt-2 position-relative custom-tooltip-hover" *ngIf="asset.manualTags">
              <div class="title-text">
                {{asset.title || asset.assetName}}
              </div>
              <span class="hover-text-details">{{asset.title || asset.assetName}}</span>
            </div>
            <div *ngIf="userType!='mediaUploader'">
              <ul class="list-group list-group-flush" style="border: none;">
                <li class="list-group-item px-0 py-1 d-flex flex-grow-1 justify-content-start align-items-center">
                  <img src="assets\images\icons\ai-icon.svg" class="left-curve me-2">
                  <span class="dot"></span>
                  <span class="tag-text card-text ms-2">
                    {{(tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===0 ) ? 'AI tagging pending.' :
                    (tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===2 ) ? 'No AI tags available.' :
                    (asset.isAutoTaged===1 && tagArray[i].longTagArrAi.length>=1 ) ?
                     ( tagArray[i].longTagArrAi.toString().replaceAll(',',', ') | titlecase) :'No AI tags available.' }}

                  </span>
                  <span class="hover-text-details">
                    {{(tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===0 ) ? 'AI tagging pending.' :
                    (tagArray[i].longTagArrAi.length < 1 && asset.isAutoTaged===2 ) ? 'No AI tags available' :
                    (asset.isAutoTaged===1 && tagArray[i].longTagArrAi.length>=1 ) ?
                    ( tagArray[i].longTagArrAi.toString().replaceAll(',',', ') | titlecase) :'No AI tags available.' }}
                  </span>
                </li>
                <li class="list-group-item px-0 py-1 d-flex flex-grow-1 justify-content-start align-items-center">
                  <img src="assets\images\icons\tag-icon.svg" class="me-2">
                  <span class="dot"></span>
                  <span class="tag-text card-text ms-2">
                    {{(tagArray[i].longTagArrManual.length < 1 ) ? ' Manual tagging pending.' :
                      tagArray[i].longTagArrManual.toString().replaceAll(',',', ')}}
                  </span>
                  <span class="hover-text-details">
                    {{(tagArray[i].longTagArrManual.length < 1) ? ' Manual tagging pending.' :
                      tagArray[i].longTagArrManual.toString().replaceAll(',',', ')}}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-footer cursorpointer">
            <div class="transcript-cntr gap-2">
              <div class="d-flex justify-content-start align-items-center w-50">
                <div class="d-flex" *ngIf="asset.assetType!=2 && asset.assetType!=4 && asset.assetType!=3">
                  <span class="ic-dimension me-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="none">
                      <path
                        d="M0.840882 0.00507151C0.585442 0.0920236 0.36513 0.261251 0.213595 0.486906C0.0620595 0.712562 -0.0123685 0.982241 0.00167835 1.25475V13.7516C0.00167835 14.083 0.131702 14.4009 0.363144 14.6352C0.594587 14.8696 0.908491 15.0013 1.2358 15.0013H7.40641V17.5006H4.93817C3.58063 17.5006 2.46992 18.6253 2.46992 20H17.2794C17.2794 18.6253 16.1687 17.5006 14.8111 17.5006H12.3429V15.0013H18.5135C18.8408 15.0013 19.1547 14.8696 19.3862 14.6352C19.6176 14.4009 19.7476 14.083 19.7476 13.7516V1.25475C19.7476 0.923318 19.6176 0.605456 19.3862 0.371095C19.1547 0.136734 18.8408 0.00507151 18.5135 0.00507151L1.2358 0.00507151C1.1619 -0.0016905 1.08756 -0.0016905 1.01366 0.00507151C0.964338 0.00206956 0.914884 0.00206956 0.865564 0.00507151L0.840882 0.00507151ZM2.46992 2.50444H17.2794V12.5019H2.46992V2.50444Z"
                        fill="#2C5F91" />
                    </svg>
                  </span>
                  <p class="mb-0 mt-1">{{asset.height}} x {{asset.width}}</p>
                </div>
              </div>
              <div *ngIf="asset.assetType!=2 && asset.assetType!=4 && asset.assetType!=3" class=""
                style="background: #DDE5F6;width: 1px;"></div>
              <div class="d-flex justify-content-end align-items-center w-50">
                <span class="ic-user me-3"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.4186 18H3.45923C2.76214 18 2.24672 17.303 2.51035 16.662C3.73075 13.698 6.59811 12 9.93843 12C13.2797 12 16.1471 13.698 17.3675 16.662C17.6311 17.303 17.1157 18 16.4186 18ZM5.90695 5.99998C5.90695 3.79398 7.71583 1.99998 9.93843 1.99998C12.162 1.99998 13.9699 3.79398 13.9699 5.99998C13.9699 8.20599 12.162 9.99998 9.93843 9.99998C7.71583 9.99998 5.90695 8.20599 5.90695 5.99998ZM19.7688 17.636C19.0362 14.277 16.7435 11.798 13.727 10.673C15.3256 9.39596 16.2577 7.33093 15.915 5.06993C15.5181 2.44693 13.3182 0.347977 10.6642 0.0419769C6.99899 -0.381023 3.89071 2.44898 3.89071 5.99998C3.89071 7.88998 4.77343 9.57396 6.15083 10.673C3.13241 11.798 0.841677 14.277 0.108053 17.636C-0.15854 18.857 0.83378 20 2.09269 20H17.7842C19.0441 20 20.0354 18.857 19.7688 17.636Z"
                      fill="#2C5F91" />
                  </svg>
                </span>
                <p class="font-medium mb-0 mt-1">{{asset.createdBy}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="d-flex justify-content-center mt-3 pb-3 w-100">
    <button [disabled]="totalRecords<=assetDetails.length" type="button" (click)="seeMore()"
      *ngIf="totalRecords !== 0 && totalRecords>assetDetails.length" class="btn btn-primary">See More</button>
  </div>
</div>
<nav #contextMenuW id="context-menu1" [ngClass]="contextmenu ? ' border-0' : 'border-0' "
  class="context-menu d-block overflow-hidden asset-context-menu"
  [ngStyle]="{'left.px': contextmenuX, 'top.px' : contextmenuY}">
  <app-context-menu *ngIf="contextmenu==true" [menuitems]="newAssetContextMenuList"
    (clickedOutside)="clickedOutsideMenu()" (menuItemSelected)="handleMenuSelection($event)"
    [selectedAssets]="selectedAssets">
  </app-context-menu>
</nav>

<div class="placeholderimg custom-placeholder-image" *ngIf="totalRecords === 0">
  <img src="./assets/images/no-assets-placeholder-img.png" alt="">
  <br>
  <span>No assets present!</span>
</div>

<!-- Preview for Image assets name-->
<p-dialog [modal]="true" [visible]="isTrimImage" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
  [resizable]="false" [style]="{width: '600px'}" [showHeader]="false">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h4 class="font-bold">Preview</h4>
    <button type=" button" (click)="closeTrimImage(trimImageFrom)"
      class="btn btn-close justify-content-center save-cropped-img me-2"></button>
  </div>
  <div class="img-wrapper d-flex align-items-center justify-content-center">
    <img [src]="croppedsrcUrl" alt="Preview" class="mw-100">
  </div>

  <form [formGroup]="trimImageFrom" (ngSubmit)="submitFormDataImage(trimImageFrom)">
    <div class="row align-items-start mt-2">
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="imageName" class="form-label font-bold">Name
            <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
          </label>
          <input type="text" class="form-control" formControlName="imageName" name="imageName" required
            placeholder="Enter Name">
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.controls['imageName'].hasError('required') && trimImageFrom.controls['imageName'].dirty">
            Name is required
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('minlength')">
            Name must be at least 2 characters long.
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('maxlength')">Name cannot exceed 100
            characters. </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('pattern')">
            Only - and _ are allowed as special characters and leading or trailing spaces are not allowed
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="VideoName" class="form-label font-bold">Type
            <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
          </label>
          <input type="text" class="form-control" placeholder="{{imageExt ? imageExt:''}}" disabled>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-grid d-flex gap-3 pt-3 flex-wrap justify-content-center">
          <button type="button" (click)="onCancel()" class="btn btn-secondary justify-content-center  btn-min-width"
          [disabled]="!trimImageFrom.dirty" style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
          <button type="submit" class="btn d-btn-primary btn-primary justify-content-center btn-min-width border-0"
            [disabled]="trimImageFrom.invalid || (trimImageFrom.controls['imageName'].length >=1 && trimImageFrom.controls['imageName'].length <2 || trimImageFrom.controls['imageName'].length > 50) && (trimImageFrom.controls['imageName'].dirty || trimImageFrom.controls['imageName'].touched || trimImageFrom.submitted)">Save</button>

        </div>
      </div>
    </div>
  </form>

</p-dialog>

<!-- dialog box for delete confirmation -->
<app-deletepopup [title]="'Are you sure you want to Delete?'" *ngIf="isDeleteModalShow" (onCancel)="closedeleteModal()"
  [show]="isDeleteModalShow" (onConfirm)="onItemDeleted()"></app-deletepopup>

<!-- dialog box for creating event -->
<app-add-event-modal *ngIf="IsmodelEventShow" (closeEvent)="closeEventModal($event)"></app-add-event-modal>

<!---Modal for asset preview-->
<p-dialog [modal]="true" [visible]="isPreview" [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [resizable]="false"
  [style]="{width: '600px'}" [showHeader]="false">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h5 class="font-bold"></h5>
    <button type="button" (click)="isPreview=!isPreview" class="btn-close" aria-label="Close"></button>
  </div>

  <div *ngIf="modalAssetType==0" class="modal-body text-center">
    <img crossOrigin="anonymous" [src]="appService?.s3BaseUrl+''+imgSrc | formatS3Url" id="imagepreview"
      class="mw-100 m-auto" alt="" width="" height="">
  </div>
  <div *ngIf="modalAssetType==1" class="modal-body text-center">
    <video [src]="appService?.s3BaseUrl+''+imgSrc | formatS3Url" class="mw-100 m-auto" alt="" width="" height=""
      controls disablePictureInPicture controlsList="noplaybackrate nodownload">
    </video>
  </div>

  <div *ngIf="modalAssetType==3" class="modal-body text-center">
    <img src="assets/images/icons/audio_icon.svg" class="mw-100 mh-100 w-25 mb-3 m-auto" alt="Audio">
    <audio [src]="appService?.s3BaseUrl+''+imgSrc | formatS3Url" class="mw-100 m-auto" alt="" width="" height=""
      controls controlsList="noplaybackrate nodownload">
    </audio>
  </div>
</p-dialog>

<!---Modal for video/audio trimming-->
<p-dialog [modal]="true" class="asset-trimming-wrapper" [visible]="isTrimVideo" [resizable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '800px', height: 'auto'}" [showHeader]="false">
  <div class="modal-header border-0">
    <h3 class="font-bold">Trim Asset</h3>
  </div>
  <div class="container-fluid p-3" *ngFor="let asset of selectedAssets">
    <app-video-trim #trimvideo (showPreview)="showPreview($event)" assetType="{{asset.assetType}}"
      source="{{appService?.s3BaseUrl}}{{asset?.assetPath}}/{{asset?.assetName}}" assetName="{{asset?.assetName}}"
      isSaved="isSaved" assetPath="{{asset?.assetPath}}" referenceAssetId="{{asset?.id}}"
      *ngIf="asset.assetType==1 || asset.assetType==3" folderId="{{childFolderId}}" class="w-100"
      (isvideoeditorclosed)="closeVideoTrimModal()"></app-video-trim>
  </div>
</p-dialog>

<!-- sldier logic -->
<ng-container *ngIf="showAssetPreview">
  <app-asset-preview [assetsList]="assetDetails" [currentItem]="slideritemdata"
    (onActionPerformed)="onAssetPreviewChange($event)"></app-asset-preview>
</ng-container>


<!-- Modal Copy  -->
<p-dialog class="copy-move-popup movetoasset" [draggable]="false" [header]="assetsHeader" [modal]="true"
  [(visible)]="displayDialogBox" [breakpoints]="{ '500px': '90vw'}" [style]="{width: '1000px'}" [showHeader]="true"
  [resizable]="false" (onShow)="onCopyMoveDialogShow()">

  <div class="row" *ngIf="folderOrigin===0">
    <div class="col-12 col-lg-4 pe-0 d-flex align-items-start">
      <div class="sidetab">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button class="nav-link active d-flex justify-content-start btn-lg"
            [ngClass]="{'active':isActiveTabForCopy===1}" *ngIf="displayAllAssetsFolderStructure" id="v-pills-home-tab"
            data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home"
            (click)="switchTab('all')">
            <img src="assets/images/ic_folder.svg" class="me-2" alt="all upload">
            All Uploads</button>
          <button class="nav-link d-flex justify-content-start btn-lg" *ngIf="displayCoreAssetsFolderStructure"
            [ngClass]="{'active':isActiveTabForCopy===2}" id="v-pills-profile-tab" data-bs-toggle="pill"
            data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile"
            (click)="switchTab('core')"><img src="assets/images/ic_folder.svg" class="me-2" alt="Core Assets">Core
            Assets</button>
        </div>
      </div>
    </div>
    <div class="col-12 col-lg-8 p-0">
      <div class="tab-content sidepanel position-relative" id="v-pills-tabContent">

        <div class="tab-pane fade" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"
          *ngIf="isActiveTabForCopy===1 && displayAllAssetsFolderStructure"
          [ngClass]="{'show active':isActiveTabForCopy===1}">

          <div class=" d-flex justify-content-start align-items-center" *ngIf="breadCrumbArrayOfFolderItem.length >= 1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb arr-right breadcrumb_ath">
                <li class="breadcrumb-item breadcrumb-secitem"
                  *ngFor="let item of breadCrumbArrayOfFolderItem; let i = index">
                  <a class="breadcrumb_foldername mt-1" (click)="openFolderThroughBreadCrumb(item.folderItem)">
                    {{item.folderItem.folderName}}
                  </a>
                </li>
              </ol>
            </nav>
          </div>
          <div class="tree-view-nav">
            <ul id="myUL" class="tree-ul">
              <li>
                <div>
                  <app-dynamic-nested-list [items]="firstItem" [layer]="1" [isOpen]="firstItem.isCollapse"
                    [id]="firstItem.folderId" [parentId]="firstItem.parentId" (refresh)="openFolder($event)"
                    [isOpenedTrhoughModal]=true>
                  </app-dynamic-nested-list>
                </div>
              </li>
            </ul>
          </div>

        </div>


        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"
          *ngIf="isActiveTabForCopy===2 && displayCoreAssetsFolderStructure"
          [ngClass]="{'show active':isActiveTabForCopy===2}">
          <div class=" d-flex justify-content-start align-items-center" *ngIf="breadCrumbArrayOfFolderItem.length >= 1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb arr-right breadcrumb_ath">
                <li class="breadcrumb-item breadcrumb-secitem"
                  *ngFor="let item of breadCrumbArrayOfFolderItem; let i = index">
                  <a class="breadcrumb_foldername mt-1" (click)="openFolderThroughBreadCrumb(item.folderItem)">
                    {{item.folderItem.folderName}}
                  </a>
                </li>
              </ol>
            </nav>
          </div>

          <div class="tree-view-nav">
            <ul id="myUL" class="tree-ul">
              <li>
                <div>
                  <app-dynamic-nested-list [items]="firstItemsCoreAssets" [layer]="1"
                    [isOpen]="firstItemsCoreAssets.isCollapse" [id]="firstItemsCoreAssets.folderId"
                    [assetCountDisableFlag]="true" [parentId]="firstItemsCoreAssets.parentId"
                    (refresh)="openFolder($event)" [isOpenedTrhoughModal]=true>
                  </app-dynamic-nested-list>
                </div>
              </li>
            </ul>
          </div>

        </div>
        <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
          <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
            style="color: #116ACC; border: 1px solid #116ACC;">Cancel</button>
          <button type="button" class="btn d-btn-primary btn-primary btn-min-width border-0"
            (click)="checkSubmitCopyData()" [disabled]="isDestinationFolderDisabled">{{assetsFooter}}</button>
        </div>
      </div>

    </div>

  </div>

  <div class="row" *ngIf="folderOrigin===1">
    <div class="col-12 col-lg-4 pe-0 d-flex align-items-start">
      <div class="sidetab">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical">
          <button class="nav-link d-flex justify-content-start btn-lg active" *ngIf="displayCoreAssetsFolderStructure"
            id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab"
            aria-controls="v-pills-profile"><img src="assets/images/ic_folder.svg" class="me-2" alt="Core Assets">Core
            Assets</button>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-8 p-0">
      <div class="tab-content sidepanel position-relative" id="v-pills-tabContent">

        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab"
          *ngIf="isActiveTabForCopy===2 && displayCoreAssetsFolderStructure"
          [ngClass]="{'show active':isActiveTabForCopy===2}">
          <div class=" d-flex justify-content-start align-items-center" *ngIf="breadCrumbArrayOfFolderItem.length >= 1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb arr-right breadcrumb_ath">
                <li class="breadcrumb-item breadcrumb-secitem"
                  *ngFor="let item of breadCrumbArrayOfFolderItem; let i = index">
                  <a class="breadcrumb_foldername mt-1" (click)="openFolderThroughBreadCrumb(item.folderItem)">
                    {{item.folderItem.folderName}}
                  </a>
                </li>
              </ol>
            </nav>
          </div>

          <div class="tree-view-nav">
            <ul id="myUL" class="tree-ul">
              <li>
                <div>
                  <app-dynamic-nested-list [items]="firstItem" [layer]="1" [isOpen]="firstItem.isCollapse"
                    [id]="firstItem.folderId" [parentId]="firstItem.parentId" (refresh)="openFolder($event)"
                    [isOpenedTrhoughModal]=true>
                  </app-dynamic-nested-list>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
          <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
            style="color: #116ACC; border: 1px solid #116ACC;">Cancel</button>
          <button type="button" class="btn d-btn-primary btn-primary btn-min-width border-0"
            (click)="checkSubmitCopyData()" [disabled]="isDestinationFolderDisabled">{{assetsFooter}}</button>
        </div>
      </div>
    </div>
  </div>


</p-dialog>

<!----------------Modal to tak assets in bulk (BULK TAG)------------------->
<p-dialog [modal]="true" [draggable]="false" [visible]="isBulkAssetTag"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{'width': '90%'}" [showHeader]="false"
  (onHide)="emptyFileList()" class="bulk-tagging-popup-wrapper" [resizable]="false" [draggable]="false">
  <div class="container-fluid">
    <div class="d-flex justify-content-end px-3 mt-3">
      <button type="button" (click)="goBack()" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="row">
      <form [formGroup]="uploadBulkTagsForm">
        <div class="col">
          <div class="detail-card">
            <div class="row">
              <div class="col-lg-8 pe-4">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-none d-lg-block">
                    <h2 class="font-medium">BULK TAGGING</h2>
                  </div>
                  <div>
                    <button class="btn btn-outline-tag btn-min-width"
                      style="border-color: rgb(25 25 25 / 25%); pointer-events: none;">
                      Total Selected: {{selectedAssets.length}}
                    </button>
                  </div>
                </div>

                <div class="card bulk-details-sec">
                  <div class="card-body bulk-details-container">
                    <div class="bulkdetails font-bold">
                      <ul>
                        <li *ngFor="let element of assetNameArray">
                          {{ element }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0 img-detail-sec">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link w-50" id="nav-home-tab" type="button" role="tab" (click)="isActiveTab=1"
                      [ngClass]="{'active': isActiveTab===1}">Details</button>
                    <button class="nav-link w-50" id="nav-profile-tab" type="button" role="tab" (click)="isActiveTab=2"
                      [ngClass]="{'active': isActiveTab===2}">Tagging</button>
                  </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                    *ngIf="isActiveTab===1" [ngClass]="{'show active': isActiveTab===1}">
                    <div class="row row flex-column d-flex">
                      <div class="col d-flex justifnav-home-taby-content-end justify-content-end">
                        <button class="btn btn-primary btn-sm new-btn mt-4" (click)="isViewMode=!isViewMode"
                          *ngIf="isViewMode">
                          <span class=" nm_icon me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 17 15"
                              fill="none">
                              <path
                                d="M12.2034 0L10.1695 1.875L14.2373 5.625L16.2712 3.75L12.2034 0ZM8.1356 3.75L0 11.25V15H4.0678L12.2034 7.5L8.1356 3.75Z"
                                fill="white" />
                            </svg>
                          </span>
                          Edit
                        </button>
                      </div>


                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="!isViewMode">
                        <h3 _ngcontent-vvf-c90="" class="txtheading">Asset Details</h3>
                        <div class="col-12">
                          <div class="form-outline mt-3">
                            <textarea rows="3" class="form-control mt-1 mb-2" aria-describedby="assest"
                              placeholder="Title" maxlength="{{totalText}}"
                              pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetTitle"></textarea>
                          </div>
                          <span class="remaning">{{uploadBulkTagsForm.get('assetTitle').value?.length || 0}} /
                            {{totalText}}</span>

                          <div class="form-outline mt-3 mb-3">
                            <textarea rows="5" class="form-control mt-1 mb-2" aria-describedby=" AssestDescription"
                              placeholder="Description" maxlength="{{totalText}}"
                              pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetDescription"></textarea>
                          </div>
                          <span class="remaning">{{uploadBulkTagsForm.get('assetDescription').value?.length || 0}} /
                            {{totalText}}</span>
                          <!-- <span class="font-bold mt-2" style="color: #788596; font-size: 16;">Upload Date :  15-Aug-2022</span> -->
                        </div>
                        <div>
                          <h3 class="txtheading">Event</h3>
                          <div class="row  my-5">
                            <div class="col-12">
                              <div class="mb-2 fs-18-14">Event Name</div>
                            </div>
                            <div class="col-12">
                              <div class="form-check ps-0">
                                <div class="col-12">
                                  <p-dropdown [options]="eventsList" placeholder="Select an event"
                                    optionLabel="eventName" optionValue="id" formControlName="eventId"
                                    [showClear]="true" [style]="{'width':'100%'}" scrollHeight="150px" [required]="true"
                                    [filter]="true" [resetFilterOnHide]="true">
                                  </p-dropdown>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 create-event-block mt-3">
                              <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary d-btn-primary border-0"
                                  (click)="openEventModal()">
                                  <i class=" nm_icon fs-24">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <g id="Add" clip-path="url(#clip0_214_22660)">
                                        <path id="Vector"
                                          d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
                                          fill="white" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_214_22660">
                                          <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Create Event</button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-5">
                          <button type="button" class="btn btn-secondary btn-min-width me-3"
                            (click)="resetBulkTagForm()"
                            style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
                          <button type="button"
                            class="btn btn-primary new-btn btn-min-width cursorpointer addtocollection"
                            (click)="SaveBulkTagInfo()">Save</button>
                        </div>
                      </div>

                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="isViewMode">
                        <div>
                          <h3 class="txtheading">Asset Details</h3>
                          <h4 class="imgheading">
                            {{assetTitle || 'No Asset Title available'}}
                          </h4>
                          <p class="imgparagrph">
                            {{assetDescription || 'No Asset Description available'}}
                          </p>
                        </div>
                        <div>
                          <h3 class="txtheading">Event</h3>
                          <table class="table table-borderless infotable">
                            <tbody>
                              <tr>
                                <td class="title">Event Name</td>
                                <td class="colon">:</td>
                                <td class="detail">{{event.eventName || 'No Event Name available'}}</td>
                              </tr>
                              <tr>
                                <td class="title">Event Date</td>
                                <td class="colon">:</td>
                                <td class="detail">{{(event.eventTime | date) || 'No Event Date available'}}</td>
                              </tr>
                              <tr>
                                <td class="title">Event Location</td>
                                <td class="colon">:</td>
                                <td class="detail">{{event.eventLocation || 'No Event Location available'}}</td>
                              </tr>
                              <tr>
                                <td class="title">Event Description</td>
                                <td class="colon">:</td>
                                <td class="detail">{{event.eventDescription|| 'No Event Description available'}}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
                    *ngIf="isActiveTab===2" [ngClass]="{'show active': isActiveTab===2}">
                    <div class="row flex-column d-flex">
                      <div class="col d-flex justifnav-home-taby-content-end justify-content-end">
                        <button class="btn btn-primary btn-sm new-btn mt-4" (click)="isViewMode=!isViewMode"
                          *ngIf="isViewMode">
                          <span class=" nm_icon me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 17 15"
                              fill="none">
                              <path
                                d="M12.2034 0L10.1695 1.875L14.2373 5.625L16.2712 3.75L12.2034 0ZM8.1356 3.75L0 11.25V15H4.0678L12.2034 7.5L8.1356 3.75Z"
                                fill="white" />
                            </svg>
                          </span>
                          Edit
                        </button>
                      </div>
                      <div class="col-sm-12 mt-4 flex-column" *ngIf="!isViewMode">
                        <div class="col-12">
                          <div class="form-outline">
                            <label class="form-label fs-18-14" for="textAreaExample">Manual
                              Tags:</label>
                            <div class="card border-0 radius-8 h-100 overflow-visible">
                              <div class="p-0 dam-neutral-80 ">
                                <div>
                                  <div class="d-grid d-flex flex-wrap gap-2 py-2 bulk-tag-m">
                                    <p-chips class="w-100" [allowDuplicate]="false" formControlName="tagArray"
                                      type="reset" separator="," placeholder="Add Tags" [required]="true"
                                      [addOnBlur]="true" [addOnTab]="true" (keydown)="onKeyDown($event)"
                                      (onAdd)="handleAdd($event)"></p-chips>
                                    <span class="note"> Note: Press enter or Tab key after you added the
                                      tag name. </span>

                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                        <div class="col-12 my-5">
                          <div>
                            <h5 class="mb-2 fs-18-14">Tagging Status</h5>
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="radio">
                                <input id="radio-1" type="radio" class="me-1" name="assetTag" [value]="1"
                                  [(ngModel)]="assetTag" formControlName="assetTag">
                                <label for="radio-1" class="radio-label text-nowrap">Complete</label>
                              </div>
                              <div class="radio">
                                <input id="radio-2" type="radio" class="me-1" name="assetTag" [value]="0"
                                  [(ngModel)]="assetTag" formControlName="assetTag">
                                <label for="radio-2" class="radio-label text-nowrap">Pending</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mt-5">
                            <button type="button" class="btn btn-secondary btn-min-width me-3"
                              (click)="resetBulkTagForm()"
                              style="color: #116ACC; border: 1px solid #116ACC;">Reset</button>
                            <button type="button"
                              class="btn btn-primary new-btn btn-min-width cursorpointer addtocollection"
                              (click)="SaveBulkTagInfo()">Save</button>
                          </div>
                        </div>
                      </div>

                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="isViewMode">
                        <div class="tags-box">
                          <h3 class="txtheading">Manual Tags</h3>
                          <div class="tags-container d-flex flex-wrap card-title manualtagcontainer text-truncate"
                            *ngIf="manualTagArray && manualTagArray.length">
                            <ng-container *ngFor="let tag of shortTagArrManual">
                              <div class="tag m-tag text-break">{{tag || 'No Tags available'}}</div>
                            </ng-container>
                            <div class="tag m-tag moretags custom-tooltip-hover position-relative"
                              *ngIf="manualTagArray && manualTagArray.length > 10">+{{manualTagArray.length -
                              shortTagArrManual.length }}
                              <span class="hover-text-details">{{longTagArrManual}}</span>
                            </div>
                          </div>

                        </div>
                        <div class="tags-box mt-3">
                          <p class="fs-6" *ngIf="assetTag==1">Tagging status - <span
                              class="text-success">Complete</span></p>
                          <p class="fs-6" *ngIf="assetTag==0">Tagging status - <span class="text-danger">Pending</span>
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--row end-->
          </div>
          <!--detail-card end-->
          <!-- <div class="row">
            <div class="col">
              <div class="d-grid d-flex gap-3">
                <button type="button" class="btn btn-secondary btn-min-width"
                  (click)="goBack()" style="color: #116ACC; border: 1px solid #116ACC;">Cancel</button>
                <button type="button" class="btn btn-primary new-btn btn-min-width"
                  (click)="uploadBulkTag()">Approve</button>
              </div>
            </div>
          </div> -->
        </div>
      </form>

    </div>
  </div>
</p-dialog>
