<div class="col">

    <div class="d-flex justify-content-end d-grid gap-4">

        <div class="d-flex align-items-center">
            <div class="pe-2 font-bold">
                Number of rows
            </div>
            <!-- <div>
                <select class="form-select" aria-label="Default select example">
                    <option value="1">10</option>
                    <option value="2">20</option>
                    <option value="3">30</option>
                </select>
            </div> -->
            <p-paginator [rows]="10" [totalRecords]="totalRecords" [rowsPerPageOptions]="[10,20,30]"></p-paginator>

        </div>


    </div>


</div>