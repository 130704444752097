<div class="upload-component search-by-img">
  <p-dialog [modal]="true" [visible]="true" [breakpoints]="{'1030px': '850px', '991px': '90vw'}"
    [style]="{width: '1100px'}" [showHeader]="false" [resizable]="false" [closeOnEscape]="false"
    class="p-dialog-container">
    <div class="col-sm-12">
      <div class="header-container w-100 modal-header border-0">
        <div class="header-title">Search by Image</div>
        <div class="minimizer" (click)="minimize()" *ngIf="view.status">
          <img src="assets/images/icons/ic_minimize.svg" alt="" width="" height="">
        </div>
      </div>
    </div>
    <div class="row no-gutter p-4">
      <div class="col-md-6 search-by-img-colleft">
        <div class="card drag-drop-container radius-24 text-center h-100">
          <input class="btn bg-transparent w-100 drag-drop-file h-100" type="file"
            accept="image/png, image/gif, image/jpeg" multiple ngFileDragDrop (change)="selectFiles($event)" title="" />
          <div class="card-body d-flex flex-column justify-content-center min-h-auto">
            <div class="p-2 mb-4">
              <img alt="image" class="" src="assets/images/icons/file-upload.svg" width="40%">
            </div>
            <div class="my-2">
              <div class="row">
                <div class="col-8 m-auto">
                  <div class="upload-btn-wrapper w-100">
                    <button class="btn bg-transparent w-100">
                      <a class="choosefile"> Choose a file </a> &nbsp; or Drag it here </button>
                    <input (change)="selectFiles($event)" type="file" title="" accept="image/png, image/gif, image/jpeg"
                      multiple>
                  </div>
                </div>
                <div class="text-center pb-2">
                  <span class="ps-fs-8 ps-text-clr-grey">Supported file types:
                    <span class="font-medium ps-clr-dark">JPG, PNG, JPEG, TIFF, JFIF, HEIC, WEBP, TIF </span></span>
                </div>
                <div class="">
                  <span class="ps-text-clr-grey ps-fs-8">Max file size:
                    <span class="font-medium ps-clr-dark">500MB</span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 search-by-img-colright">
        <div class="card h-100 border-0 upload-files-items">
          <div class="card-body min-h-auto">
            <div class="d-flex justify-content-between align-items-center">
              <div class="left">
                <ng-container *ngIf="view.upload; else processedText">
                  <div class="selected-files">Selected Files</div>
                </ng-container>
                <ng-template #processedText>
                  <div class="selected-files">Queued Images</div>
                </ng-template>
                <div *ngIf="view.upload" class="totol-uploads">Total Uploads:
                  {{totalAssetSize | formatBytes:2:'exactValue'}}</div>
                <div *ngIf="view.status" class="noti-text">
                  <ng-container *ngIf="dataRefresh; else staticText">
                    Updating queue...
                  </ng-container>
                  <ng-template #staticText>
                    Image retrieval queue will be cleared after 24hrs
                  </ng-template>
                </div>
              </div>
              <div class="right cursorpointer" *ngIf="view.status && imageRevData?.length>0">
                <img src="assets/images/icons/ic_refresh.png" width="18px" [class.rotate]="dataRefresh"
                  [class.disabled]="dataRefresh" (click)="[getImageRevStatus(),rotateAnim()]" alt="refresh">
              </div>
            </div>
            <div class="d-grid d-flex gap-3 font-medium justify-content-between"></div>
            <div class="upload-list-panel mt-3">
              <ng-container *ngIf="view.upload">
                <div class="upload-list" *ngFor="let f of selectedFilesList; let i = index">
                  <div class="d-flex d-grid gap-3 pt-3 pb-3 align-items-center">
                    <div class="icon-left justify-content-start">
                      <i *ngIf="!successFileNameArray.includes(f.file.name) && !fileAlreadyExistsOrError.includes(replaceName(f.file.name)) && !fileAlreadyExistsOrError.includes(f.file.name)"
                        class="nm_icon mt-1 align-items-start">
                        <img src="assets/images/icons/folder-asset.png" alt="">
                      </i>
                      <i *ngIf="successFileNameArray.includes(f.file.name) && !fileAlreadyExistsOrError.includes(replaceName(f.file.name))  && !fileAlreadyExistsOrError.includes(f.file.name)"
                        class="nm_icon">
                        <img src="assets/images/icons/success.png" alt="">
                      </i>
                      <i *ngIf="fileAlreadyExistsOrError.includes(replaceName(f.file.name))  || fileAlreadyExistsOrError.includes(f.file.name) || unSupportedFile.includes(f.file.name)"
                        class="nm_icon">
                        <img src="assets/images/icons/alert.png" alt="">
                      </i>
                    </div>
                    <div
                      *ngIf="!fileAlreadyExistsOrError.includes(replaceName(f.file.name))  && !fileAlreadyExistsOrError.includes(f.file.name)"
                      class="file-name flex-grow-1 fs-18 font-bold text-break "> {{f.file.name}}

                    </div>
                    <div
                      *ngIf="fileAlreadyExistsOrError.includes(replaceName(f.file.name))  || fileAlreadyExistsOrError.includes(f.file.name)"
                      class="file-name flex-grow-1 fs-18 font-bold text-break text-danger "> {{f.file.name}}

                    </div>
                    <div class="asset-size" *ngIf="!fileAlreadyExistsOrError.includes(f.file.name)">
                      {{f.file.size | formatBytes:2:'exactValue'}}</div>
                    <div
                      *ngIf="(!fileAlreadyExistsOrError.includes(replaceName(f.file.name))  && !fileAlreadyExistsOrError.includes(f.file.name))"
                      class="icon-end">
                      <button *ngIf="progressList[i]==0 || progressList[i]==null" [disabled]="isUploading" class="btn btn-sm icon-only-btn">
                        <img src="assets/images/icons/delete-asset.png" (click)="removeImage(f.file,i)"> </button>
                    </div>
                    <div
                      *ngIf="fileAlreadyExistsOrError.includes(replaceName(f.file.name))  || fileAlreadyExistsOrError.includes(f.file.name)"
                      class="icon-end">
                      <div class="asset-size text-danger">{{f.file.size | formatBytes:2:'exactValue'}}</div>
                      <button class="btn btn-sm icon-only-btn" *ngIf="internalServerErrorWithFile.includes(f.file)">
                        <img src="assets/images/icons/ic_retry.svg" (click)="singleRetry(f.file,i)" alt="refresh">
                      </button>
                      <button class="btn btn-sm icon-only-btn">
                        <img src="assets/images/icons/ic_delete.svg" (click)="removeImage(f.file,i)" alt="delete">
                      </button>
                    </div>
                  </div>
                  <div *ngIf="internalServerErrorWithFile.includes(f.file)"
                    class="flex-grow-1 fs-18 font-bold text-break text-danger "> Upload failed due to a
                    server error
                  </div>
                  <div *ngIf="unSupportedFile.includes(f.file)"
                    class="flex-grow-1 fs-18 font-bold text-break text-danger "> This File is not Supported .
                  </div>
                  <div
                    *ngIf="!internalServerErrorWithFile.includes(f.file) && !unSupportedFile.includes(f.file) && (fileAlreadyExistsOrError.includes(replaceName(f.file.name))  && fileAlreadyExistsOrError.includes(f.file.name)) "
                    class="flex-grow-1 fs-18 font-bold text-break text-danger "> File already exists!
                  </div>
                  <div class="progress-bar-group pt-2"
                    *ngIf="progressOfUplodingFile[i] && progressOfUplodingFile[i].i==i">
                    <div class="progress">
                      <div class="progress-bar" role="progressbar"
                        attr.aria-valuenow="{{ progressOfUplodingFile[i]?.progress }}" aria-valuemin="0"
                        aria-valuemax="100" [ngStyle]="{ width: progressOfUplodingFile[i]?.progress + '%' }"> </div>
                    </div>
                    <div class="dam-primary-100 fs-14 font-bold mt-1">
                      {{ progressOfUplodingFile[i].progress }}%
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="view.status">
                <div class="status-list">
                  <div class="status-list-item gap-3 pt-3 pb-4" *ngFor="let item of imageRevData; let i = index">
                    <div class="icon-container">
                      <ng-container *ngIf="item.status; else pendingStatus">
                        <i class="nm_icon">
                          <img src="assets/images/icons/success.png" alt="">
                        </i>
                      </ng-container>
                      <ng-template #pendingStatus>
                        <i class="nm_icon">
                          <img src="assets/images/icons/ic_cloud_warning.svg" alt="">
                        </i>
                      </ng-template>
                    </div>
                    <div class="asset-details flex-grow-1 ">
                      <div class="name flex-grow-1 fs-18 font-bold text-break">
                        {{item.assetName}}
                      </div>
                      <div class="desc">
                        <ng-container *ngIf="item.status; else fetchingText">
                          Similar images found
                        </ng-container>
                        <ng-template #fetchingText>
                          Fetching similar images
                        </ng-template>
                      </div>
                    </div>
                    <div class="action-container">
                      <div class="btn-group">
                        <button class="btn btn-sm icon-only-btn" [disabled]="!item.status">
                          <img src="assets/images/icons/ic_navigator.svg" (click)="onAssetAction('redirect',item)"
                            alt="refresh">
                        </button>
                        <button class="btn btn-sm icon-only-btn" (click)="onAssetAction('delete',item)">
                          <img src="assets/images/icons/ic_close.svg" alt="delete">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
            <button *ngIf="view.upload " type="button" class="btn btn-secondary btn-min-width"
              (click)="onAssetAction('cancel')" [disabled]="(!uploadDone && isUploading)">Cancel
            </button>
            <button *ngIf="!uploadDone && view.upload" [disabled]="selectedFilesList.length==0 || isUploading"
              type="button" class="btn btn-primary btn-min-width" (click)="uploadFiles()">
              Upload
            </button>
            <button *ngIf="view.status && imageRevData?.length>0" type="button" class="btn btn-secondary btn-min-width"
              (click)="onAssetAction('clear')">Clear All
            </button>
            <button *ngIf="uploadDone && view.upload" type="button" class="btn btn-primary d-btn-primary btn-min-width"
              [disabled]="fileAlreadyExistsOrError.length>0 || selectedFilesList.length==0"
              (click)="submitImages();[$event.stopPropagation()]">Find Similar Images</button>
          </div>
        </div>
      </div>
    </div>
  </p-dialog>
</div>
<app-deletepopup [show]="confirmationModal" [title]="'Are you sure?'"
  [subTitle]="'Canceling now will result in the loss of queued images. This process cannot be undone.'"
  (onCancel)="confirmationModal = false;" (onConfirm)="onAssetAction('deleteAll')">
</app-deletepopup>
<p-toast></p-toast>
