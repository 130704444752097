<div class="search-input-container">
  <div class="search-input-group w-100 position-relative radius-8">
    <input id="searchInputEle" type="text" class="search_input h-auto" aria-label="search"
      aria-describedby="basic-addon1" [placeholder]="placeholder" [(ngModel)]="searchText" #searchInputEle="ngModel" (ngModelChange)="onSearchChange()"
      (keyup.enter)="onSearchTextChanged(searchInputEle)" [disabled]="disable" autocomplete="off"/>
    <div class="search_action" (click)="disable? '' : onSearchTextChanged(searchInputEle)">
      <span class="search-icon">
        <img src="assets/images/icons/search-input.png" alt="Search" />
      </span>
    </div>
    <div class="search_action" *ngIf="searchText" (click)="reset(searchInputEle)">
      <span class="search-icon">
        <img src="assets/images/icons/close-asset.png" alt="Clear" />
      </span>
    </div>
  </div>
</div>
