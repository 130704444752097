import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
  HttpErrorResponse,
} from '@angular/common/http';
import { AppService } from '../services/app.service';
import { AuthService } from '../../auth/auth.service';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  constructor(
    private appService: AppService,
    private authService: AuthService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      withCredentials: true,
    });
    // return next.handle(request);
    return next.handle(req).pipe(
      catchError((error) => {
        if (req.url.includes('auth/passwordReset')) {
          //do nothing
        } else if (req.url.includes('login/forgotPassword')) {
          //do nothing
        } else if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/login') &&
          error.status === 401
        ) {
          return this.handle401Error(req, next);
        } else if (
          error instanceof HttpErrorResponse &&
          !req.url.includes('auth/login') &&
          error.status === 500
        ) {
          return this.handle401Error(req, next);
        }
        return throwError(() => error);
      })
    );
  }
  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      let userData = this.appService.getUserdata();
      let token = this.appService.getUserdata().token;
      let refreshToken = this.appService.getUserdata().refreshToken;

      if (this.appService.isLoggedIn()) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((data: any) => {
            if (data.code == 200) {
              let userData = this.appService.getUserdata();
              userData.refreshToken = data.result.refreshToken;
              userData.token = data.result.token;
              this.appService.setUserdata(userData);
            }
            this.isRefreshing = false;
            return next.handle(request);
          }),
          catchError((error) => {
            this.isRefreshing = false;
            return throwError(() => error);
          })
        );
      }
    }
    return next.handle(request);
  }
}

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
];
