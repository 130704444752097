import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../services/loader.service';
import { environment } from '../../environment/environment';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingService: LoaderService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/uploadAssets')) {
      return next.handle(request);
    }
    if (request.url.includes('/getQueueStatus')) {
      return next.handle(request);
    }
    // to remove loader when gen AI tag api call .
    if (request.url.includes('/bulkAiTagging')) {
      return next.handle(request);
    }
    if (request.url.includes('/elk/publishAssetsToELK/')) {
      return next.handle(request);
    }
    // to remove loader when download button is hit .
    if (request.url.includes('/download')) {
      return next.handle(request);
    }
    if (request.url.includes('/initiateMultipart')) {
      return next.handle(request);
    }
    if (request.url.includes('/getPresignedUrlUnique')) {
      return next.handle(request);
    }

    if (request.url.includes('/completeMultiPartUpload')) {
      return next.handle(request);
    }

    if (request.url.includes('/getPresignedUrl')) {
      return next.handle(request);
    }

    if (request.url.includes('/reverseSearch/getStatus')) {
      return next.handle(request);
    }

    if (request.url.includes(environment.BUCKET)) {
      return next.handle(request);
    }

    if(request.url.includes('/clearDoneListOfQueue')) {
      return next.handle(request);
    }

    this.totalRequests++;
    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      finalize(() => {
        this.totalRequests--;
        if (this.totalRequests == 0) {
          this.loadingService.setLoading(false);
        }
      })
    );
  }
}
