import { Component, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import {
  AppDataService,
  ClientadminService,
  EventData,
  EventEmitterService,
} from 'src/app/core/services';
import {
  IHeaderModuleOption,
  PermissionsEngine,
} from '../../../common/permission/permission';
import { AuthService } from 'src/app/auth/auth.service';
import { MessageService } from 'primeng/api';
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/core/services/loader.service';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { APP_EVENTS } from 'src/app/core/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  userDetails: any;
  isUserRoleId: any;
  isUserRole: any;
  isUserLogo: any;
  userDetailsArray: any = [];
  public routerConfig: any = [];
  getUserName: any = '';
  getUserFirstName: any = '';
  getUserLastName: any = '';
  getUserRoleName: any;
  getUserRoleId: any;
  activeIndex: any;
  isMenuCollapsed = true;
  toggleSearch: boolean = false;
  logOut: any;
  isAddedToCollection: boolean = false;
  isQueueListModalOpen = false;
  queueList: any = [];
  pollingSubscription: Subscription | undefined;
  id: any;
  clickOutsideListener: any;
  @ViewChild('searchtext') inputElementForSearch: ElementRef;
  @ViewChild('DropdownMenu') DropdownMenu: ElementRef;
  private subscriptions: Subscription[] = [];
  imageRevRequest = {
    circumference: 0,
    donePercentage: 0,
    status: '',
    doneCount: 0,
    totalCount: 0,
  };

  constructor(
    private messageService: MessageService,
    private clientadminService: ClientadminService,
    public _router: Router,
    private appservice: AppService,
    public permissionsEngine: PermissionsEngine,
    private authService: AuthService,
    private renderer: Renderer2,
    private eventEmitterService: EventEmitterService,
    private loader: LoaderService,
    private dashboardService: DashboardService,
    private appDataService: AppDataService
  ) {
    this.userDetails = this.appservice.getUserdata();
    this.isUserRole = this.userDetails?.role;
    permissionsEngine.setup().then((date) => {
      this.initData();
    });
    // this.initData();
  }

  ngOnInit() {
    this.initData();
    this.isAddedToCollection = false;
    this.getLoggedInUserData();
    this.clientadminService.getAddtoCollectionEvent().subscribe((data) => {
      // Handle the data change from search detail component
      this.isAddedToCollection = data;
    });
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event' && event.newValue === 'logout') {
        window.location.href = 'auth/login';
      }
    });
    this.imageRevRequest.circumference = Math.PI * 2 * 40;
    this.subscriptions.push(
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            if (event.data.display) {
              if (event.data.visible) {
                this.appDataService.stopPollingFlag();
              } else {
                this.appDataService.startPollingFlag();
              }
            } else {
            }
            break;
        }
      }),
      this.appDataService.flag$.subscribe((flag: any) => {
        this.imageRevRequest.doneCount = flag.processed || 0.1;
        this.imageRevRequest.totalCount = flag.total;
        const percentage = Math.round(
          (100 * this.imageRevRequest.doneCount) /
            this.imageRevRequest.totalCount
        );
        this.imageRevRequest.donePercentage =
          this.imageRevRequest.circumference * (1 - percentage / 100);
        if (this.imageRevRequest.totalCount == this.imageRevRequest.doneCount) {
          this.appDataService.stopPollingFlag();
        }
      })
    );
  }

  ngAfterViewInit() {
    this.clientadminService.openQueueListModal$.subscribe((data) => {
      if (data === true) {
        const dropdownToggle = document.getElementById('navbarDropdown');
        if (dropdownToggle) {
          if (this.DropdownMenu.nativeElement) {
            this.isQueueListModalOpen = false;
            this.DropdownMenu.nativeElement.classList.add('show');
            this.getQueueList();
          }
        }
      }
    });
  }
  // async logout() {
  //   localStorage.clear();
  //   location.reload()
  //   window.location.href = "auth/login"
  //   // this._router.navigateByUrl('/auth/login');
  // }

  async logout() {
    localStorage.clear(); // Clear user data
    this.appservice.cleartUserdata(); // Clear user data
    localStorage.setItem('logout-event', 'logout'); // Set a logout event in localStorage
    window.location.href = 'auth/login'; // Redirect to the login page
  }

  logoutByApi() {
    this.authService.logout('').subscribe({
      next: (data: any) => {
        this.logOut = data.message;
        localStorage.removeItem('userDetails');
        window.location.href = '';
        this._router.navigate(['/auth/login']);
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'error',
          summary: 'Please relogin',
          detail: `error in logout Says: ${error.error.message}`,
        });
      },
    });
  }
  initData() {
    this.routerConfig = '';
    let myHeaderOptions: Array<IHeaderModuleOption> = [
      {
        url: 'client-admin/dashboard',
        label: 'Dashboard',
        isShowMenu: this.permissionsEngine.hasPermission(
          'dashboard',
          'view_asset_dashboard'
        ),
        moduleSlug: 'dashboard',
        isChildOpen: false,
      },
      {
        url: '',
        label: 'User Management',
        isShowMenu: true,
        moduleSlug: 'usermgmt',
        isChildOpen: true,
        childs: [
          {
            url: 'client-admin/role-config',
            label: 'Roles',
            isShowMenu: this.permissionsEngine.hasPermission(
              'role',
              'view_roles'
            ),
            moduleSlug: 'role',
          },
          {
            url: 'client-admin/user-list',
            label: 'Users',
            isShowMenu: this.permissionsEngine.hasPermission(
              'user',
              'view_users'
            ),
            moduleSlug: 'user',
          },
        ],
      },
      {
        url: '',
        label: 'Management',
        isShowMenu: true,
        moduleSlug: 'management',
        isChildOpen: true,
        childs: [
          {
            url: 'client-admin/folder-management',
            label: 'Folders',
            isShowMenu: this.permissionsEngine.hasPermission(
              'folder',
              'folder_access_management'
            ),
            moduleSlug: 'folder',
          },
          {
            url: 'client-admin/tag-repository',
            label: 'Tags',
            isShowMenu: this.permissionsEngine.hasPermission(
              'tags',
              'tag_management'
            ),
            moduleSlug: 'tags',
          },
          {
            url: 'client-admin/event-management',
            label: 'Events',
            isShowMenu: this.permissionsEngine.hasPermission(
              'events',
              'event_management'
            ),
            moduleSlug: 'events',
          },
          {
            url: 'client-admin/people-management',
            label: 'People',
            isShowMenu: this.permissionsEngine.hasPermission(
              'people',
              'people_management'
            ),
            moduleSlug: 'people',
          },
        ],
      },
      {
        url: 'client-admin/assets',
        label: 'Assets',
        moduleSlug: 'folder',
        isChildOpen: false,
        isShowMenu: this.permissionsEngine.hasPermission(
          'folder',
          'view_folder'
        ),
      },
    ];
    //Remove parent tabs that all childs have a false value on the isShowMenu flag
    myHeaderOptions.forEach((tab) => {
      if (tab.isChildOpen) {
        tab.isShowMenu =
          tab.childs != undefined &&
          tab.childs.filter((childTab) => childTab.isShowMenu == true).length >
            0;
      }
    });
    // //call folderAccess permission filter method
    // this.permissionsEngine.filterHeaderUsingFolderAccess(myHeaderOptions).then(data => {
    //   this.routerConfig = data;
    // })
    this.routerConfig = myHeaderOptions;
  }
  isShowMenu(arr: String[]): boolean {
    const hasAllElems = arr.every((elem: any) =>
      this.routerConfig.includes(elem)
    );
    return !!hasAllElems;
  }

  getLoggedInUserData() {
    this.clientadminService
      .callGetQuery('', 'user/userDetails/', this.userDetails.userId)
      .subscribe((data: any) => {
        this.getUserName = data.result.firstName;
        this.getUserFirstName = data.result.firstName.charAt(0).toUpperCase();
        this.getUserLastName = data.result.lastName.charAt(0).toUpperCase();
        this.getUserRoleName = data.result.roleName;
        this.getUserRoleId = data.result.roleId;
        if (data.result?.isRequestForImageRevSearch) {
          this.eventEmitterService.emit({
            type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
            data: {
              display: true,
              visible: false,
            },
          });
        }
      });
  }

  setRouterPath(item: any) {
    if (!item.url) {
      item.isChildOpen = !item.isChildOpen;
      return false;
    }
    this._router.navigate([item.url]);
  }
  redirectChild(item: any) {
    this._router.navigate([item.url]);
  }
  setActiveIndex(index: any) {
    this.activeIndex = index;
  }
  getActiveClass(i: any) {
    return this.activeIndex == i ? 'active' : '';
  }
  getAssetsListSearch(searchtext: any) {
    if (searchtext) {
      this.clientadminService.setHeader(true);
      localStorage.setItem('searchtext', searchtext);
      this._router.navigateByUrl('/dashboard/search-details');
    }
    // this.router.navigateByUrl("/auth/dashboard/search-details");
  }
  onToggleSearch() {
    this.toggleSearch = !this.toggleSearch;
  }
  goToCliplist() {
    this._router.navigateByUrl('/dashboard/cliplist');
  }
  goToCollections() {
    this._router.navigateByUrl('/dashboard/collection-management');
  }
  showQueueListModal = false;
  getQueueList() {
    // Toggle the modal state
    this.isQueueListModalOpen = !this.isQueueListModalOpen;

    // Toggle polling based on the modal state
    if (this.isQueueListModalOpen) {
      this.startPolling();
    } else {
      this.stopPolling();
    }

    // If the modal is open, add a click event listener to close it when clicking outside
    if (this.isQueueListModalOpen) {
      this.addClickOutsideListener();
    } else {
      // If the modal is closed, remove the click event listener (if previously added)
      this.removeClickOutsideListener();
    }
  }

  addClickOutsideListener() {
    if (!this.clickOutsideListener) {
      this.clickOutsideListener = this.renderer.listen(
        'window',
        'click',
        (e: Event) => {
          const modalElement = document.getElementById('queueListToggleButton');

          if (!modalElement?.contains(e.target as Node)) {
            // Clicked outside of the modal, close it and stop polling
            this.isQueueListModalOpen = false;
            this.stopPolling();
            this.removeClickOutsideListener();
          }
        }
      );
    }
  }

  removeClickOutsideListener() {
    if (this.clickOutsideListener) {
      this.clickOutsideListener(); // Remove the click event listener
      this.clickOutsideListener = undefined; // Clear the listener reference
    }
  }

  startPolling() {
    if (!this.pollingSubscription) {
      this.pollingSubscription = interval(1000) // Emit a value every 5 seconds
        .pipe(
          switchMap(() => this.clientadminService.multipleAssetDownload(''))
        )
        .subscribe({
          next: (data: any) => {
            if (data.code == 200) {
              this.queueList = data.result;
            }
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
    }
  }
  stopPolling() {
    // Check if polling is active and unsubscribe to stop it
    this.appservice.delete('dashboard/clearDoneListOfQueue').subscribe({
      next: (data) => {},
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
    if (this.pollingSubscription) {
      this.DropdownMenu.nativeElement.classList.remove('show');
      this.pollingSubscription.unsubscribe();
      this.pollingSubscription = undefined; // Clear the subscription
    }
  }
  deleteAssetFromQueue(e: any) {
    //this.stopPolling();
    this.clientadminService
      .callDeleteQuery('dashboard/deleteFromQueue/', e)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.getQueueList();
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  //to reset input search bar. 153469->Text is not getting vanished after closing search bar
  handleOpenSearchBar() {
    this.inputElementForSearch.nativeElement.value = '';
    this.inputElementForSearch.nativeElement.focus();
  }

  // retry functionality

  retryDownload(
    id: any,
    assetArr: any,
    folderPath: any,
    folderId: any,
    downloadType: any
  ) {
    this.stopPolling();
    if (downloadType == 'M') {
      let FolderPath: any = {};
      FolderPath.Ids = folderId;
      FolderPath.Url = folderPath;
      const cleanedString = assetArr.replace(/[\[\]"\s]/g, '');
      // Split the cleaned string by commas to get an array of links
      const assets = cleanedString.split(',');
      this.dashboardService.muiltDownloadAssets(assets, FolderPath).subscribe({
        next: (res: any) => {
          this.deleteAssetFromQueue(id);
          this.loader.setLoading(false);
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    } else {
      this.dashboardService.folderDownload(folderId, folderPath).subscribe({
        next: (res: any) => {
          this.deleteAssetFromQueue(id);
          this.loader.setLoading(false);
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    }
  }

  maximizeGlobalUploadModal() {
    this.appDataService.stopPollingFlag();
    this.eventEmitterService.emit({
      type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
      data: {
        display: true,
        visible: true,
      },
    });
  }

  ngOnDestroy() {
    this.clientadminService.setOpenQueueTrigger(false);
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
