import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { APP_EVENTS } from 'src/app/core/constants';
import {
  EventData,
  EventEmitterService,
  S3UploadServices,
} from 'src/app/core/services';
import pLimit from 'p-limit';
import { AppDataService } from '../../../core/services/app.data.service';
import { CommonUtils } from 'src/app/common/utils/common.utils';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-global-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [MessageService],
})
export class UploadComponent implements OnInit, OnDestroy {
  @Input() type: string = 'add';
  @Input() folder: any;
  private subscriptions: Subscription[] = [];
  isVisible: boolean = true;
  selectedFilesList: any[] = [];
  selectedFiles: any[] = [];
  selectedFileNameArray: any = [];
  uploadDone: boolean = false;
  message: string[] = [];
  progressList: any = [];
  progressOfUplodingFile: any = [];
  internalServerErrorWithFile: any = [];
  fileAlreadyExistsOrError: any = [];
  successFileNameArray: any = [];
  unSupportedFile: any = [];
  success: boolean = false;
  successFileDetails: any = [];
  commonUtils = new CommonUtils();
  userProfile: any;
  imageRevData: any;
  view = {
    upload: true,
    status: false,
  };
  confirmationModal: boolean;
  totalAssetSize: number = 0;
  dataRefresh: boolean;
  isUploading: boolean;

  constructor(
    private eventEmitterService: EventEmitterService,
    private s3UploadService: S3UploadServices,
    private appDataService: AppDataService,
    private router: Router,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.appDataService.getUserDetails().subscribe({
      next: (user: any) => {
        this.userProfile = user.result;
        if (this.userProfile?.isRequestForImageRevSearch) {
          this.setView('status');
          this.getImageRevStatus();
        }
      },
    });
    this.subscriptions.push(
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            this.isVisible = event.data.visible;
            if (this.isVisible && this.view.status) {
              this.getImageRevStatus();
            }
            break;
        }
      }),
      this.s3UploadService.notificationForProgress.subscribe((data) => {
        const { i, progress } = data;
        if (this.progressOfUplodingFile[i]) {
          // Update the progress if the index exists
          this.progressOfUplodingFile[i].progress = progress;
        } else {
          // If the index doesn't exist, initialize it with 0 progress
          this.progressOfUplodingFile[i] = { i, progress: 0 };
        }
      })
    );
  }

  setView(view: string) {
    if (view === 'upload' && typeof this.totalAssetSize !== 'number') {
      this.totalAssetSize = 0;
    }
    this.view.upload = view === 'upload';
    this.view.status = view === 'status';
  }

  selectFiles(event: any) {
    const inputTag = event.target;
    this.progressOfUplodingFile = [];
    const processFilesChunk = async (
      files: any[],
      startIndex: number,
      chunkSize: number
    ) => {
      const endIndex = Math.min(startIndex + chunkSize, files.length);
      const filesToProcess = files.slice(startIndex, endIndex);
      await Promise.all(filesToProcess.map(processFile));
      if (endIndex < files.length) {
        await processFilesChunk(files, endIndex, chunkSize);
      }
    };

    const processFile = async (file: any) => {
      try {
        if (!this.selectedFileNameArray.includes(file.name)) {
          let obj = {
            file: file,
            status: '',
            message: '',
          };
          let type = file.type;
          if (
            type === 'image/png' ||
            type === 'image/jpeg' ||
            type === 'image/jpg' ||
            type === 'image/WEBP' ||
            type === 'image/webp'
          ) {
            this.totalAssetSize += file.size;
            this.selectedFiles.push(obj);
            this.uploadDone = false;
            this.selectedFilesList = [...this.selectedFiles];
            this.selectedFileNameArray.push(file.name);
          } else {
            this.messageService.add({
              severity: 'warn',
              summary: 'Error!',
              detail: 'File not supported',
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleFiles = () => {
      if (event && event.target && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        const chunkSize = 5;
        processFilesChunk(files, 0, chunkSize);
      } else {
        // let dragArray: any = Array.from(event);
        // console.log('dragArray= ', dragArray);
        // this.selectedFiles.push(dragArray);
        // this.selectedFiles = Array.prototype.concat.apply(
        //   [],
        //   this.selectedFiles
        // );
      }
    };
    this.setView('upload');
    handleFiles();
    event = null;

    inputTag.value = '';
  }

  async uploadFiles() {
    this.message = [];

    if (!this.selectedFilesList || this.selectedFilesList.length === 0) {
      this.message.push('File Required');
      return;
    }

    const batchSize = 25; // Set batch size to 25
    const limit = pLimit(batchSize); // Limit concurrency to 25 uploads

    let ReUploadAssets: any[] = [];
    let i = 0;

    // Function to upload a single file and handle errors
    const uploadFile = async (file: any, index: any) => {
      try {
        this.isUploading = true;
        if (this.uploadDone) {
          if (this.internalServerErrorWithFile.includes(file)) {
            const indexOfFile = this.internalServerErrorWithFile.indexOf(file);
            if (indexOfFile !== -1) {
              this.internalServerErrorWithFile.splice(indexOfFile, 1);
              this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
            }
            return await this.upload(file, index);
          }
        } else {
          if (!this.successFileNameArray.includes(file.name)) {
            return await this.upload(file, index);
          } else {
            this.progressList[index] = 0;
          }
        }
      } catch (error) {
        let obj = {
          file: file,
          status: '',
          message: '',
        };
        this.isUploading = false;
        ReUploadAssets.push(obj);
        this.progressList[index] = 0;
      }
      return null;
    };

    while (i < this.selectedFilesList.length) {
      const batch = this.selectedFilesList.slice(i, i + batchSize);
      const uploadBatchPromises = batch.map((f, index) => {
        return limit(() => uploadFile(f.file, i + index));
      });
      await Promise.all(uploadBatchPromises);
      i += batchSize;
    }
    this.selectedFilesList = ReUploadAssets.concat(this.selectedFilesList);
    this.uploadDone = true;
    this.isUploading = false;
  }

  //----------------------------Single Asset Uplaod Function---------------------------------
  async upload(file: any, i: any) {
    return new Promise(async (resolve) => {
      this.progressList[i] = 5;
      const params = {
        fileName: file?.name,
        folderId: 'TempAssets',
        uploadedBy: '1',
        assetPath: 'TempAssets',
        fileType: file?.type,
        assetSize: file?.size,
      };
      if (file) {
        let event = await this.s3UploadService.uploadFile(params, file, i);
        if (event.code == 200) {
          if (event.result[1] != null) {
            if (this.successFileNameArray.includes(file.name) == false) {
              event.result[1].mimeType = file?.type;
              this.successFileDetails.push(event.result[1]);
              if (this.internalServerErrorWithFile.includes(file)) {
                var index = this.internalServerErrorWithFile.indexOf(file.name);
                if (index !== -1) {
                  this.internalServerErrorWithFile.splice(index, 1);
                }
                this.progressList[i] = 0;
                this.progressOfUplodingFile[i] = 0;
              }
              if (this.fileAlreadyExistsOrError.includes(file.name)) {
                var index = this.fileAlreadyExistsOrError.indexOf(file.name);
                if (index !== -1) {
                  this.fileAlreadyExistsOrError.splice(index, 1);
                }
              }
            }
            resolve((this.success = true));
            this.progressList[i] = 0;
            this.progressOfUplodingFile[i] = 0;
          }
          this.successFileNameArray.push(file.name);
        }

        if (event.result && event.result[0].length > 0) {
          if (this.fileAlreadyExistsOrError.includes(file.name) == false) {
            this.fileAlreadyExistsOrError.push(file.name);
          }
          resolve(false);
          this.progressList[i] = 0;
          this.progressOfUplodingFile[i] = 0;
        }

        if (
          this.fileAlreadyExistsOrError &&
          this.fileAlreadyExistsOrError.length > 0
        ) {
          this.successFileNameArray.push('Error while uploading!');
        } else {
          // this.successFileNameArray.push(file.name);
        }

        if (event.error) {
          if (event.error.code === 400) {
            if (this.fileAlreadyExistsOrError.includes(file.name) == false) {
              this.fileAlreadyExistsOrError.push(file.name);
            }
            if (this.unSupportedFile.includes(file.name) == false) {
              this.unSupportedFile.push(file);
            }
            this.progressList[i] = 0;
            this.progressOfUplodingFile[i] = 0;
            resolve(false);
          } else if (event.error.code == 500) {
            if (this.fileAlreadyExistsOrError.includes(file.name) == false) {
              this.fileAlreadyExistsOrError.push(file.name);
            }
            if (this.internalServerErrorWithFile.includes(file) == false) {
              this.internalServerErrorWithFile.push(file);
            }
            this.progressList[i] = 0;
            this.progressOfUplodingFile[i] = 0;
            resolve(false);
          } else {
            let msg = '';
            if (event.error.message == 'Request Entity Too Large.') {
              msg = 'Please do not upload over 10 files at once.';
            } else {
              msg =
                'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
            }
            this.message.push(msg);
            if (this.fileAlreadyExistsOrError.includes(file.name) == false) {
              this.fileAlreadyExistsOrError.push(file.name);
            }
            if (this.internalServerErrorWithFile.includes(file) == false) {
              this.internalServerErrorWithFile.push(file);
            }
            this.progressList[i] = 0;
            this.progressOfUplodingFile[i] = 0;
            resolve(false);
          }
        }
      }
    });
  }

  removeImage(media: any, idx: number) {
    this.selectedFiles.splice(idx, 1);
    this.selectedFilesList.splice(idx, 1);
    const removedFileSize = media.size;
    this.totalAssetSize -= removedFileSize;
    const fileName = media.name;
    media.name.substring(media.name.lastIndexOf('.') + 1);
    const successFileIndex = this.successFileNameArray.indexOf(fileName);
    if (successFileIndex !== -1) {
      this.successFileNameArray.splice(successFileIndex, 1);
    }
    const fileIndex = this.fileAlreadyExistsOrError.indexOf(fileName);
    if (fileIndex !== -1) {
      this.fileAlreadyExistsOrError.splice(fileIndex, 1);
    }
    this.selectedFileNameArray.splice(idx, 1);

    if (this.selectedFiles.length == 0 || this.selectedFilesList.length == 0) {
      this.uploadDone = false;
      this.success = false;
      this.selectedFiles = [];
      this.successFileDetails = [];
      this.message = [];
      this.selectedFileNameArray = [];
    }
  }

  async singleRetry(file: any, i: any) {
    this.message = [];
    let indexOfFile = this.internalServerErrorWithFile.indexOf(file);
    if (indexOfFile !== -1) {
      this.internalServerErrorWithFile.splice(indexOfFile, 1);
      this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
    }
    this.isUploading = true;
    await this.upload(file, i);
  }

  replaceName(name: any) {
    return (
      name.split(/\.(?=[^\.]+$)/)[0].replace(/[^A-Z0-9]+/gi, '_') +
      '.' +
      name.split(/\.(?=[^\.]+$)/)[1]
    );
  }

  submitImages() {
    let assetArr = this.commonUtils.getValuesFromArray(
      this.successFileDetails,
      '',
      '',
      'assetS3Url'
    );
    let payload = {
      assetArr: [...assetArr],
    };
    this.appDataService.uploadRevImages(payload).subscribe({
      next: () => {
        this.selectedFilesList = [];
        this.successFileDetails = [];
        this.selectedFiles = [];
        this.setView('status');
        this.getImageRevStatus();
      },
      error: () => {},
    });
  }

  getImageRevStatus(afterDelete?: boolean) {
    this.appDataService.getImageRevStatus().subscribe({
      next: (data: any) => {
        this.imageRevData = data.result || [];
        this.appDataService.updatePollingStatus(this.imageRevData);
        if (this.imageRevData.length === 0) {
          if (afterDelete || this.view.status) {
            this.setView('upload');
          }
        }
      },
      error: (data: any) => {},
    });
  }

  rotateAnim() {
    this.dataRefresh = true;
    setTimeout(() => {
      this.dataRefresh = false;
    }, 1500);
  }

  minimize() {
    this.eventEmitterService.emit({
      type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
      data: {
        display: true,
        visible: false,
      },
    });
  }

  onAssetAction(action: string, asset?: any) {
    switch (action) {
      case 'redirect':
        this.minimize();
        const url = this.router.serializeUrl(
          this.router.createUrlTree(
            ['/dashboard/' + asset.jobId + '/image-reverse-search'],
            {
              queryParams: {},
            }
          )
        );
        window.open(url, '_blank');
        break;
      case 'delete':
        var payload = {
          jobIdArr: [asset.jobId],
        };
        this.appDataService.deleteImageRevJobs(payload).subscribe({
          next: (user: any) => {
            this.getImageRevStatus(true);
            this.uploadDone = false;
            this.successFileDetails = [];
            this.selectedFiles = [];
            this.selectedFilesList = [];
            this.successFileNameArray = [];
            this.selectedFileNameArray = [];
          },
          error: () => {},
        });
        break;
      case 'cancel':
        this.eventEmitterService.emit({
          type: APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD,
          data: {
            display: false,
            visible: false,
          },
        });
        break;
      case 'clear':
        this.confirmationModal = true;
        this.totalAssetSize = 0;
        break;
      case 'deleteAll':
        let jobIdArr = this.commonUtils.getValuesFromArray(
          this.imageRevData,
          '',
          '',
          'jobId'
        );
        var payload = {
          jobIdArr: [...jobIdArr],
        };
        this.appDataService.deleteImageRevJobs(payload).subscribe({
          next: (user: any) => {
            this.getImageRevStatus(true);
            this.uploadDone = false;
            this.successFileDetails = [];
            this.selectedFiles = [];
            this.selectedFilesList = [];
            this.successFileNameArray = [];
            this.selectedFileNameArray = [];
            this.setView('upload');
            this.confirmationModal = false;
          },
          error: () => {},
        });
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
