import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppService } from './core/services/app.service';
import { httpInterceptorProviders } from './core/interceptors/refresh-token.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SpinnerComponent } from './common/components/spinner/spinner.component';
import { DropdownModule } from 'primeng/dropdown';
import { CanDeactivateGuard } from './common/guards/can-deactivate.guard';
import { SharedAssetComponent } from './common/components/shared-asset/shared-asset.component';
import { DatePipe } from '@angular/common';
import { CalendarModule } from 'primeng/calendar';
import { CoreModule } from './core/core.module';
import {
  LoadingInterceptor,
  PassTokenInterceptorProviders,
} from './core/interceptors';
import { AssetNotFoundComponent } from './common/components/asset-not-found/asset-not-found.component';
import { SharedModule } from './shared/shared.module';
import { UploadComponent } from './common/components/upload/upload.component';
import { DialogModule } from 'primeng/dialog';
import { ToastModule } from 'primeng/toast';


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    SharedAssetComponent,
    AssetNotFoundComponent,
    UploadComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    DropdownModule,
    DialogModule,
    DatePipe,
    CalendarModule,
    ToastModule
  ],
  providers: [
    AppService,
    CanDeactivateGuard,
    httpInterceptorProviders,
    DatePipe,
    PassTokenInterceptorProviders,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
