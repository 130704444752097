import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject }from 'rxjs';
import { environment } from 'src/app/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class S3UploadServices {
  public api_url = environment.apiUrl;
  constructor(private http: HttpClient) {
  }

  // For Tracking Upload Progress

  private progressForFileUpload$ = new BehaviorSubject<any>(0);
  notificationForProgress = this.progressForFileUpload$.asObservable();

  count : any = 0;


  
  //function to set new map object for subscribers
  updateProgressValue(val: any) {
    this.progressForFileUpload$.next(val);
  }

  // function to Upload File 

  async uploadFile(params: any, file: File, index: any) {
    const checkFileSize = params.assetPath == "TempAssets" ? 500 * 1024 * 1024 : 100 * 1024 *1024;
    if (file?.size <= checkFileSize ) { // checking if size of asset is less than 100 mb uploading it directly to S3
      try {
        const params1 = {
          assetName: file?.name,
          assetPath: params.assetPath,
          mimetype: file?.type,
          assetSize: file?.size,
          folderId: params.folderId,
          folderOrigin: params.folderOrigin,
          uploadedBy: params.uploadedBy
        };
  
        const getUploadUrlResp: any = await this.http.post(`${this.api_url}asset/getPresignedUrl`, params1).toPromise()
        if(getUploadUrlResp.message === 'Asset already exist')
        {
          return getUploadUrlResp;
        }
        else{
          const presignedUrl = getUploadUrlResp.result[1].preSignedUrl;
          const response = environment.infra == "aws" ? await this.uploadFileToS3(presignedUrl, file, index ) : await this.uploadFileToGCP(presignedUrl, file, index ) ;
          return getUploadUrlResp;
        }
        
      } catch (error) {
        return error;
      }
    } else {
      try {
        let uploadId: any;
        let count = 0;

        // Calling InitiateMultipart API to initiate MultiPart Upload
  
         const resp: any  = await this.http.post(`${this.api_url}asset/initiateMultipart`, params).toPromise();
         
        if (resp.message === "Multipart Initiated") {
          uploadId = resp.result;
          return await this.uploadMultipartFile(file, uploadId, params.assetPath, params, index, count);
        } else {
          return resp;
        }
      } catch (err) {
        return err;
      }
    }
  }
  
// function to generate PreSigned Url for each chunks and then Uploading it.

  async uploadMultipartFile(file: File, uploadId: any, folderPath: string, data: any, i: any, count : any) {
    let uploadPartsArray: any[] = [];
    let uploadPromises: Promise<any>[] = [];
    let progress: any = 0 ;
    try {
      const fileSize = file.size;
      const CHUNK_SIZE = 10 * 1024 *1024 ; // 10MB
      const CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
      this.updateProgressValue({ progress: progress, i });
      for (let index = 1; index < CHUNKS_COUNT + 1; index++) {
        const start = (index - 1) * CHUNK_SIZE;
        const end = index * CHUNK_SIZE;
        const blob = (index < CHUNKS_COUNT) ? file.slice(start, end) : file.slice(start);
        const params = {
          fileName: file?.name,
          folderPath: folderPath,
          uploadId: uploadId,
          partNumber: index,
        }
        // Get presigned URL for each part
      
          const getUploadUrlResp: any= await this.http.post(`${this.api_url}asset/getPresignedUrlUnique`, params).toPromise();
       
        const presignedUrl = getUploadUrlResp.result;
        if(environment.infra === "aws"){
          const uploadPromise = this.uploadPart(presignedUrl, blob, index, i, file, CHUNKS_COUNT, uploadPartsArray, progress, params)
        .then((response: any) => {
          count++;
          progress = (Math.round(100 * count / CHUNKS_COUNT));
          this.updateProgressValue({ progress: progress, i });
        });
        uploadPromises.push(uploadPromise);
        }
        else{
          const uploadPromise = this.uploadPartGCP(presignedUrl, blob, index, i, file, CHUNKS_COUNT, uploadPartsArray, progress)
        .then((response: any) => {
          count++;
          progress = (Math.round(100 * count / CHUNKS_COUNT));
          this.updateProgressValue({ progress: progress, i });
        });
        uploadPromises.push(uploadPromise);
        }
      }

      // Use Promise.all to wait for all parts to be uploaded concurrently
      const uploadResponses = await Promise.all(uploadPromises);
      
      // sorting UploadPartsArray in ascending order in accordance to their PartNumber
      uploadPartsArray.sort((a, b) => a.PartNumber - b.PartNumber);
      let api = "asset/completeMultiPartUpload"
      const completeUploadResp: any = await this.http.post(
        `${this.api_url}${api}`,
        {
          params: {
            fileName: file?.name,
            folderPath: folderPath,
            parts: uploadPartsArray,
            uploadId: uploadId,
            folderId: data.folderId,
            uploadedBy: '1',
            assetPath: data.assetPath,
            folderOrigin: data.folderOrigin,
            assetSize: data.assetSize,
          },
        }
      ).toPromise();
      this.updateProgressValue(null);
      uploadPartsArray = [];
      uploadPromises = [];
      count=0;
      progress=0;
      return completeUploadResp;
    }
    catch (err) {
      this.updateProgressValue(null);
      uploadPartsArray = [];
      uploadPromises = [];
      count=0;
      progress=0;
      return err   
    }
  }

  // function to upload part to s3 using PreSinged URL

  async uploadPart(presignedUrl: string, blob: Blob, index: any, i: any, file: File, CHUNKS_COUNT: any, uploadPartsArray: any, progress: any,params: any, maxRetries: number = 3): Promise<any> {
    const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
      const headers = new HttpHeaders({
        'Content-Type': fileType
      });
  
    let retries = 0;
  
    const attemptUpload = async (): Promise<any> => {
      try {
        const response: any = await this.http.put(presignedUrl, blob, { headers, observe: 'response' })
          .toPromise();
        const etag = response.headers.get('Etag');
        uploadPartsArray.push({
          ETag: etag,
          PartNumber: index,
        });
        return response;
      } catch (error) {
        if (retries < maxRetries) {
          retries++;
          const getUploadUrlResp: any = await this.http.post(`${this.api_url}asset/getPresignedUrlUnique`, params).toPromise();
          presignedUrl = getUploadUrlResp.result;
          console.log(`Upload attempt ${retries} failed. Retrying...`);
          return attemptUpload(); // Retry the upload
        } else {
          console.error(`Max retries reached. Upload failed for chunk ${index}.`);
          uploadPartsArray.push(error);
        }
      }
    };
    return attemptUpload();
  }

  async uploadPartGCP(presignedUrl: string, blob: Blob, index: any, i: any, file: File, CHUNKS_COUNT: any, uploadPartsArray: any, progress: any) {
    const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
      const headers = {
        'Content-Type': fileType
      };
  
    try {
      const response = await fetch(presignedUrl, {
        method: 'PUT',
        headers: new Headers(headers),
        body: blob,
      });
  
      if (!response.ok) {
        throw new Error('Failed to upload part');
      }
  
      const etag = response.headers.get('ETag');
      uploadPartsArray.push({
        ETag: etag,
        PartNumber: index,
      });
  
      return response;
    } catch (error) {
      uploadPartsArray.push(error);
      throw error;
    }
  }
  
  uploadFileToGCP(presignedUrl: string, file: File, i: any): Promise<any> {
    return new Promise((resolve, reject) => {
      // Update progress
      this.updateProgressValue({ progress : 0, i });
      const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
      const headers = {
        'Content-Type': fileType
      };
  
      // Create a new request with the presigned URL and headers
      const request: any = new Request(presignedUrl, {
        method: 'PUT',
        headers: new Headers(headers),
        body: file,
      });
  
      // Perform the fetch request
      fetch(request)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response;
        })
        .then((response: any) => {
          // Track upload progress using a ReadableStream
          const reader = response.body.getReader();
          const contentLength = parseInt(response.headers.get('Content-Length') || '0');
          let bytesUploaded = 0;
  
          reader.read().then(function processResult(this: any, result: any) {
            if (result.done) {
              resolve(response); // File upload completed successfully
              return;
            }
  
            bytesUploaded += result.value.length;
 
            // Calculate progress percentage
            const progress = Math.round((bytesUploaded / contentLength) * 100);
            // Update progress
            this.updateProgressValue({ progress, i });
  
            // Continue reading the stream
            reader.read().then(processResult);
          });
        })
        .catch(error => {
          throw error;
          reject(error); // File upload failed
        });
    });
  }

  uploadFileToS3(presignedUrl: string, file: File, i: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const fileType = file.type == 'text/plain' ? 'application/octet-stream' : file.type ;
      const headers = {
        'Content-Type': fileType
      };

      this.http.put(presignedUrl, file, {
        headers: headers,
        reportProgress: true, // if you want progress updates
        observe: 'events'
      })
      .subscribe({
        next: (event: any)=> {
          if (event.type === HttpEventType.UploadProgress) {
            if (event.total !== undefined) {
              const percentDone = Math.round((100 * event.loaded) / event.total);
              // You can handle progress updates here if needed
              this.updateProgressValue({ progress: percentDone, i });
            } else {
              // Handle the case where event.total is undefined
            }
          } else if (event.type === HttpEventType.Response) {
            resolve(event.body); // File upload successful
          }
        },
        error:(error: any) => {
          console.log("i am in the error")
          reject(error); // File upload failed
        }
    });
    });
  }
}



