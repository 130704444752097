import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PermissionsEngine } from './common/permission/permission';
import { SharedAssetComponent } from './common/components/shared-asset/shared-asset.component';
import { FolderMgmtUtill } from './common/utils/folderMgmtUtill';
import { AssetNotFoundComponent } from './common/components/asset-not-found/asset-not-found.component';
import { AccessDeniedComponent } from './shared/components/access-denied/access-denied.component';
import { ClientadminService } from './core/services';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'client-admin',
    loadChildren: () =>
      import('./client-admin/client-admin.module').then(
        (m) => m.ClientAdminModule
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  },
  { path: 'share-asset/:encryptedAssetId', component: SharedAssetComponent },
  { path: 'asset-not-found', component: AssetNotFoundComponent },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: AccessDeniedComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: false,
      paramsInheritanceStrategy: location.search.startsWith('?always')
        ? 'always'
        : 'emptyOnly',
    }),
  ],
  providers: [ClientadminService, PermissionsEngine, FolderMgmtUtill],
  exports: [RouterModule],
})
export class AppRoutingModule {}
