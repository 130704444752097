import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ClientadminService } from 'src/app/core/services';
import { AppService } from 'src/app/core/services/app.service';

@Component({
  selector: 'app-sidenav-admin',
  templateUrl: './sidenav-admin.component.html',
  styleUrls: ['./sidenav-admin.component.css'],
})
export class SidenavAdminComponent implements OnInit {
  public routerConfig: any = [];
  ishideMenu: boolean = true;
  userDetails: any;
  isUserRole: any;
  isUserRoleId: any;
  getUserName: any;
  constructor(
    private _router: Router,
    private appservice: AppService,
    private clientadminService: ClientadminService
  ) {
    this.userDetails = this.appservice.getUserdata();
    if (this.userDetails && this.userDetails.role) {
      this.isUserRoleId = this.userDetails.roleId.toString();
      //this.isUserRoleId = '4';

      this.isUserRole = this.userDetails.role;
    }
  }

  ngOnInit() {
    this.initData();
    this.getLoggedInUserData();
  }
  logout() {
    localStorage.clear();
    location.reload();
    window.location.href = 'auth/login';
    // this._router.navigateByUrl('/auth/login');
  }

  initData() {
    this.routerConfig = [
      {
        url: 'client-admin/dashboard',
        label: 'Dashboard',
        //  isShowMenu: this.isUser(['admin']),
        isShowMenu: true,
        moduleSlug: 'dashboard',
      },
      {
        url: 'client-admin/role-config',
        label: 'User Role Configuration',
        //isShowMenu: this.isUser(['admin']),
        isShowMenu: true,
        moduleSlug: 'user_role_configuration',
      },
      {
        url: 'client-admin/user-list',
        label: 'Users',
        //isShowMenu: this.isUser(['admin']),
        isShowMenu: true,
        moduleSlug: 'users',
      },
      {
        url: 'client-admin/folder-management',
        label: 'Folder Management',
        // isShowMenu: this.isUser(['mediaUploader','mediaValidator']),
        isShowMenu: true,
        moduleSlug: 'folder_management',
      },
      {
        url: 'client-admin/tag-repository',
        label: 'Tag Repository',
        isShowMenu: true,
        moduleSlug: 'tag_repository',
      },
      {
        url: 'client-admin/media-uploader',
        label: 'Media Uploader',
        isShowMenu: true,
        moduleSlug: 'media_uploader',
      },
      {
        url: 'client-admin/media-validator',
        label: 'Media Validator',
        isShowMenu: true,
        moduleSlug: 'media_validator',
      },
      {
        url: 'client-admin/event-management',
        label: 'Event Management',
        isShowMenu: true,
        moduleSlug: 'event_management',
      },
      {
        url: 'client-admin/untagged-uploads',
        label: 'Untagged Uploads',
        isShowMenu: true,
        moduleSlug: 'untagged_uploads',
      },
      {
        url: 'client-admin/tagged-uploads',
        label: 'Tagged Uploads',
        isShowMenu: true,
        moduleSlug: 'tagged_uploads',
      },
    ];
    //   this.permissionsEngine.getNecessaryModules(this.routerConfig).then((data) => {
    //   this.routerConfig = data;
    // })
  } //initData

  getLoggedInUserData() {
    this.clientadminService
      .callGetQuery('', 'user/userDetails/', this.userDetails.userId)
      .subscribe((data: any) => {
        this.getUserName = data.result.firstName;
      });
  }
  redirect(item: any) {
    // if (item.url === 'client-admin/media-uploader') {
    //   this.routerConfig.forEach((element: any) => {
    //     element.isShowMenu = false;
    //     element.ishideMenu = true;

    //   });
    // }
    this._router.navigate([item.url]);
  }
  // isUser(arr: String[]): boolean {
  //   if (!!this.isUserRoleId && arr.indexOf(this.isUserRoleId.toLowerCase()) > -1) {
  //     return true;
  //   }
  //   return false;
  // }
} //SidenavAdminComponent
