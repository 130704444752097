import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rows',
  templateUrl: './rows.component.html',
  styleUrls: ['./rows.component.css'],
})
export class RowsComponent {
  @Input() totalRecords: any;

  constructor() {}

  ngOnInit(): void {}
  @Output()
  onPageChange: EventEmitter<string> = new EventEmitter<string>();

  onPaginatorChanged(event: any) {
    this.onPageChange.emit(event);
  }
}
