import { Component, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.css']
})
export class ToolbarComponent {
  constructor(private eRef: ElementRef) { }
  @Input() x = 0;
  @Input() y = 0;
  @Input() menuitems = '';
  theMenuItems: any = [];
  @Output() menuItemSelected = new EventEmitter();
  @Output() clickedOutside = new EventEmitter();
  ngOnInit() {
    this.theMenuItems = this.menuitems;
  }

  outputSelectedMenuItem(menuitem: any) {
    this.menuItemSelected.emit(menuitem.id);
  }
  @HostListener('document:click', ['$event'])
  clickout(event:any) {
    let clickedInside = this.eRef.nativeElement.contains(event.target);
      if (!clickedInside) {
          this.menuItemSelected.emit();
      }
  }
}
