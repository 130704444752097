export const environment = {
  label: 'Devlopement',
  production: false,
  infra:'aws',
  apiUrl: 'https://dev.tagsmarter.com/callback/',
  s3BaseUrl: 'https://nmarchive-images-dev.narendramodi.in/',
  // S3 BUCKET URL TO BE PASSED WHILE CROPPING/TRIMMING ASSETS
  s3Url: 'https://s3.ap-south-1.amazonaws.com/damdevbucket-1/',
  // S3 BaseUrl for Pre-SignedUrl
  s3PresignedBaseUrl: 'https://damdevbucket-1.s3.ap-south-1.amazonaws.com/',
  // Transfer_Accelerated_URl
  TRANSFER_ACCELERATED_URL:
    'https://damdevbucket-1.s3-accelerate.amazonaws.com/',
  secretKey: '#@1234@#',
  // G-drive properties
  // TODO(developer): Set to client ID and API key from the Developer Console
  CLIENT_ID:
    '440127083145-nnnj76pj6n3u2gbgg68jtcl8prpjrse1.apps.googleusercontent.com',
  API_KEY: 'AIzaSyCmvswrvDBpUID-YtcPVX_GEe6v-v_NzRw',
  // Discovery doc URL for APIs used by the quickstart
  DISCOVERY_DOC: 'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest',
  // Authorization scopes required by the API; multiple scopes can be
  // included, separated by spaces.
  SCOPES: 'https://www.googleapis.com/auth/drive.file',
  // S3BucketName
  BUCKET: 'damdevbucket-1',
  // to enable disable logs
  logsEnabled: false,
  //GCP BaseUrl
  GCPBaseUrl : 'https://storage.googleapis.com/jio-dam-dev-api-bucket/'
};
