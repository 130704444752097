export const CLIPLIST_SORT_BY = [
  { name: 'Select', code: '' },
  { name: 'Asset Size', code: 'assetSize' },
  // { name: 'Asset Created At', code: 'assetCreatedAt' },
  // { name: 'Asset Name', code: 'assetName' },
  { name: 'Event Date', code: 'eventDate' },
  // { name: 'Event Location', code: 'eventLocation' },
  { name: 'Event Name', code: 'eventName' },
  { name: 'No. of downloads', code: 'downloads' },
];

export const SORT_BY_OPTIONS = [
  { id: 1, value: '', label: '-- Select --' },
  { id: 2, value: 'assetSize', label: 'Asset Size' },
  { id: 3, value: 'eventDate', label: 'Event Date' },
  { id: 4, value: 'eventName', label: 'Event Name' },
  { id: 5, value: 'downloads', label: 'No. of downloads' },
];

export const ORDER_BY_OPTIONS = [
  { id: 1, value: 'asc', label: 'Ascending' },
  { id: 2, value: 'desc', label: 'Descending' },
];
export const ASSET_TYPE_OPTIONS = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Images' },
  { id: 3, value: 2, label: 'Videos' },
  { id: 4, value: 4, label: 'Audios' },
];
export const ASSET_TYPE_OPTIONS_2 = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Images' },
  { id: 3, value: 2, label: 'Videos' },
  { id: 4, value: 3, label: 'Documents' },
  { id: 5, value: 4, label: 'Audios' },
];

export const PAGE_SIZES_OPTIONS = [
  { id: 1, value: 50, label: '50' },
  { id: 2, value: 100, label: '100' },
  { id: 3, value: 200, label: '200' },
  { id: 4, value: 500, label: '500' },
  { id: 5, value: 1000, label: '1000' },
];

export const ASSET_TYPE_FILTERS = [
  { id: 0, assetType: 'All', isSelected: true },
  { id: 1, assetType: 'Images', isSelected: false },
  { id: 2, assetType: 'Videos', isSelected: false },
  { id: 3, assetType: 'Audios', isSelected: false },
];

export const ACCESS_TYPE_FILTERS = [
  { id: 1, name: 'Read' },
  { id: 2, name: 'Read-Upload' },
  { id: 3, name: 'Read-Write-Upload' },
  { id: 4, name: 'Read-Write-Upload-Delete' },
];

export const TAG_STATUS_TYPE_OPTIONS = [
  { id: 1, value: 0, label: 'All', selectAll: true },
  { id: 2, value: 1, label: 'Completed' },
  { id: 3, value: 2, label: 'Pending' },
];

export const TAG_TYPE_FILTERS = [
  { tagName: 'Manual' },
  { tagName: 'AI' },
  { tagName: 'Default' },
];
export const APP_CONSTANTS = {
  PAGE_SIZE: 10,
  PAGE_SIZE_50: 50,
};
export const APP_EVENTS = {
  BROWSER_BACK: 'BROWSER_BACK',
  TOGGLE_CLIENT_ADMIN_OVERFLOW: 'TOGGLE_CLIENT_ADMIN_OVERFLOW',
  HIDE_HEADER: 'HIDE_HEADER',
  TOGGLE_DIS_F_GLOB_UPLOAD: 'TOGGLE_DISPLAY_FOR_GLOBAL_UPLOAD'
};

export const SORT_BY_OPTIONS_2 = [
  { id: 1, value: '', label: '-- Select --' },
  { id: 2, value: 'assetSize', label: 'Asset Size' },
  { id: 3, value: 'eventDate', label: 'Event Date' },
  { id: 4, value: 'eventName', label: 'Event Name' },
];

export const PEOPLE_MANAGEMENT_FILTER = [
  { id: 1, value: '', label: 'All' },
  { id: 2, value: '1', label: 'Named' },
  { id: 3, value: '2', label: 'Un Named' },
  { id: 4, value: '3', label: 'Hidden' },
];
