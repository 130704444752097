import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AppService, ClientadminService } from 'src/app/core/services';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-add-to-collection',
  templateUrl: './add-to-collection.component.html',
  styleUrls: ['./add-to-collection.component.scss'],
})
export class AddToCollectionComponent {
  userDetails: any;
  userId: any;
  isDeleteCollectionModal: Boolean = false;
  isDeleteModalShow: Boolean = false;
  isSingleCollection: Boolean = false;
  isOpenCollectionModal: boolean = false;
  collectionListForm: any = '';
  multiAssetList: any = [];
  isCreateModalShow: Boolean = false;
  createCollectionFrom: any = '';
  removeData: any;
  collectionArr: any = [];
  collectionList: any = [];
  remainingText: any;
  totalText = 100;
  assetArr: any = [];
  flattenedItem: any;
  @Input() singleCollectionDetails: any;
  @Input() itemsavetocollection: any;
  @Input() eventtocollection: any;
  @Input() assetDetails: any;
  @Output() collectionIdEmiiter = new EventEmitter<any>();
  selectedItems: any;
  @Output() closeCollectionModal = new EventEmitter<any>();
  @Output() openDeleteCollectionModalCollection = new EventEmitter<any>();

  constructor(
    public appService: AppService,
    private dashboardService: DashboardService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
    private clientadminService: ClientadminService
  ) {
    this.removeData =
      'Are you sure you want to remove the asset(s) from the collection?';

    this.userDetails = this.appService.getUserdata();
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
    }
    this.collectionListForm = new FormGroup({
      require: new FormControl('allRecords'),
      search: new FormControl(''),
      assetType: new FormControl(''),
      sort: new FormControl({ '': 'DESC' }),
      userId: new FormControl(this.userId),
    });
    /* Add Reactive Form for create collection */
    this.createCollectionFrom = new FormGroup({
      // userId: new FormControl(this.userId),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern('^[A-Za-z0-9_ -]+$'),
      ]),
    });
  }

  ngOnInit() {
    this.selectedItems = {};
    if (this.singleCollectionDetails && this.singleCollectionDetails._source) {
      this.selectedItems = {
        ...this.singleCollectionDetails._source,
        _index: this.singleCollectionDetails._index,
        _id: this.singleCollectionDetails._id,
        id: this.singleCollectionDetails.id,
        _score: this.singleCollectionDetails._score,
        sort: this.singleCollectionDetails.sort,
        isSelected: this.singleCollectionDetails.isSelected,
      };
    } else {
      this.selectedItems = this.singleCollectionDetails;
    }

    if (!this.selectedItems._id) {
      if (this.selectedItems['assetId']) {
        this.selectedItems['_id'] = this.selectedItems['assetId'];
        delete this.selectedItems['assetId'];
      }
      if (this.selectedItems['id']) {
        this.selectedItems['_id'] = this.selectedItems['id'];
        delete this.selectedItems['id'];
      }
    }

    if (this.eventtocollection === 'single') {
      if (this.selectedItems && this.selectedItems.collectionId) {
        this.isDeleteModalShow = true;
        this.isDeleteCollectionModal = true;
      } else {
        this.isOpenCollectionModal = true;
        this.isSingleCollection = true;
      }
    } else {
      this.isOpenCollectionModal = true;
      this.isSingleCollection = false;
    }
    this.getCollectionList();
  }
  getCollectionList() {
    this.clientadminService
      .callGetAllList(
        'collections/fetchUserCollectionAssetIds',
        this.collectionListForm.value
      )
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.collectionList = data.result;
            this.cdr.detectChanges();
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!!!',
            detail: error.error.message,
          });

          this.collectionList = null;
        },
      });
  }
  closeToCollectionModal() {
    this.closeCollectionModal.emit(false);
  }

  // openCollectionModal(asset: any, event: any) {
  //   //document.getElementsByTagName('body')[0].style.overflow = 'hidden';
  // }

  resetInput() {
    this.createCollectionFrom.reset();
  }

  afterReassignAssetsToACollection() {
    this.assetArr = [];
    this.collectionArr = [];
    this.isSingleCollection = false;
    this.isOpenCollectionModal = true;
  }

  async saveToCollection(data: any) {
    this.multiAssetList = [];
    this.collectionArr.push(data.id);
    this.collectionIdEmiiter.emit(data.id);
    if (this.isSingleCollection && this.selectedItems.collectionId) {
      this.openDeleteCollectionModal();
    }
    if (this.isSingleCollection && !this.selectedItems.collectionId) {
      let userdata = this.appService.getUserdata();

      this.assetArr.push({
        assetId: this.selectedItems?._id,
        folderId: this.selectedItems.folderId,
      });

      const queryparams = {
        userId: userdata.userId,
        assetArr: this.assetArr,
        collectionId: data.id,
      };
      this.dashboardService.reassignAssetsToACollection(queryparams).subscribe({
        next: async (data: any) => {
          if (data.code == 200) {
            // this.router.navigate(['/dashboard/collections']);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Asset(s) saved to Collection successfully!',
            });
            this.assetArr = [];
            this.collectionArr = [];
            this.isSingleCollection = false;
            this.isOpenCollectionModal = false;
            this.cdr.detectChanges();
            this.closeCollectionModal.emit(true);
          }
        },
        error: (error) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
          this.assetArr = [];
          this.collectionArr = [];
        },
      });
    } else {
      //loop to remove source
      for (const item of this.assetDetails) {
        if (item._source) {
          this.flattenedItem = {
            ...item._source,
            _index: item._index,
            _id: item._id,
            id: item.id,
            _score: item._score,
            sort: item.sort,
            isSelected: item.isSelected,
          };
          this.multiAssetList.push(this.flattenedItem);
        } else {
          this.multiAssetList.push(item);
        }
      }

      //to key in _id format for redirection
      if (!this.multiAssetList._id) {
        for (const element of this.multiAssetList) {
          if (element['assetId']) {
            element['_id'] = element['assetId'];
            //delete element['assetId'];
          }
          if (element['id']) {
            element['_id'] = element['id'];
            //delete element['id'];
          }
        }
      }

      this.multiAssetList.forEach((asset: any) => {
        if (asset.isAssetSelected == true || asset.isSelected == true) {
          if (asset.collectionId) {
            this.assetArr.push({
              assetId: asset._id,
              collectionId: asset.collectionId,
              folderId: asset.folderId,
            });
            this.cdr.detectChanges();
          } else {
            this.assetArr.push({
              assetId: asset._id,
              folderId: asset.folderId,
            });
            this.cdr.detectChanges();
          }
        }
      });
      let userdata = this.appService.getUserdata();
      const queryparams = {
        userId: userdata.userId,
        assetArr: this.assetArr,
        collectionId: data.id,
      };

      this.dashboardService.reassignAssetsToACollection(queryparams).subscribe({
        next: async (data: any) => {
          if (data.code == 200) {
            // this.router.navigate(['/dashboard/collections']);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Asset(s) saved to Collection successfully!',
            });
            this.assetArr = [];
            this.collectionArr = [];
            this.isOpenCollectionModal = false;
            this.cdr.detectChanges();
            this.closeCollectionModal.emit(true);
          }
        },
        error: (error) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
          this.assetArr = [];
          this.collectionArr = [];
        },
      });
    }
  }

  /* open modal for delete collection confirmation modal  */
  openDeleteCollectionModal() {
    this.isDeleteModalShow = true;
    this.isDeleteCollectionModal = true;
  }

  /* open modal for create collection  */
  openCreateCollection() {
    this.isCreateModalShow = true;
  }

  /* close modal for create collection  */
  onCancel() {
    this.createCollectionFrom.reset();
    this.isCreateModalShow = false;
  }

  valueChange(value: any) {
    this.remainingText = this.totalText - value;
  }

  /* API integration for create collection  */
  async submitFormData(createCollectionFrom: any) {
    this.createCollectionFrom.value.name = this.createCollectionFrom.value.name
      .trim()
      .replace(/\s+/g, ' ');
    if (this.createCollectionFrom.valid) {
      const queryParams = {
        name: this.createCollectionFrom.value.name,
        userId: this.userId,
      };
      this.clientadminService
        .callCreateQuery(queryParams, 'collections/createCollection')
        .subscribe({
          next: async (data: any) => {
            if (data.code == 200) {
              this.messageService.add({
                severity: 'success',
                summary: 'Success!',
                detail: 'Collection created successfully',
              });
              this.isCreateModalShow = false;
              this.cdr.detectChanges();
              this.getCollectionList();
              this.createCollectionFrom.reset();
            } else {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: 'Something went wrong please try later',
              });
            }
          },
          error: (error) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message || 'The input value is invalid',
            });
            this.createCollectionFrom.reset();
          },
        });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }

  openToCreateCollection() {
    this.isCreateModalShow = true;
  }

  closedeleteModal() {
    this.isDeleteModalShow = false;
    this.isDeleteCollectionModal = false;
    this.closeCollectionModal.emit(false);
  }

  /* delete collection API integration  */
  deleteCollectionFromAsset() {
    this.assetArr = [];
    this.assetArr.push({
      assetId: this.selectedItems._id,
      folderId: this.selectedItems.folderId,
    });
    const param: any = {
      assetArr: this.assetArr,
      collectionId: [this.selectedItems.collectionId],
      userId: this.userId,
    };
    this.clientadminService
      .callGetAllList('collections/deleteAssetsFromSingleCollection/', param)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.closeCollectionModal.emit(true);

            this.isDeleteModalShow = false;
            this.isDeleteCollectionModal = false;
            this.assetArr = [];
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Selected asset removed from the collection',
            });
          }
        },
        error: (error: any) => {
          this.assetArr = [];
          this.isDeleteModalShow = false;
          this.isDeleteCollectionModal = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }
}
