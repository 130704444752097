import {
  ElementRef,
  Component,
  Output,
  EventEmitter,
  Input,
  HostListener,
  ViewChild,
  ChangeDetectorRef,
  Renderer2,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { AppService } from 'src/app/core/services/app.service';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from 'src/app/common/utils/folderMgmtUtill';
import { FormGroup, FormControl, Validators, NgForm } from '@angular/forms';
import { GdriveShare } from 'src/app/common/gdriveshare/gdriveshare';
import { environment } from '../../../environment/environment';
import { NgxPhotoEditorService } from 'ngx-photo-editor';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionsEngine } from '../../../common/permission/permission';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { LoaderService } from 'src/app/core/services/loader.service';
import { FormatS3UrlPipe } from '../../pipes';
import { ClientadminService } from 'src/app/core/services';

interface ITagMore {
  shortTagArrManual: Array<String>;
  longTagArrManual: Array<String>;
  showMoreManualTag: Boolean;
}

//Interfaces for creating root folder
interface IFolder {
  folderId: Number;
  parentId: Number;
  folderName: String;
  folderPath: String | null;
  folderAccess: Array<IFolderAccess>;
  folderOrigin: Number;
} //IFolder

interface IFolderAccess {
  assignedTo: Number;
  roleId: Number;
  accessType: Number;
} //IFolderAccess

interface IAccessType {
  id: Number;
  name: String;
}

interface Iuser {
  id: Number;
  roleId: Number;
  firstName: String;
  lastName: String;
  roleName: String;
  email: String;
  contactNumber: String;
  active: Boolean;
  profession: String;
  isDisabled: Boolean;
}

@Component({
  selector: 'app-custom-context-menu',
  templateUrl: './custom-context-menu.component.html',
  styleUrls: ['./custom-context-menu.component.scss'],
})
export class CustomContextMenuComponent {
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    children: [],
  };
  firstItemsCoreAssets: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    children: [],
  };
  // Variables for integrate parent and child methods and data
  @Output() triggerMethod = new EventEmitter();
  @Output() childCreateFolderEvent = new EventEmitter<string>();
  @Input() isAssetView: Boolean;
  @Input() iszipUploadSelect: Boolean;
  @Input() show_assets_acn_btn: boolean;
  @Input() isCollapse: any;
  @Input() parentId: any;
  @Input() folderDetails: any;
  @Input() objectForSourceTrack: any;
  @Input() childFolderId: any;
  @Input() modalType: any;
  @Input() contextMenuSelection: any;
  @Input() userType: any;
  @Input() isCreateSelect: any;
  @Input() displayCoreAssetsFolderStructure = false;
  @Input() isRootCreateFolder: any;
  @Input() folderOrigin: any;
  @Output() menuEmitter = new EventEmitter();
  @Output() assetsUpdate = new EventEmitter();
  @ViewChild('toggleFolderShareButton') toggleFolderShareButton: ElementRef;
  @ViewChild('toggleMultiShareButton') toggleMultiShareButton: ElementRef;

  // End

  assetArray: any = [];
  assetArr: any = [];
  selectedItems: any = [];
  isCreateModalShow: boolean = false;
  isDeleteModalShow: boolean = false;
  isUploadModalClose: boolean = false;
  showAllAssetsActionButtons: boolean = false;
  displayDialogBox: boolean = false;
  showButtons: Boolean = false;
  customContextMenuAssetArray: any = [];
  allSelectedAssetsAreReady: any;
  assetsHeader: any = '';
  assetsFooter: any = '';
  myparams: any = {
    limit: 500,
    pageno: 1,
  };
  queryParams: any;
  indexPage: number = 0;
  userId: any;
  destinationFolderId: any = '';
  destinationLocation: any = '';
  sourceLocation: any = '';
  sourceFolderId: any = '';
  folderList: any;
  isCopyVisible: boolean = true;
  hasAccess: boolean = false;
  assetsCopyType: any = 0;
  userDetails: any;
  fileDetails: any;
  destinationfolderDetails: any;
  paramsSub: any;
  isSaveDisabled: boolean = true;
  assetUploadPath: any;
  creareSubfolderForm: any;
  folderPath: any;
  folderAccessList: any = [];
  parentFolderList: any = [];
  customMenu: any;
  trimImageFrom: any = '';
  uploadTagsForm: any;
  multiSharePopUpIsOpen = false;
  userRole: any;
  selectAllCheck: boolean = false;
  isAssetTag: boolean = false;
  aiArrayOfTag: Array<{
    id: String;
    tagName: String;
  }> = [];
  aiArrayTagName: Array<String> = [];
  assetsIdArray: any = [];
  //selectedAssets: any = [];
  arrayOfReTag: Array<{
    id: String;
    tagname: String;
  }> = [];
  arrayOfReTagName: Array<String> = [];
  eventsList: any = [];
  contextmenu = false;
  showSuccessMessage: any = false;
  submitted: boolean = false;
  IsmodelEventShow: boolean = false;
  uploadTag: any;
  tagArray: Array<ITagMore> = [];
  manualTagArray: Array<ITagMore> = [];
  isToolbarShown: boolean = false;
  totalText = 1000;
  remainingText: any;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  eventId: any;
  isTrimImage: boolean = false;
  imageExt: any = '';
  croppedFile: any = '';
  trimAssetId: any;
  IsVideoSave: any = 0;
  IspreviewShow: boolean = false;
  isTrimVideo: boolean = false;
  imageUrl: any = '';
  output?: any;
  trimAssetsPreview: any = '';
  selectedAssets: any = [];
  urlStr: any;
  assetTagPath: any;
  assetStatus: any;
  assetTag: any = [0, 1];
  previousFolderInfoForRedirction: any;
  //variables for create Root Folder dialog box
  folder: IFolder = {
    folderId: 0,
    folderName: '',
    parentId: 0,
    folderAccess: new Array<IFolderAccess>(),
    folderPath: '',
    folderOrigin: 0,
  };

  accessTypeList: Array<IAccessType> = [
    { id: 1, name: 'Read' },
    { id: 2, name: 'Read-Upload' },
    { id: 3, name: 'Read-Write-Upload' },
    { id: 4, name: 'Read-Write-Upload-Delete' },
  ];
  recordId: any;
  //queryParams: any;
  @ViewChild('frmFolder') templateForm: any;
  userList: Array<Iuser> = [];
  isDisabled: boolean = false;
  deletedAssignedTo: boolean = false;
  isRootCreateFolderModalShow: boolean = false;
  inputTagAssetDefaultName: string = '';
  croppedsrcUrl: any;
  isBulkAssetTag: boolean = false;
  assetNameArray: any = [];
  uploadBulkTagsForm: any;
  isActiveTab: any = 2;
  eventName: string;
  folderSharePopUpIsOpen: boolean = false;
  isViewMode: boolean = false;
  assetDescription: any;
  assetTitle: any;
  shortTagArrManual: any = [];
  longTagArrManual: any = [];
  formattedDate: any;
  handleButtonClicked: boolean = false;
  formatS3UrlPipe = new FormatS3UrlPipe();
  isuploadBulkTagsForm: boolean = false;
  constructor(
    public permissionsEngine: PermissionsEngine,
    public clientadminService: ClientadminService,
    private dashboardService: DashboardService,
    public appService: AppService,
    private messageService: MessageService,
    private folderMgmt: FolderMgmtUtill,
    private gdriveShare: GdriveShare,
    private service: NgxPhotoEditorService,
    private sanitise: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private datePipe: DatePipe,
    private loader: LoaderService
  ) {
    this.userDetails = this.appService.getUserdata();
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
      this.userRole = this.userDetails.role;
    }

    /* Add Reactive Form for cropped image validation */
    this.trimImageFrom = new FormGroup({
      imageName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        Validators.pattern('^[a-zA-Z0-9_ -]+$'),
      ]),
    });

    this.uploadTagsForm = new FormGroup({
      //'assetStatus': new FormControl(''),
      assetTag: new FormControl(''),
      assetArr: new FormControl(),
      tagArray: new FormControl('', [Validators.required]),
      eventId: new FormControl('', [Validators.required]),
      folderId: new FormControl(''),
    });

    this.uploadBulkTagsForm = new FormGroup({
      //'assetStatus': new FormControl(0),
      assetTag: new FormControl('0'),
      assetArr: new FormControl(),
      tagArray: new FormControl([]),
      eventId: new FormControl(''),
      folderId: new FormControl(''),
      assetDescription: new FormControl('', [
        clientadminService.assetInputValidator(this.totalText),
      ]),
      assetTitle: new FormControl('', [
        clientadminService.assetInputValidator(this.totalText),
      ]),
    });
    this.clientadminService.addAssetToSelectedAssets(new Map());
    this.userDetails = this.appService.getUserdata();
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
    }
    //used this service to select store all selected assets in an array ie "allSelectedAssetsAreReady"
    this.paramsSub = this.clientadminService.selectedAssetsByUser.subscribe(
      (data) => {
        if (data.size > 0) {
          this.showAllAssetsActionButtons = true;
          this.allSelectedAssetsAreReady = [...data.values()];
          this.selectedItems = this.allSelectedAssetsAreReady;
          //this.assetStatus = this.selectedItems[0].assetStatus;
          this.assetTag = this.selectedItems[0].assetTag;
        } else if (data.size === 0) {
          this.showAllAssetsActionButtons = false;
          this.allSelectedAssetsAreReady = [];
          this.selectedItems = this.allSelectedAssetsAreReady;
        } else {
          this.showAllAssetsActionButtons = false;
        }
      }
    );

    this.queryParams = {
      parentId: 0,
      userId: this.userId,
      search: '',
      limit: this.myparams.limit,
      pageno: this.myparams.pageno,
    };
    this.creareSubfolderForm = new FormGroup({
      folderPath: new FormControl(),
      folderName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_ -]+$'),
      ]),
      parentId: new FormControl(''),
      createdBy: new FormControl(),
      folderAccess: new FormControl(this.folderAccessList),
      folderOrigin: new FormControl(this.folderOrigin),
    });
    //initializing variable related to create root folder dialogbox
    this.recordId = '';
    this.recordId = this.route.snapshot.paramMap.get('id');
    if (this.folder.folderAccess.length === 0) {
      this.folder.folderAccess.push({} as IFolderAccess);
    }
    this.renderer.listen('window', 'click', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
    // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another.
    this.renderer.listen('window', 'contextmenu', (e: Event) => {
      if (
        this.toggleFolderShareButton &&
        e.target !== this.toggleFolderShareButton.nativeElement
      ) {
        this.folderSharePopUpIsOpen = false;
      }
      if (
        this.toggleMultiShareButton &&
        e.target !== this.toggleMultiShareButton.nativeElement
      ) {
        this.multiSharePopUpIsOpen = false;
      }
    });
  }

  isedit: any = false;
  editassetDescription: any;
  editassetTitle: any;
  editeventId: any;

  ngOnChanges() {
    this.customMenu = this.contextMenuSelection;
    this.menuSelection(this.customMenu);
    this.assetTagPath = this.folderDetails?.source.substring(
      0,
      this.folderDetails?.source.length - 1
    );
    this.assetUploadPath = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
    this.sourceFolderId = this.parentId;
    this.sourceLocation = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
  }

  async ngOnInit() {
    this.showButtons = false;
    this.isuploadBulkTagsForm = !this.uploadBulkTagsForm.pristine;
    this.uploadBulkTagsForm.valueChanges.subscribe(() => {
      let title = Boolean(this.uploadBulkTagsForm.get('assetTitle').value);
      let desc = Boolean(this.uploadBulkTagsForm.get('assetDescription').value);
      let eve = Boolean(this.uploadBulkTagsForm.get('eventId').value);
      if (!this.isedit) {
        //to check form is in inital state or in edit state
        //when edit button is not clicked
        this.isuploadBulkTagsForm = title || desc || eve;
      } else {
        //edit button is clicked

        this.isuploadBulkTagsForm = !(this.uploadBulkTagsForm.get('assetDescription').value ===
          this.editassetDescription &&
          this.uploadBulkTagsForm.get('assetTitle').value ===
          this.editassetTitle &&
          this.uploadBulkTagsForm.get('eventId').value === this.editeventId);
      }
    });
  }
  editclicked() {
    this.isedit = true;
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }
  menuSelection(menu: any) {
    switch (menu) {
      case 'copy':
        this.assetCopyFuction('');
        break;
      case 'move':
        this.assetMoveFuction('');
        break;
      case 'delete':
        this.opendeleteModal();
        break;
      case 'download':
        //this.onItemDownloading();
        this.downloadMultipleAssets();
        break;
      case 'tagAsset':
        this.tagAsset();
        //this.menuEmitter.emit('tagAsset');
        break;
      case 'bulkTagAsset':
        this.bulkTagAsset();
        //this.menuEmitter.emit('tagAsset');
        break;
      case 'share':
        this.menuEmitter.emit('share');
        break;
      case 'genAiTag':
        this.generateAiTagForAssets();

        break;
      // case "genTranscript":
      //   //this.generateAiscript();
      //   // this.menuEmitter.emit('genTranscript');
      //   break;
      case 'trim/crop':
        if (this.selectedItems[0].assetType === 0) {
          this.fileChangeHandler(this.appService.s3Url, this.selectedItems);
        } else {
          this.contextmenu = false;
          this.TrimVideo(this.selectedItems);
        }
        break;
      case 'clearAll':
        this.clearAllAssests();
        break;

      default:
        break;
    }
  }

  //======================= All root folder Listg =========//
  callGetAllListParent() {
    this.firstItem.children = this.folderMgmt.getLeftSideElementList();
    this.firstItem.isCollapse = false;
    //set Track
    this.folderMgmt.setTrackRecord(this.firstItem);
  }
  //============= open delete folder confirmation modal  =========//
  opendeleteModal() {
    this.isDeleteModalShow = true;
  }
  //============= close delete folder modal  =========//
  closedeleteModal() {
    this.isDeleteModalShow = false;
  }
  //=========== close event modal ==========//
  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.uploadBulkTagsForm.patchValue({
        eventId: event.id,
      });
      this.getEvents();
      this.uploadBulkTagsForm.markAsDirty();
    }
    this.IsmodelEventShow = false;
  }
  // Common Method for single and multi assets delete
  onItemDeleted() {
    if (this.selectedItems.length >= 1) {
      this.assetArray = [];
      for (let item of this.selectedItems) {
        this.assetArray.push({ assetId: item.id });
      }
      const param: any = {
        assetArr: this.assetArray,
      };
      this.clientadminService
        .callDeleteAssets('asset/deleteAsset', param)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              // this.clientadminService.notifyChange(this.childFolderId);
              if (this.assetArray.length == 1) {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success!',
                  detail: 'Asset Deleted Successfully',
                });
              } else {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success!',
                  detail: 'Assets Deleted Successfully',
                });
              }
              this.isDeleteModalShow = false;
              this.selectedItems = [];
              let params = { fileDetails: this.objectForSourceTrack };
              this.triggerMethod.emit(params);
            }
          },
          error: (err) => {
            this.selectedItems = [];
            this.clientadminService.addAssetToSelectedAssets(new Map());
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message,
            });
          },
        });
    }
  }

  //============= Open create folder modal  =========//
  openCreateModal() {
    this.isCreateModalShow = true;
    this.getParentFolderAccess(this.childFolderId);
    //Start**Duplicated this peace of code from getParentFolderAccess() method to resolved
    //bug id 161740 :   If we click on create folder options in assets page it gets hidden for a fraction of second
    // let item = this.childFolderId;
    // let folderId = item.folderId ? item.folderId : item;
    // if (folderId !== 0) {
    //   this.clientadminService.callParentFolderAccess('folder/getParentFolderAccess', { folderId: folderId })
    //     .subscribe((data: any) => {
    //       if (data.code === 200) {
    //         this.parentFolderList = data.result;
    //         this.parentFolderList.filter((newel: any) => {
    //           if (this.userId === newel.assignedTo && newel.accessType === 2) {
    //             this.hasAccess = true;
    //           }
    //         });
    //       }
    //     });
    // }//** end
  }
  //============= close create folder modal  =========//
  closeCreateModal() {
    this.isCreateModalShow = false;
    this.creareSubfolderForm.reset();
  }
  closeRootCreateModal(frmFolder: any) {
    frmFolder.resetForm();
    this.isRootCreateFolderModalShow = false;
    //setting the isDisabled property to false for the userList Array
    this.userList.forEach((item: any) => {
      item.isDisabled = false;
    });
    this.folder.folderAccess = [{} as IFolderAccess];
  }
  //============= create folder api  =========//
  createSubfolder(frmFolder: any) {
    this.handleButtonClicked = true;
    this.creareSubfolderForm.value.folderName =
      this.creareSubfolderForm.value.folderName.trim().replace(/\s+/g, ' ');
    if (this.folderOrigin === 0) {
      this.creareSubfolderForm.value.folderOrigin = this.folderOrigin;
      this.creareSubfolderForm.value.parentId = this.childFolderId;
      this.creareSubfolderForm.value.folderPath =
        this.folderDetails.id_path.substring(
          0,
          this.folderDetails.id_path.length - 1
        ) + '/';
      this.parentFolderList.filter((el: any) => {
        this.folderAccessList.push({
          assignedTo: el.assignedTo,
          roleId: el.roleId,
          accessType: el.accessType,
        });
      });
      this.creareSubfolderForm.value.folderAccess = this.folderAccessList;
    } else {
      this.creareSubfolderForm.value.folderOrigin = this.folderOrigin;
      this.creareSubfolderForm.value.parentId = this.childFolderId;
      if (this.childFolderId === 0) {
        this.folderAccessList.push({
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 4,
        });
        this.creareSubfolderForm.value.folderPath =
          this.creareSubfolderForm.value.folderPath == null ||
          this.creareSubfolderForm.value.folderPath == ''
            ? ''
            : this.creareSubfolderForm.value.folderPath;
      } else {
        this.creareSubfolderForm.value.folderPath =
          this.folderDetails.id_path.substring(
            0,
            this.folderDetails.id_path.length - 1
          ) + '/';
        this.parentFolderList.filter((el: any) => {
          this.folderAccessList.push({
            assignedTo: el.assignedTo,
            roleId: el.roleId,
            accessType: el.accessType,
          });
        });
      }

      this.creareSubfolderForm.value.folderAccess = this.folderAccessList;
    }
    if (frmFolder.valid) {
      this.clientadminService
        .callCreateQuery(this.creareSubfolderForm.value, 'folder/createFolder')
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.isCreateModalShow = false;
              this.creareSubfolderForm.reset();
              if (this.childFolderId === 0) {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  createroot: 'root',
                };
                this.triggerMethod.emit(params);
              } else {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  create: 'create',
                };
                this.triggerMethod.emit(params);
              }
              this.messageService.add({
                severity: 'success',
                summary: 'Successfull!!',
                detail: data.message,
              });
              this.handleButtonClicked = false;
            }
          },
          error: (err: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message,
            });
            this.handleButtonClicked = false;
          },
        });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }

  downloadMultipleAssets() {
    let FolderPath: any = {};
    if (this.selectedItems && this.selectedItems.length) {
      for (let item of this.selectedItems) {
        this.assetArr.push(
          // this.formatS3UrlPipe.transform(
          this.appService.s3BaseUrl + item.assetPath + '/' + item.assetName
        );
        // );
      }
      let path = this.selectedItems[0].assetPath;
      FolderPath.Ids = path.split('/');
      FolderPath.Url = this.shareFolderUrl();
    }

    this.dashboardService
      .muiltDownloadAssets(this.assetArr, FolderPath)
      .subscribe({
        next: (res: any) => {
          this.selectedItems = [];
          let params = { fileDetails: this.objectForSourceTrack };
          this.triggerMethod.emit(params);
          this.clientadminService.setOpenQueueTrigger(true);
          this.messageService.add({
            severity: 'success',
            summary: 'Success!',
            detail: 'Zip will be downloaded and will be sent to you via email.',
          });
          this.loader.setLoading(false);
        },
        error: (error: any) => {
          this.selectedItems = [];
          this.clientadminService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    this.assetArr = [];
  }

  // Common Method for single and multi Assets download
  /* onItemDownloading() {
    this.assetArray = []
    this.selectedItems.forEach((item: any) => {
      if (item.assetSize > 819200000) {
        this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: 'Cannot download Larger size file.' });
      }
    });
    // For single Download
    if (this.selectedItems.length <= 1) {
      let myparams = {
        assetId: this.selectedItems[0].id,
        assetName: this.selectedItems[0].assetPath + "/" + this.selectedItems[0].assetName,
        type : 0
      };
      this.dashboardService.downloadAssets(myparams).subscribe((res: any) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(res);
        a.href = objectUrl
        a.download = this.selectedItems[0].assetName;
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.selectedItems = [];
        let params = { fileDetails: this.objectForSourceTrack }
        this.triggerMethod.emit(params);
      }, (error: any) => {
        this.selectedItems = [];
        this.clientadminService.addAssetToSelectedAssets(new Map());
        this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: error.error.message });
      });
    }
    // For MultiSelect Download
    else {
      for (let item of this.selectedItems) {
        this.assetArr.push(this.appService.s3BaseUrl + item.assetPath + "/" + item.assetName);
      }
      this.dashboardService.muiltDownloadAssets(this.assetArr).subscribe((res: any) => {
        const a = document.createElement('a')
        const objectUrl = URL.createObjectURL(res)
        a.href = objectUrl
        a.download = "attachments.zip";
        a.click();
        URL.revokeObjectURL(objectUrl);
        this.selectedItems = [];
        let params = { fileDetails: this.objectForSourceTrack }
        this.triggerMethod.emit(params);
      }, (error: any) => {
        this.selectedItems = [];
        this.clientadminService.addAssetToSelectedAssets(new Map());
        this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: error.error.message });
      });
      this.assetArr = [];

    }

  } */
  //this function works on clicking copy to button
  assetCopyFuction(event: any) {
    this.callGetAllListParent();
    if (this.selectedItems.length === 0) return;
    this.assetsHeader = 'Copy To';
    this.displayDialogBox = true;
    this.assetsHeader = 'Copy to';
    this.assetsFooter = 'Copy';
    this.assetsCopyType = 0;
  }
  //this function works on clicking move to button
  assetMoveFuction(event: any) {
    this.callGetAllListParent();
    if (this.selectedItems.length === 0) return;
    this.assetsHeader = 'Move to';
    this.assetsFooter = 'Move';
    this.displayDialogBox = true;
    this.assetsCopyType = 1;
  }
  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))} ${
      sizes[i]
    }`;
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.displayDialogBox = false;
    this.selectedItems = [];
    let tempParentFolder: IFolderDetails = this.objectForSourceTrack;
    while (tempParentFolder.parentId != 0) {
      tempParentFolder = this.folderMgmt.getTrackRecord(
        tempParentFolder.parentId
      );
      tempParentFolder.isCollapse = true;
    }
    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.clientadminService.notifyChange(this.objectForSourceTrack);
    this.clientadminService.addAssetToSelectedAssets(new Map());
  }
  submitCopyData() {
    this.assetArray = [];
    this.selectedItems.forEach((ele: any) => {
      this.assetArray.push({
        assetId: ele.id,
        assetName: ele.assetName,
        assetType: ele.assetType,
        thumbnailAssetName: ele.thumbnailAssetName,
      });
    });
    const param: any = {
      assetArr: this.assetArray,
      sourceLocation: this.sourceLocation,
      destinationLocation: this.destinationLocation,
      sourceFolderId: this.sourceFolderId,
      destinationFolderId: this.destinationFolderId,
      copyOrMove: this.assetsCopyType,
    };
    this.clientadminService
      .callCopyOrMoveAsset('asset/copyOrMoveAssetToAnotherFolder', param)
      .subscribe({
        next: (data: any) => {
          if (data.code === 200) {
            setTimeout(() => {
              let params = { fileDetails: this.fileDetails };
              this.triggerMethod.emit(params);
              this.displayDialogBox = false;
              this.destinationFolderId = '';
              this.destinationLocation = '';
              this.sourceFolderId = '';
              this.sourceLocation = '';
              this.assetsHeader = '';
              this.assetsFooter = '';
              this.selectedItems = [];
              if (this.previousFolderInfoForRedirction.folderOrigin === 1) {
                this.router.navigate(
                  [`client-admin/core-assets/core-uploads/`],
                  { queryParams: { parentId: 0, childId: 0 } }
                );
              }
            }, 100);
            this.messageService.add({
              severity: 'success',
              summary: 'Successful!',
              detail: data.message,
            });
          }
        },
        error: (err) => {
          this.displayDialogBox = false;
          this.selectedItems = [];
          this.clientadminService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
  }
  //======================= dynamic nested listing of subfolder =========//
  async openFolder(item: IFolderDetails) {
    let queryParam;
    this.parentId = item.folderId;
    this.callFolderDetails(item);
    this.getParentFolderAccess(item);
    this.fileDetails = item;
    if (item.folderCount && parseInt(item.folderCount) >= 1) {
      this.isCopyVisible = true;
      if (item.isCollapse) {
        this.isCollapse = true;
        queryParam = {
          limit: 500,
          pageno: 1,
          parentId: item.folderId,
          userId: this.userId,
          isFolderSearch: false,
        };
        await this.folderMgmt.setFolderMgmtRightSideElementList(queryParam);
        item.children = this.folderMgmt.getRightSideElementList();
        //set Track
        this.folderMgmt.setTrackRecord(item);
      } else {
        this.isCollapse = !this.isCollapse;
        //get previous track
        this.folderMgmt.setRightSideElementList(
          this.folderMgmt.getTrackRecord(item.parentId).children
        );
      }
    } else {
      this.isCopyVisible = false;
    }
    this.previousFolderInfoForRedirction = item;
    //adding item to our data structure to tranck opened folders
    this.clientadminService.addItemToOpenedFolderDataStruct(item);
  } //refreshed
  //==============call Folder info api =========//
  callFolderDetails(item: any) {
    this.clientadminService
      .callGetQuery('', 'folder/getfolderinfo/', item.folderId)
      .subscribe((data: any) => {
        if (data && data.result.length && data.result[0]) {
          this.destinationfolderDetails = data.result[0];
          this.destinationFolderId = item.folderId;
          this.destinationLocation =
            this.destinationfolderDetails?.id_path.substring(
              0,
              this.destinationfolderDetails?.id_path.length - 1
            );
        }
      });
  }
  //=========call Folder access api =========//
  getParentFolderAccess(item: any) {
    let folderId = item.folderId ? item.folderId : item;
    if (folderId !== 0) {
      this.clientadminService
        .callParentFolderAccess('folder/getParentFolderAccess', {
          folderId: folderId,
        })
        .subscribe((data: any) => {
          if (data.code === 200) {
            this.parentFolderList = data.result;
            this.parentFolderList.filter((newel: any) => {
              if (this.userId === newel.assignedTo && newel.accessType === 2) {
                this.hasAccess = true;
              }
            });
          }
        });
    }
  }

  //method to tag asset
  tagAsset() {
    this.isAssetTag = true;
    this.aiArrayOfTag = [];
    this.aiArrayTagName = [];
    this.assetsIdArray = [];
    this.tagArray = [];
    if (this.selectedItems && this.selectedItems.length) {
      this.selectedItems.forEach((element: any) => {
        this.assetsIdArray.push(element.id);
        this.arrayOfReTag = element.tags;
        this.aiArrayOfTag = element.aiTags;
        element['manualTags'] = [];
        element['aiTagsArray'] = [];

        if (this.arrayOfReTag && this.arrayOfReTag.length) {
          this.arrayOfReTag.forEach((obj) => {
            element['manualTags'].push(obj.tagname);
            this.arrayOfReTagName.push(obj.tagname);
          });
        }
        if (this.aiArrayOfTag && this.aiArrayOfTag.length) {
          this.aiArrayOfTag.forEach((obj) => {
            element['aiTagsArray'].push(obj.tagName);
          });
        }
      });
    }

    this.getEvents();
    this.contextmenu = false;
  }

  //method to bulk tag assets
  bulkTagAsset() {
    this.isedit = true;
    this.isActiveTab = 2;
    this.isBulkAssetTag = true;
    this.assetNameArray = [];
    this.assetsIdArray = [];
    //clear ManualTagArray
    this.manualTagArray = [];
    if (this.selectedItems && this.selectedItems.length) {
      this.selectedItems.forEach((el: any) => {
        this.assetsIdArray.push(el.id);
        this.assetNameArray.push(el.assetName);
        //populate ManualTagArray
        this.manualTagArray.push({
          shortTagArrManual:
            el.manualTags != undefined
              ? el.manualTags.length > 2
                ? [...el.manualTags].sort().splice(0, 2)
                : [...el.manualTags].sort()
              : [],
          longTagArrManual:
            el.manualTags != undefined ? [...el.manualTags].sort() : [],
          showMoreManualTag: false,
        }); //manualTagArray.push()
      });
    }
    this.getEvents();
  }
  getEvents() {
    this.clientadminService.getEvents().subscribe({
      next:(data: any) => {
        if (data.code == 200) {
          this.eventsList = data.result;
        }
      },
      error:(error: any) => {
        this.selectedItems = [];
        this.clientadminService.addAssetToSelectedAssets(new Map());
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      }
  });
  }

  eventSelection(event: any) {
    this.eventName = event.eventName;
  }

  //This function is used to check valid tags while tagging any asset
  checkInput(event: any) {
    if (
      event.value.replace(/[^a-z0-9\-\s]/gi, '').length < event.value.length
    ) {
      this.uploadTagsForm.value.tagArray.pop(); // remove last entry from values
    }
  }
  //==========================Open Create Event Modal while tagging asset================================//
  openEventModal() {
    this.IsmodelEventShow = true;
  }
  //========================Close POPUP For TaggingAsset=========================//
  goBack() {
    this.isViewMode = false;
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.isBulkAssetTag = false;
    this.selectedAssets = [];
    this.clientadminService.notifyChange(this.objectForSourceTrack);
    this.clientadminService.addAssetToSelectedAssets(new Map());
  }

  //====================RESET BULK TAG FORM ====================================//
  resetBulkTagForm() {
    this.isViewMode = false;
    this.event = {};
    this.submitted = false;
    this.uploadBulkTagsForm.reset({
      assetTag: 0, // Set the assetTag control to 0 (Pending) by default
    });
    this.uploadBulkTagsForm.markAsPristine();
    this.uploadBulkTagsForm.markAsUntouched();
    this.assetTag = 0;
  }
  /* =======================Function to upload tags to assets==============================*/

  uploadTags() {
    this.submitted = true;
    this.uploadTagsForm.value.assetArr = this.assetsIdArray;
    //this.uploadTagsForm.value.assetStatus = 1;
    this.uploadTagsForm.value.folderId = this.childFolderId;
    if (this.uploadTagsForm.valid) {
      this.clientadminService.uploadTags(this.uploadTagsForm.value).subscribe({
        next:(data: any) => {
          if (data.code == 200) {
            //this.updateAssetStatus()
            this.uploadTag = data.result;
            this.submitted = false;
            this.uploadTagsForm.reset();
            this.uploadTagsForm.markAsPristine();
            this.uploadTagsForm.markAsUntouched();
            this.isAssetTag = false;
            this.selectAllCheck = false;
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethod.emit(params);
            this.selectedItems = [];
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
          }
        },
        error:(error: any) => {
          this.isAssetTag = false;
          this.selectAllCheck = false;
          this.selectedItems = [];
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        }
    });
    }
  }

  updateAssetStatus() {
    const reqbody = {
      assetStatus: this.uploadBulkTagsForm.value.assetStatus,
      assetId: this.assetsIdArray,
      // this.idarry
    };
    this.clientadminService.updateAssetStatus(reqbody).subscribe({
      next:(res: any) => {
        if (res.code === 200) {
          return;
        }
      },
      error:(error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      }
    });
  }
  valueChange(value: any) {
    this.remainingText = this.totalText - value;
  }

  // Method to clean the component — for example, to cancel background tasks.
  ngOnDestroy() {
    this.paramsSub.unsubscribe();
  }
  openAssetsModal(modalType: any) {
    this.isSaveDisabled = false;
    this.modalType = modalType;
    this.isUploadModalClose = true;
  }
  closeModalEvent(event: any) {
    this.isUploadModalClose = false;
    if (event && this.modalType === 'zip') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        zipUpload: 'zipUpload',
        folderId: event.folderId,
      };
      this.triggerMethod.emit(params);
    } else if (event && this.modalType === 'image') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        folderId: event.folderId,
        assetUploadCount: event.assetUploadCount,
      };
      this.triggerMethod.emit(params);
    } else {
      this.emptyFileList();
    }
  }

  //====================FUNCTION TO SAVE SHARE LINK HISTORY =============================
  saveShareLink() {
    let assetArr: any = [];
    let assetNames: any = [];
    let url = this.shareFolderUrl();
    //push all asset urls into assetArr array
    for (let item of this.selectedItems) {
      assetArr.push(
        this.formatS3UrlPipe.transform(
          this.appService.s3BaseUrl + item.assetPath + '/' + item.assetName
        )
      );
      assetNames.push(item.assetName);
    }
    let path = this.selectedItems[0].assetPath;
    let FolderPath = path.split('/');
    const params = {
      assetArr: assetArr,
      OriginPath: { Ids: FolderPath, Url: url },
      AssetNames: assetNames,
    };
    //call saveShareLink API to save the history of shared assets
    this.clientadminService.saveShareLink(params).subscribe({
      next:(data: any) => {
        return;
        //this.downloadHistoryList = data.result;
      },
      error:(error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      }
    });
  }
  ShareMultipleSelectedAssetsOnMail() {
    // let selectedAssetUrls = '';
    let selectedAssetUrlsArray: any = [];
    const emailAddress = '';
    const subject = '';
    this.selectedItems.forEach((asset: any) => {
      const linkToCopy = this.formatS3UrlPipe.transform(
        this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
      );
      selectedAssetUrlsArray.push(linkToCopy);
    });
    const body = `Check out this URL:\n ${selectedAssetUrlsArray?.join(
      '\n'
    )} \n`;
    const mailtoLink = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoLink;
  }
  getAssertUrlsShareMultipleSelectedAssets() {
    if (this.selectedItems == undefined) return '';
    let selectedAssetUrls = '';
    this.selectedItems.forEach((asset: any) => {
      const linkToCopy = this.formatS3UrlPipe.transform(
        this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
      );
      selectedAssetUrls =
        selectedAssetUrls +
        this.formatS3UrlPipe.transform(
          this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
        );
      //selectedAssetUrls = selectedAssetUrls + `<a href='${linkToCopy}'>` + this.appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName + `</a>`;
      selectedAssetUrls = selectedAssetUrls + '\n\n';
    });
    return selectedAssetUrls.replaceAll(' ', '%20');
  } //getAssertUrlsShareMultipleSelectedAssets

  async ShareMultipleSelectedAssetsOnGdrive() {
    let sharedAssetsId: any = [];
    this.selectedItems.forEach((asset: any) => {
      sharedAssetsId.push(asset);
    });
    if (!this.gdriveShare.isUserLoggedIn()) {
      await this.gdriveShare.getUserLoggedIn(() => {
        sharedAssetsId.forEach((_source: any) => {
          this.shareAssetOnGdrive(_source);
        });
      });
    }
  } //ShareMultipleSelectedAssetsOnGdrive

  async shareAssetOnGdrive(_source: any) {
    await this.gdriveShare.handleAuthClick(
      this.formatS3UrlPipe.transform(
        this.appService.s3BaseUrl + _source.assetPath + '/' + _source.assetName
      ),
      _source.assetName
    );
  } //shareAssetOnGdrive

  getAssetShareEncryptedLink(assetIdToEncrypt: string): string {
    let assetShareUrl = window.location.origin + '/share-asset/';
    let encryptAssetId = CryptoJS.AES.encrypt(
      JSON.stringify(assetIdToEncrypt),
      environment.secretKey
    )
      .toString()
      .replace(/\+/g, '3r21Jk')
      .replace(/\//g, 'PoMl3rd')
      .replace(/=/g, 'l3r21L');
    return assetShareUrl + encryptAssetId;
  } //getAssetShareEncryptedLink

  //

  /* image editor close modal event */
  closeTrimImage(formImage: any) {
    this.submitted = false;
    this.isTrimImage = false;
    formImage.reset();
    this.trimImageFrom.markAsPristine();
    this.trimImageFrom.markAsUntouched();
    //this.assetDetail();
    //this.getTrimmedChildAsset();
  }

  /* Method for save and upload cropped image*/
  async submitFormDataImage(formImage: any) {
    this.submitted = false;
    if (formImage.valid) {
      const fd = new FormData();
      const myFile = new File(
        [this.croppedFile],
        this.trimImageFrom.value.imageName + '.' + this.imageExt,
        {
          type: this.croppedFile.type,
        }
      );
      this.trimImageFrom.controls['imageName'].disable();
      fd.append('folderId', this.parentId);
      fd.append('referenceAssetId', this.selectedItems[0].id);
      fd.append('uploadedBy', '1');
      fd.append('assetPath', this.selectedItems[0].assetPath);
      fd.append('asset', myFile);

      await this.clientadminService.uploadTrimmedAsset(fd).subscribe({
        next:(event: any) => {
          if (event.type === HttpEventType.UploadProgress) {
          } else if (event instanceof HttpResponse) {
            this.isTrimImage = false;
            this.submitted = false;
            this.trimImageFrom.reset();
            this.trimImageFrom.markAsPristine();
            this.trimImageFrom.markAsUntouched();
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethod.emit(params);
            this.isToolbarShown = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: event.body.message,
            });
          }
        },
        error:(err: any) => {
          this.selectedItems = [];
          this.clientadminService.addAssetToSelectedAssets(new Map());
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        }
    });
    }
  }

  videoObj: any;
  /* parent method for integrate video-trim component*/
  showPreview(event: any) {
    if (!event) {
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethod.emit(params);
      this.isTrimVideo = false;
      //this.getAllAssetsList();
    } else if (event.trimAssetId && event.IsVideoSave === 1) {
      this.trimAssetId = event.trimAssetId;
      this.IsVideoSave = event.IsVideoSave;
      this.IspreviewShow = event.IspreviewShow;
      this.deleteTrimVideo(event.trimAssetId, event.IspreviewShow);
      this.isTrimVideo = false;
    } else if (event.trimAssetId && event.IsVideoSave === 0) {
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethod.emit(params);
      this.isTrimVideo = false;
      // this.getAllAssetsList();
    }
  }

  /* delete api When user can not save trim assets*/
  deleteTrimVideo(trimAssetId: any, IspreviewShow: any) {
    this.assetArray = [];
    this.assetArray.push({ assetId: trimAssetId });
    const param: any = {
      assetArr: this.assetArray,
    };
    this.clientadminService
      .callDeleteAssets('asset/deleteAsset', param)
      .subscribe({
        next: (data: any) => {
          if (data.code === 200) {
            if (!IspreviewShow) {
              let params = { fileDetails: this.objectForSourceTrack };
              this.triggerMethod.emit(params);
            }
          }
        },
        error: (err) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
  }

  //Function to trim audio / video files
  TrimVideo(item: any) {
    this.isTrimVideo = true;
  }

  /* image editor closed cropped image */
  closeImageSave() {
    this.isTrimVideo = false;
  }

  // ============ Function to generate AI tags for assets ==============//
  generatedAiTagResponse: Map<String, Array<String>>;
  generatedAiTagResponseLength: number = 0;
  generateAiTagForAssets() {
    let assetIdgenerateAiTagArr: Array<Number> = [];
    let selectedAssetArr: Array<{
      id: number;
    }> = this.selectedItems;
    this.generatedAiTagResponse = new Map<String, Array<String>>();

    //fetch all the selected Asset id
    selectedAssetArr.forEach((asset) => {
      assetIdgenerateAiTagArr.push(asset.id);
    });
    let requestBody = {
      assetArr: assetIdgenerateAiTagArr,
    };
    try {
      this.clientadminService
        .callGetAllList('elk/publishAssetsToELK/', requestBody)
        .subscribe({
          next: (data: any) => {
            let responseArr: Array<{
              tagName: string;
              id: number;
              category: number;
              tagcategory: string;
            }> = data.result;
            responseArr.forEach((obj) => {
              if (this.generatedAiTagResponse.has(obj.tagcategory)) {
                this.generatedAiTagResponse
                  .get(obj.tagcategory)
                  ?.push(obj.tagName);
              } else {
                this.generatedAiTagResponse.set(obj.tagcategory, [obj.tagName]);
              }
            });
            //Update AI Array to be shown in preview
            // if (responseArr && responseArr.length) {
            //   responseArr.forEach(obj => {
            //     //  this.aiArrayOfTag.push(obj.tagName);
            //   })
            // } else {
            //   // this.aiArrayOfTag = ["No AI Suggestion"]
            // }
            this.isToolbarShown = false;
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethod.emit(params);
            this.generatedAiTagResponseLength =
              this.generatedAiTagResponse.size;
            // get model Element By Id
            let element = document.getElementById('generateAiTagModelButton');
            //click model open Button element
            element?.click();
            this.contextmenu = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'AI tag generated successfully',
            });
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
            throw error;
          },
        });
    } catch (error) {}
  } //generateAiTagForAssets

  yearDayMonthHoursMinutes() {
    // Create a new Date object using the timestamp
    const timestamp = Date.now();
    const dateObj = new Date(timestamp);

    // Extract the individual date and time components
    const year = dateObj.getFullYear().toString().substr(-2);
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObj.getDate().toString().padStart(2, '0');
    const hours = dateObj.getHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    // Generate the desired timestamp format
    const formattedTimestamp = `${year}${day}${month}_${hours}${minutes}`;
    return formattedTimestamp;
  }

  //============Function to crop any asset ========
  fileChangeHandler(s3BaseUrl: any, assetDetail: any) {
    // Assetname is showing after image cropping.
    let assetsDummyName = assetDetail[0].assetName.slice(0, 88).split('.');
    this.inputTagAssetDefaultName =
      assetsDummyName[0] + '_' + this.yearDayMonthHoursMinutes();

    this.contextmenu = false;
    this.trimImageFrom.controls['imageName'].enable();
    if (assetDetail[0].assetType === 0) {
      this.imageUrl = this.formatS3UrlPipe.transform(
        this.appService.s3BaseUrl +
          assetDetail[0].assetPath +
          '/' +
          assetDetail[0].assetName
      );
      this.imageExt = this.imageUrl.substring(
        this.imageUrl.lastIndexOf('.') + 1
      );
    }

    var url = this.formatS3UrlPipe.transform(
      s3BaseUrl + assetDetail[0].assetPath + '/' + assetDetail[0].assetName
    );
    if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'jfif') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'jfif file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'webp') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'webp file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'tiff') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'tiff file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'ico') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'ico file format is not supported for trim/crop action!',
      });
    } else if (assetDetail[0].assetName.split('.')[1].toLowerCase() == 'svg') {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'svg file format is not supported for trim/crop action!',
      });
    } else {
      this.service
        .open(url, {
          aspectRatio: 4 / 3,
          autoCropArea: 1,
          viewMode: 1,
        })
        .subscribe((data) => {
          this.isTrimImage = true;
          this.croppedFile = data.file;
          this.croppedsrcUrl = data.base64;
          this.output = data;
        });
    }
  }

  /* image editor save cropped image */
  openImageSave(slide: any) {
    this.isTrimImage = true;
    this.trimAssetsPreview = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + slide.assetPath + '/' + slide.assetName
    );
  }

  // =========== Function to preview document =============
  cleanUrl(url: any) {
    let str = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + url.assetPath + '/' + url.assetName
    );
    this.urlStr = this.sanitise.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/gview?url=' + str
    );
    window.open(this.urlStr.changingThisBreaksApplicationSecurity, '_blank');
    //return this.sanitise.bypassSecurityTrustResourceUrl("https://docs.google.com/gview?url="+str);
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.stopPropagation();
      const element = event.target as HTMLElement;
      element.blur();
      element.focus();
    }
  }
  //methods related with create root folder dialogbox
  openRootCreateFolderModal() {
    this.folder.folderAccess = [{} as IFolderAccess];
    //If admin or super admin is creating the rootfolder then that folder will be by default assigned to itself. TaskId-> 165541
    if (this.userDetails.roleId == 1 || this.userDetails.roleId == 2) {
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 4,
        };
      }
    }
    //User Story 176108: Hardcoded Admin should be able to see all folders that are being created in the system
    //& should be able to perform read-write-upload-delete operations on all visible folders
    //added this condition to give self RWU access by default if the user is  media validator
    if (this.userDetails.roleId == 4) {
      //media validator role id  =  4
      if (this.folder.folderAccess.length === 1) {
        this.folder.folderAccess[0] = {
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 3,
        }; //accessType :  3 RWU access
      }
    }
    this.isRootCreateFolderModalShow = true;
  }
  callInitItem() {
    this.clientadminService
      .callGetQuery(
        this.queryParams,
        'folder/getFolderInfoPattern2ByFolderId/',
        this.recordId
      )
      .subscribe((data: any) => {
        this.folder = { ...this.folder, ...data.result };
        this.userList.forEach((obj) => {
          this.folder.folderAccess.forEach((fobj) => {
            if (obj.id == fobj.assignedTo) {
              obj.isDisabled = true;
            }
          });
        });
        // this.router.navigateByUrl("/client-admin/role-config")
      });
  } //callInitItem

  submitFormDataToCreateFolder(frmFolder: NgForm) {
    this.folder.folderName = this.folder.folderName.trim();
    if (frmFolder.form.valid) {
      //set folder path same as Folder Name
      this.folder.folderPath =
        this.folder.folderPath == null || this.folder.folderPath == ''
          ? ''
          : this.folder.folderPath;
      this.folder.folderId = parseInt(this.recordId);
      this.folder.folderOrigin = 0;
      const reqParams = this.folder;

      let queryCall = this.clientadminService.callCreateQuery(
        reqParams,
        'folder/createFolder'
      );
      let callFor = 'create';
      let msg = '';
      queryCall.subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            let params = {
              fileDetails: this.objectForSourceTrack,
              createroot: 'root',
            };
            this.triggerMethod.emit(params);
            this.isRootCreateFolderModalShow = false;
            frmFolder.resetForm();
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: data.message,
            });
          }
        },
        error: (err) => {
          frmFolder.resetForm();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
          this.isRootCreateFolderModalShow = false;
        },
      });
      //setting the isDisabled property to false for the userList Array
      this.userList.forEach((item: any) => {
        item.isDisabled = false;
      });
      this.folder.folderAccess = [{} as IFolderAccess];
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  } //submitFormData

  async addItem() {
    this.folder.folderAccess.push({} as IFolderAccess);
    this.userList.forEach((obj) => {
      this.folder.folderAccess.forEach((fobj) => {
        if (obj.id == fobj.assignedTo) {
          obj.isDisabled = true;
        }
      });
    });
  } //addItem

  //function to disable user when user user keyboard to chnage user
  onKeyDowns(event: FocusEvent, index: number) {
    this.userList.forEach((obj: any) => {
      if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.isTrusted == true
      ) {
        // This condition will enable the previous user in the list when Enter key is pressed
        obj.isDisabled = true;
      }
    });
    event.preventDefault(); // Prevent navigation with arrow keys
  }

  changeUser(event: any, index: number) {
    // this.userList.forEach((obj, index1) => {
    //   if (index1 == parseInt(event.target.value)) {

    //     this.folder.folderAccess[index].roleId = obj.roleId

    //   }

    // })
    this.userList.forEach((obj: any) => {
      if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 1
      ) {
        //This condition will enable the previous user in the list
        obj.isDisabled = false;
      } else if (
        obj.id == this.folder.folderAccess[index].assignedTo &&
        event.pointerId == 0
      ) {
        //This will disable the new selected user in the list
        obj.isDisabled = true;
      }
    });
  } //changeUser

  changeAccessType(event: any) {}

  getAllUserList() {
    this.clientadminService
      .callGetAll('user/getUserList', '')
      .subscribe((data: any) => {
        this.userList = data.result;
        this.userList.forEach((obj: any) => {
          // TaskId-> 165541 if user is admin or superadmin then that user will be disabled and while creating new folder , that folder will be assigned to itself with all-access by default
          obj.isDisabled = (this.userDetails.roleId === 1 ||
              this.userDetails.roleId === 2 ||
              this.userDetails.roleId === 4) &&
            obj.id === this.userDetails.userId;
        });
        this.userList = this.userList.filter((user) => user.roleId != 1);
      });
  }

  removeUserOnfolderAccessList(index: number) {
    this.userList.forEach((obj) => {
      if (obj.id == this.folder.folderAccess[index].assignedTo) {
        obj.isDisabled = false;
      }
    });
    this.folder.folderAccess.splice(index, 1);
    this.deletedAssignedTo = true;
  } //removeUserOnfolderAccessList

  clearAllAssests() {
    if (this.selectedItems && this.selectedItems.length) {
      for (let item of this.selectedItems) {
        if (item.isSelected == true) item.isSelected = false;
      }
      let params = { fileDetails: this.objectForSourceTrack };
      this.triggerMethod.emit(params);
    }
    this.multiSharePopUpIsOpen = false;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    if (this.templateForm.submitted) {
      return true;
    } else {
      return !(this.templateForm.dirty || this.deletedAssignedTo);
    }
  }

  onFolderShare() {
    this.folderSharePopUpIsOpen = !this.folderSharePopUpIsOpen;
  }

  shareFolderUrl() {
    let activeTab;
    let editUrl = '';
    if (this.folderOrigin === 0) {
      activeTab = 'all-uploads';
    } else {
      activeTab = 'core-uploads';
    }
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath
        .split('/')
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      '/client-admin/assets/' +
      activeTab +
      '?folderPath=' +
      editUrl;
    return shareLink;
  }
  //=========== download folder call=========//
  onFolderDownload() {
    if (this.objectForSourceTrack) {
      if (this.objectForSourceTrack.folderCount >= 1) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'The folder you are trying to download has subfolders',
        });
      } else if (this.objectForSourceTrack.assetConts == 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'This folder is empty! Please add some assets before downloading it.',
        });
      } else {
        let id = this.objectForSourceTrack.folderId;
        let url = this.shareFolderUrl();
        this.dashboardService.folderDownload(id, url).subscribe({
          next: (res: any) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(res);
            a.href = objectUrl;
            a.download = 'attachment.zip';
            a.click();
            URL.revokeObjectURL(objectUrl);
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
      }
    }
  }

  SaveBulkTagInfo() {
    this.assetDescription = this.uploadBulkTagsForm.value.assetDescription;
    this.assetTitle = this.uploadBulkTagsForm.value.assetTitle;
    this.tagArray = this.uploadBulkTagsForm.value.tagArray;
    this.eventsList.filter((newel: any) => {
      if (newel.id === this.uploadBulkTagsForm.value.eventId) {
        this.event.eventName = newel.eventName;
        this.event.eventDescription = newel.description;
        this.event.eventLocation = newel.eventLocation;
        this.event.eventTime = newel.eventTime;
        this.formattedDate = this.datePipe.transform(
          this.event.eventTime,
          'dd-MMM-yyyy'
        );
      }
      this.isuploadBulkTagsForm = false;
      this.isedit = false;
      this.editassetDescription = this.assetDescription;
      this.editassetTitle = this.assetTitle;
      this.editeventId = this.uploadBulkTagsForm.get('eventId').value;
    });
    if (this.tagArray && this.tagArray.length) {
      this.shortTagArrManual = this.tagArray.slice(0, 10);
      this.longTagArrManual = this.tagArray.slice(10);
    }
    this.isViewMode = false;
    this.uploadBulkTag();
    this.uploadBulkTagsForm.get('tagArray').reset([]);
    this.uploadBulkTagsForm.reset();
    this.uploadBulkTagsForm.markAsUntouched();
    this.uploadBulkTagsForm.markAsPristine();
    this.isBulkAssetTag = false;
  }
  // reset function for cancel button
  resetdata() {
    this.uploadBulkTagsForm.reset();
  }
  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.
  Checkmultipleseledcted() {
    if (this.selectedItems.length < 2) {
      if (this.multiSharePopUpIsOpen) {
        this.multiSharePopUpIsOpen = false;
      }
      return false;
    } else {
      return true;
    }
  }
  //*bugFix Bug 181244: DAM : ADMIN : Share tray is enabled when we select only one asset.

  uploadBulkTag() {
    this.uploadBulkTagsForm.value.assetArr = this.assetsIdArray;
    //this.uploadTagsForm.value.assetStatus = (this.uploadTagsForm.value.assetStatus === null || this.uploadTagsForm.value.assetStatus === undefined || this.uploadTagsForm.value.assetStatus==='') ? 0 : this.uploadTagsForm.value.assetStatus;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
      this.uploadTagsForm.value.assetTag === undefined ||
      this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadBulkTagsForm.value.folderId = this.childFolderId;

    //set Default value for form attributes
    if (this.uploadBulkTagsForm.value.tagArray == null) {
      this.uploadBulkTagsForm.value.tagArray = [];
    }

    // Bug 183199: DAM: Admin: User is not able to perform Bulk Tag operation on assets from the top options.
    //set Default value for assetDescription,assetTitle
    if (this.uploadBulkTagsForm.value.assetDescription == null) {
      this.uploadBulkTagsForm.value.assetDescription = '';
    }
    if (this.uploadBulkTagsForm.value.assetTitle == null) {
      this.uploadBulkTagsForm.value.assetTitle = '';
    }
    // if(this.uploadBulkTagsForm.value.assetStatus==null){
    //   this.uploadBulkTagsForm.value.assetStatus = 0;
    // }
    if (this.uploadBulkTagsForm.value.assetTag == null) {
      this.uploadBulkTagsForm.value.assetTag = 0;
    }

    this.clientadminService
      .uploadBulkTags(this.uploadBulkTagsForm.value)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            //this.updateAssetStatus()
            let params = { fileDetails: this.objectForSourceTrack };
            this.triggerMethod.emit(params);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
          }
        },
        error: (error: any) => {
          this.isAssetTag = false;
          this.selectAllCheck = false;
          this.selectedItems = [];
          this.isBulkAssetTag = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  handleAdd(tagToAdd: any) {
    const tagValue = tagToAdd.value.toLowerCase();
    const tagArray = this.uploadBulkTagsForm.value.tagArray;
    
    for(let i = 0; i<tagArray.length-1 ; i++){
      if(tagArray[i].toLowerCase() === tagValue){
        // Tag already exists, remove the last added tag
        this.uploadBulkTagsForm.value.tagArray.pop();
      }
    }
  }
}
