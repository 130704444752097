<p-dialog [modal]="true" [draggable]="false" [visible]="isDeleteCollectionModal" [resizable]="false"
  [breakpoints]="{'1030px': '850px', '991px': '90vw'}" [style]="{width: '600px'}" [showHeader]="false"
  (onHide)="removeClosedeleteModal()">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h5 class="font-bold"></h5>
    <button type="button" (click)="removeClosedeleteModal()" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-dialog  modal-dialog-centered">
    <div class="modal-content radius-24 p-3">
      <div class="modal-body">
        <h5 class="font-bold">{{removeData}}
        </h5>
        <span class="d-block space-32 space-h lh_space"></span>
      </div>
      <div class="modal-footer border-0 ">
        <button (click)="removeClosedeleteModal()" type="button" class="btn btn-secondary btn-min-width"
          data-bs-dismiss="modal">No</button>
        <button type="submit" class="btn d-btn-primary btn-primary btn-min-width ms-3 border-0"
          (click)="removeCollectionFromAsset()">Yes</button>
      </div>
    </div>
  </div>
</p-dialog>