import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PermissionsEngine } from 'src/app/common/permission/permission';
import { ClientadminService } from 'src/app/core/services';
import { AppService } from 'src/app/core/services';
import { Columns, IPageData } from 'src/app/core/models/models';
import { APP_CONSTANTS } from 'src/app/core/constants';

@Component({
  selector: 'app-onboard-new-user',
  templateUrl: './onboard-new-user.component.html',
  styleUrls: ['./onboard-new-user.component.scss'],
})
export class OnboardNewUserComponent {
  @Input() isOnboardModalShow: any;
  @Input() userRoleList: any = [];
  @Output() refreshList = new EventEmitter<any>();
  userForm: any;
  contactNumberPattern = this.appService.patterns.contactNumber;
  namePattern = this.appService.patterns.name;
  emailPattern = this.appService.patterns.email;
  passwordPattern = this.appService.patterns.password;
  profAgencyPattern = this.appService.patterns.profAgency;
  columns!: Array<Columns>;
  @ViewChild('actionTemplate', { static: true }) actionTemplate: any;
  @ViewChild('statusTemplate', { static: true }) statusTemplate: any;
  createRoleForm: any;
  @Input() recordId: any = {};
  queryParams: any = {
    search: '',
    limit: APP_CONSTANTS.PAGE_SIZE,
    pageno: 1,
    roleId: '',
    sortBy: '',
    orderBy: '',
  };
  cleared = false;
  @ViewChild('frmUser') templateForm: any;
  userFormData: {
    firstName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    active: true;
    password: string;
    roleId: string;
    agency: string;
  } = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    active: true,
    password: '',
    roleId: '',
    agency: '',
  };
  btnText: string;
  isVisible: Boolean = false;
  createRoleArray: any = [];
  // userRoleList: any = [];
  ischecked: any;
  originalRoleList: any;
  constructor(
    private clientadminService: ClientadminService,
    private appService: AppService,
    private messageService: MessageService,
    private route: ActivatedRoute,
    public permissionsEngine: PermissionsEngine
  ) {
    this.createRoleForm = new FormGroup({
      limit: new FormControl('10'),
      pageno: new FormControl('1'),
      require: new FormControl(''),
    });
    this.userForm = new FormGroup({
      firstName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern),
      ]),
      lastName: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(this.namePattern),
      ]),
      email: new FormControl('', [
        Validators.required,
        Validators.maxLength(40),
        Validators.pattern(this.emailPattern),
      ]),
      contactNumber: new FormControl(''),
      active: new FormControl(true),
      password: new FormControl('', [Validators.pattern(this.passwordPattern)]),
      roleId: new FormControl('defaultRole', Validators.required),
      agency: new FormControl('', [
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(this.profAgencyPattern),
      ]),
    });
    this.userForm.get('email').valueChanges.subscribe((value: string) => {
      if (this.userForm.get('email').value) {
        this.userForm
          .get('email')
          .setValue(value.toLowerCase(), { emitEvent: false });
      }
    });

    this.columns = [
      {
        key: 'id',
        sortKey: 'userId',
        label: 'User ID',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'firstName',
        sortKey: 'firstName',
        label: 'First Name',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'lastName',
        sortKey: 'lastName',
        label: 'Last Name',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'roleName',
        sortKey: 'roleName',
        label: 'Role',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'email',
        sortKey: 'email',
        label: 'Email ID',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center text-break',
      },
      {
        key: 'contactNumber',
        label: 'Mobile No.',
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'action',
        label: 'Status',
        template: this.statusTemplate,
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
      {
        key: 'action',
        label: 'Actions',
        template: this.actionTemplate,
        thClass: 'd-flex justify-content-center align-items-center',
        tdClass: 'text-center',
      },
    ];
    this.route.queryParams.subscribe((params) => {
      if (params.roleId) {
        this.queryParams.roleId = parseInt(params.roleId);
      }
    });
  }
  ngOnInit(): void {
    if (this.recordId) {
      this.btnText = 'Update';
      this.clientadminService
        .callGetQuery(this.recordId, 'user/userDetails/', this.recordId)
        .subscribe((data: any) => {
          this.userFormData = { ...this.userFormData, ...data.result };
          this.userForm.patchValue({
            email: data.result.email,
            firstName: data.result.firstName,
            lastName: data.result.lastName,
            password: data.result.password,
            agency: data.result.agency,
            contactNumber: data.result.contactNumber,
            roleId: data.result.roleId,
          });
        });
    } else {
      this.btnText = 'Save';
      this.userForm
        .get('password')
        .setValidators([
          Validators.required,
          Validators.pattern(this.passwordPattern),
        ]);
    }
  }
  resetUserFormData() {
    this.userFormData = {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      active: true,
      password: '',
      roleId: '',
      agency: '',
    };
    this.userForm.reset();
    this.userForm.patchValue({
      roleId: 'defaultRole',
    });
  }

  get first() {
    return this.userForm.get('firstName');
  }
  get last() {
    return this.userForm.get('lastName');
  }
  get email() {
    return this.userForm.get('email');
  }
  get phone() {
    return this.userForm.get('contactNumber');
  }
  get password() {
    return this.userForm.get('password');
  }
  get agency() {
    return this.userForm.get('agency');
  }
  get role() {
    return this.userForm.get('roleId');
  }

  clearInput() {
    // this.userFormData.contactNumber = '' // Clear the input value
    this.cleared = true;
    this.userForm.get('contactNumber').reset();
  }
  onInputFocus(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    input.setSelectionRange(0, input.value.length);
  }
  onInputChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    input.value = input.value.replace(/\D/g, ''); // Remove non-numeric characters
    this.userFormData.contactNumber = input.value; // Update the form control value
  }

  cancelShowModalForm() {
    this.isOnboardModalShow = false;
    this.refreshList.emit(false);
    this.resetUserFormData();
  }

  //THIS FUNCTION TAKES DATA FROM THE INPUT FIELDS IN THE FORM AND PASSES THEM TO THE SUBMIT FUNCTION.
  submitMiddleware(formData: any) {
    this.userFormData.firstName = this.userForm.get('firstName').value || '';
    this.userFormData.lastName = this.userForm.get('lastName').value || '';
    this.userFormData.email = this.userForm.get('email').value || '';
    this.userFormData.contactNumber =
      this.userForm.get('contactNumber').value || '';
    this.userFormData.password = this.userForm.get('password').value || '';
    this.userFormData.roleId = this.userForm.get('roleId').value || '';
    this.userFormData.agency = this.userForm.get('agency').value || '';
    this.submitFormData(formData);
  }

  //upsert userForm data of Onboard & Edit user popUp
  submitFormData(form: any) {
    this.userFormData.agency.trim().replace(/\s+/g, ' ');
    if (form) {
      let queryCall = this.clientadminService.callCreateQuery(
        this.userFormData,
        'auth/signUp'
      );
      let callFor = 'create';
      let msg = 'User created successfully!';
      //  this.messageService.add({severity:'success', summary:'Success!', detail:'User created successfully'});
      if (this.recordId) {
        callFor = 'edit';
        const reqParams1 = {
          id: this.recordId,
          firstName: this.userFormData.firstName,
          lastName: this.userFormData.lastName,
          email: this.userFormData.email,
          contactNumber: this.userFormData.contactNumber,
          active: this.ischecked,
          // password: this.userFormData.password,
          roleId: this.userFormData.roleId,
          agency: this.userFormData.agency,
        };
        msg = 'User updated successfully!';
        // this.messageService.add({severity:'success', summary:'Success!', detail:'User updated successfully'});
        queryCall = this.clientadminService.callUpdateQuery(
          reqParams1,
          'user/editUser'
        );
      }
      queryCall.subscribe({
        next: (data) => {
          // alert(msg);
          this.isOnboardModalShow = false;
          this.messageService.add({
            severity: 'success',
            summary: 'Success!',
            detail: msg,
          });
          this.refreshList.emit(true);
        },
        error: (err) => {
          this.isOnboardModalShow = true;
          // alert(err.error.message);
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
}
