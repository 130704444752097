import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { BnNgIdleService } from 'bn-ng-idle';
import { EventData, EventEmitterService, LoggerService } from './core/services';
import { environment } from './environment/environment';
import { APP_EVENTS } from './core/constants';
import { CommonUtils } from 'src/app/common/utils/common.utils';
declare var $: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'DAM_UI';
  userActivity: any;
  userInactive: Subject<any> = new Subject();
  sessionTime: boolean = false;
  extendFlag: boolean = false;
  timeOutFlag = true;
  checkFlag = true;
  private subscriptions: Subscription[] = [];
  overflow: boolean = true;
  hideHeader: boolean = false;
  commonUtils = new CommonUtils();
  showUploadModal: boolean;
  isGlobalUploadModalVisible: boolean = true;

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.eventEmitterService.emit({
      type: APP_EVENTS.BROWSER_BACK,
      data: null,
    });
    return;
    event.stopPropagation();
    event.preventDefault();
  }

  constructor(
    private bnIdle: BnNgIdleService,
    private logger: LoggerService,
    private router: Router,
    private eventEmitterService: EventEmitterService
  ) {
    window.addEventListener('storage', (event) => {
      if (event.key === 'logout-event' && event.newValue === 'logout') {
        this.router.navigate(['/auth/login']);
        localStorage.clear();
      }
    });
  }

  ngOnInit() {
    this.subscriptions.push(
      this.router.events.subscribe((event: any) => {
        if (event instanceof NavigationEnd) {
          this.commonUtils.scrollToAppMain();
        }
      }),
      this.eventEmitterService.subscribe((event: EventData) => {
        switch (event.type) {
          case APP_EVENTS.TOGGLE_CLIENT_ADMIN_OVERFLOW:
            this.overflow = event.data;
            break;
          case APP_EVENTS.HIDE_HEADER:
            this.hideHeader = event.data;
            break;
          case APP_EVENTS.TOGGLE_DIS_F_GLOB_UPLOAD:
            this.showUploadModal = event.data.display;
            this.isGlobalUploadModalVisible = event.data.visible;
            break;
        }
      })
    );
    this.logger.setLogging(environment.logsEnabled);
    /* Seesion TimeOut 45mins after 44 mins(2640 sec) will open dialog box 1min will display later auto logout*/
    this.bnIdle.startWatching(2640).subscribe((isTimedOut: boolean) => {
      this.setTimeout();
    });
  }

  /*Extend session for again 44mins*/
  idleWatch() {
    this.sessionTime = false;
    this.bnIdle.startWatching(2640).subscribe((isTimedOut: boolean) => {
      this.extendFlag = false;
      this.setTimeout();
    });
  }

  /*Auto logoff and redirect to login*/
  setTimeout() {
    if (this.router.url === '/auth/login' || this.router.url === '/') {
    } else {
      this.sessionTime = true;
      if (this.extendFlag === true) {
      } else {
        setTimeout(() => {
          if (this.extendFlag === false) {
            this.sessionTime = false;
            this.logout();
          }
        }, 60000);
      }
      this.checkFlag = false;
    }
  }

  extend() {
    this.sessionTime = false;
    this.extendFlag = true;
    this.idleWatch();
    this.timeOutFlag = true;
    this.checkFlag = true;
  }

  /*logout*/
  async logout() {
    localStorage.clear();
    location.reload();
    window.location.href = 'auth/login';
    this.extendFlag = false;
    this.showUploadModal = false;
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
