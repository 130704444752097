<!-- Onboard model form end -->
<p-dialog [modal]="true" [(visible)]="isOnboardModalShow" [showHeader]="false" class="onboard-user-popup"
  [resizable]="false" [draggable]="false" (onHide)="resetUserFormData()">
  <div class="onboarduser-form" id="onboarduser" tabindex="-1" aria-labelledby="photoModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
      <!-- <form #frmUser="ngForm" role="form"> -->
      <!-- THIS TEMPLATE FORM HAS BEEN CONVERTED TO REACTIVE FORM.  -->
      <form [formGroup]="userForm">
        <div class="modal-content radius-24">
          <div class="modal-header border-0 pb-0">
            <h3 class="mb-1 mt-1"> {{ btnText == 'Update' ? 'Update User' : 'Onboard New User' }}</h3>
            <button type="button" class="btn-close" aria-label="Close" (click)="cancelShowModalForm()"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="firstName" class="form-label">First Name
                        <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      </label>
                      <!-- <input type="text" class="form-control" [(ngModel)]="userFormData.firstName"
                                            name="firstName" #firstName="ngModel" id="firstName" required
                                            placeholder="Enter First Name" [pattern]="namePattern"> -->
                      <input type="text" class="form-control" formControlName="firstName"
                        placeholder="Enter First Name">
                      <div class="invalid-feedback d-block font-medium mb-2"
                        *ngIf="(userForm.controls['firstName'].hasError('maxlength')) && ( first?.touched || first?.dirty) ">
                        First Name should range from 1 to 20 characters
                      </div>
                      <div *ngIf="first?.errors && (first.dirty || first.touched)">
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="first.errors?.['required']">First
                          Name is required</div>
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="first.errors?.pattern">
                          Do not enter space,numbers and
                          special
                          characters
                        </div>
                      </div>

                      <!-- <div class="invalid-feedback d-block font-medium mb-2"
                                            *ngIf="(userFormData.firstName?.length > 20) && (firstName.dirty || firstName.touched || frmUser.submitted)">
                                            First Name should range from 1 to 20 characters</div>
                                        <div *ngIf="firstName.errors && (firstName.dirty || firstName.touched || frmUser.submitted)"
                                            class="invalid-feedback d-block font-medium mb-2">
                                            <div *ngIf="firstName.errors?.['required']">First Name is required</div>
                                            <div *ngIf="firstName.errors?.pattern">Do not enter space,numbers and
                                                special
                                                characters</div> -->
                      <!-- </div> -->
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="lastName" class="form-label">Last Name
                        <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      </label>
                      <!-- <input type="text" class="form-control" [(ngModel)]="userFormData.lastName"
                                            name="lastName" #lastName="ngModel" id="lastName" required
                                            placeholder="Enter Last Name" [pattern]="namePattern"> -->
                      <input type="text" class="form-control" formControlName="lastName" placeholder="Enter Last Name">
                      <div class="invalid-feedback d-block font-medium mb-2"
                        *ngIf="(userForm.controls['lastName'].hasError('maxlength')) && ( last?.touched || last?.dirty) ">
                        Last Name should range from 1 to 20 characters
                      </div>
                      <div *ngIf="last?.errors && (last.dirty || last.touched)">
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="last.errors?.['required']">Last
                          Name is required</div>
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="last.errors?.['pattern']">
                          Do not enter space,numbers and
                          special characters
                        </div>
                      </div>
                      <!-- <div class="invalid-feedback d-block font-medium mb-2"
                                            *ngIf="(userFormData.lastName?.length > 20) && (lastName.dirty || lastName.touched || frmUser.submitted)">
                                            Last Name should range from 1 to 20 characters</div>
                                        <div *ngIf="lastName.errors && (lastName.dirty || lastName.touched || frmUser.submitted)"
                                            class="invalid-feedback d-block font-medium mb-2">
                                            <div *ngIf="lastName.errors?.['required']">Last Name is required</div>
                                            <div *ngIf="lastName.errors?.pattern">Do not enter space,numbers and special
                                                characters</div> -->
                      <!-- </div> -->
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="emailId" class="form-label">Email ID
                        <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      </label>
                      <ng-container *ngIf="recordId; then editControlEmail; else insertControlEmail">
                      </ng-container>
                      <ng-template #insertControlEmail>
                        <!-- <input onkeypress="return event.which != 32" type="email"
                                                class="form-control" email [ngModel]="userFormData.email"
                                                (ngModelChange)="userFormData.email = $event.toLowerCase()"
                                                name="emailId" #emailId="ngModel" id="emailId" required
                                                placeholder="Enter Email ID" [pattern]="emailPattern"> -->
                        <input type="email" class="form-control" formControlName="email" placeholder="Enter Email ID"
                          [pattern]="emailPattern">
                        <div class="invalid-feedback d-block font-medium mb-2"
                          *ngIf="(userForm.controls['email'].hasError('maxlength')) && ( email?.touched || email?.dirty) ">
                          Length of email cannot be greater than 40 characters
                        </div>
                        <div *ngIf="email?.errors && (email.dirty || email.touched)">
                          <div class="invalid-feedback d-block font-medium mb-2" *ngIf="email.errors?.['required']">
                            Email ID is required</div>
                          <div class="invalid-feedback d-block font-medium mb-2" *ngIf="email.errors?.['pattern']">
                            Enter a valid Email ID
                          </div>
                        </div>
                        <!-- <div class="invalid-feedback d-block font-medium mb-2"
                                                *ngIf="(userFormData.email?.length > 40) && (emailId.dirty || emailId.touched || frmUser.submitted)">
                                                Length of email cannot be greater than 40 characters</div>
                                            <div *ngIf="emailId.errors && (emailId.dirty || emailId.touched || frmUser.submitted)"
                                                class="invalid-feedback d-block font-medium mb-2">
                                                <div *ngIf="emailId.errors?.['required']">Email ID is required</div>
                                                <div *ngIf="emailId.errors?.pattern">Enter a valid Email ID</div>
                                            </div> -->
                      </ng-template>
                      <ng-template #editControlEmail>
                        <!-- <input type="email" class="form-control" [(ngModel)]="userFormData.email"
                                                name="emailId" #emailId="ngModel" id="emailId" required
                                                placeholder="Enter Email ID" [disabled]="true"> -->
                        <input type="email" class="form-control" formControlName="email" placeholder="Enter Email ID"
                          [disabled]="true">
                      </ng-template>

                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="mobileNumber" class="form-label">Mobile Number
                        <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
                      </label>
                      <ng-container *ngIf="recordId; then editControl; else insertControl">
                      </ng-container>
                      <ng-template #insertControl>
                        <div class="input-group">
                          <!-- <input aria-describedby="button-addon2" type="text" [pattern]="contactNumberPattern" class="form-control" [(ngModel)]="userFormData.contactNumber" name="phoneNumber" #phoneNumber="ngModel" id="phoneNumber" placeholder="Enter Phone Number" (input)="onInputChanged($event)" maxlength="10"> -->
                          <input aria-describedby="button-addon2" type="text" class="form-control"
                            formControlName="contactNumber" placeholder="Enter phone number"
                            [pattern]="contactNumberPattern" autocomplete="new-password" (focus)="onInputFocus($event)"
                            (input)="onInputChanged($event)" maxlength="10">
                          <button class="btn btn-secondary rounded-end" type="button" id="button-addon2"
                            (click)="clearInput()">X</button>
                          <div class="invalid-feedback d-block font-medium mb-2"
                            *ngIf="phone.errors?.['pattern']  && (phone.dirty || phone.touched)">
                            Mobile number must be 10 digits
                          </div>
                          <!-- <div class="invalid-feedback d-block font-medium mb-2" *ngIf="phoneNumber.errors?.pattern  && (phoneNumber.dirty || phoneNumber.touched || frmUser.submitted)">Mobile number must be 10 digits.</div> -->
                        </div>
                        <!-- <input type="tel" class="form-control"
                                                [(ngModel)]="userFormData.contactNumber" name="mobileNumber"
                                                #mobileNumber="ngModel" id="mobileNumber"
                                                [pattern]="contactNumberPattern" placeholder="Enter Mobile No."> -->
                        <!-- <div *ngIf="mobileNumber.errors && (mobileNumber.dirty || mobileNumber.touched || frmUser.submitted)"
                                                class="invalid-feedback d-block font-medium mb-2">

                                                <div *ngIf="mobileNumber.errors?.pattern">Please enter a 10 digit mobile number</div>
                                            </div> -->
                      </ng-template>
                      <ng-template #editControl>
                        <div class="input-group">
                          <!-- <input aria-describedby="button-addon2" type="text" [pattern]="contactNumberPattern" class="form-control" [(ngModel)]="userFormData.contactNumber" name="phoneNumber"
                                                  #phoneNumber="ngModel" id="phoneNumber" placeholder="Enter Mobile Number" (focus)="onInputFocus($event)" (input)="onInputChanged($event)" maxlength="10" > -->
                          <input type="text" class="form-control" formControlName="contactNumber"
                            placeholder="Enter phone number" [pattern]="contactNumberPattern"
                            autocomplete="new-password" (focus)="onInputFocus($event)" (input)="onInputChanged($event)"
                            maxlength="10">
                          <button class="btn btn-secondary rounded-end" type="button" id="button-addon2"
                            (click)="clearInput()">X</button>
                          <div class="invalid-feedback d-block font-medium mb-2"
                            *ngIf="phone.errors?.['pattern']  && (phone.dirty || phone.touched)">
                            Mobile number must be 10 digits
                          </div>
                          <!-- <div class="invalid-feedback d-block font-medium mb-2" *ngIf="phoneNumber.errors?.pattern && (phoneNumber.dirty || phoneNumber.touched || frmUser.submitted)">Mobile number must be 10 digits.</div> -->
                        </div>
                        <!-- <input  type="tel" class="form-control"
                                                [(ngModel)]="userFormData.contactNumber" name="mobileNumber"
                                                #mobileNumber="ngModel" id="mobileNumber"
                                                required placeholder="Enter Mobile No." [disabled]="false" [pattern] = "contactNumberPattern" >
                                                <div *ngIf="mobileNumber.errors && (mobileNumber.dirty || mobileNumber.touched || frmUser.submitted)"
                                                class="invalid-feedback d-block font-medium mb-2" >

                                                <div *ngIf="mobileNumber.errors?.pattern">Please enter a 10 digit mobile number</div>
                                            </div> -->
                      </ng-template>
                    </div>
                  </div>

                  <!-- password input for onBoard user start -->
                  <ng-container *ngIf="recordId; then editControlPassword; else insertControlPassword">
                  </ng-container>
                  <ng-template #insertControlPassword>
                    <div class="col-lg-6">
                      <div class="mb-4 position-relative">
                        <label for="password" class="form-label">Password
                          <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                        </label>

                        <!-- <input [type]="isVisible?'text':'password'" class="form-control pe-5"
                                                [(ngModel)]="userFormData.password" name="password" #password="ngModel"
                                                id="password" required placeholder="Enter Password"
                                                [pattern]="passwordPattern"> -->
                        <input [type]="isVisible?'text':'password'" class="form-control" formControlName="password"
                          placeholder="Enter Password" [pattern]="passwordPattern" autocomplete="new-password"
                          maxlength="20">
                        <div *ngIf="password?.errors && (password.dirty || password.touched)"
                          class="invalid-feedback d-block font-medium mb-2">
                          <div *ngIf="password.errors?.['required']">
                            Password is required
                          </div>
                          <div *ngIf="password.errors?.['pattern']">Password must contain 8-20
                            characters with at least 1 uppercase letter, 1 lowercase letter, 1
                            number, 1 special character and no spaces
                          </div>
                        </div>
                        <!-- Show Password Eye -->
                        <!-- <span class="eyeicon"
                                                (click)="this.isVisible = (this.isVisible==true)?false:true;">
                                                <i [ngClass]="!isVisible?'bi bi-eye-slash-fill':'bi bi-eye-fill'"></i>
                                            </span> -->
                        <!-- <div *ngIf="password.errors && (password.dirty || password.touched || frmUser.submitted)"
                                                class="invalid-feedback d-block font-medium mb-2">
                                                <div *ngIf="password.errors?.['required']">Password is required
                                                </div>
                                                <div *ngIf="password.errors?.pattern">Password should have 8-20
                                                    characters, at least 1 uppercase letter, 1 lowercase letter, 1
                                                    number, 1 special character and no whitespaces
                                                </div>
                                            </div> -->

                      </div>
                    </div>
                  </ng-template>
                  <ng-template #editControlPassword>
                  </ng-template>
                  <!-- password input for onBoard user end -->

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="agency" class="form-label">Profession / Agency
                        <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
                      </label>
                      <!-- <input type="text" class="form-control" [(ngModel)]="userFormData.agency"
                                            name="agency" #agency="ngModel" id="agency"
                                            placeholder="Enter Profession / Agency" [pattern]="profAgencyPattern"> -->
                      <input type="text" class="form-control" formControlName="agency"
                        placeholder="Enter Profession / Agency" [pattern]="profAgencyPattern">
                      <div class="invalid-feedback d-block font-medium mb-2"
                        *ngIf="(userForm.controls['agency'].hasError('maxlength') || userForm.controls['agency'].hasError('minlength')) && ( agency?.touched || agency.dirty) ">
                        Details must range from 2 to 40 characters
                      </div>
                      <div *ngIf="agency?.errors && (agency.dirty || agency.touched)">
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="agency.errors?.['pattern']">
                          Only [@, ., -, _] are allowed as special characters.
                        </div>
                      </div>
                      <!-- <div class="invalid-feedback d-block font-medium mb-2"
                                            *ngIf="(userFormData.agency.length >=1 && userFormData.agency.length < 2 || userFormData.agency.length > 40) && (agency.dirty || agency.touched || frmUser.submitted)">
                                            Details should range from 2 to 40 characters</div>
                                        <div *ngIf="agency.errors && (agency.dirty || agency.touched || frmUser.submitted)"
                                            class="invalid-feedback d-block font-medium mb-2"> -->
                      <!-- <div *ngIf="agency.errors?.['required']">Profession/Agency details are required</div> -->
                      <!-- <div *ngIf="agency.errors?.pattern">Only [@,.-_] are allowed as special
                                                characters and do not enter leading, trailing or multiple spaces between
                                                words</div> -->
                      <!-- </div> -->
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="mb-4">
                      <label for="role" class="form-label">Role
                        <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
                      </label>

                      <ng-container *ngIf="recordId; then editControlRoleName; else insertControlRoleName">
                      </ng-container>

                      <ng-template #insertControlRoleName>
                        <ng-container *ngIf="createRoleArray && createRoleArray.length===0">
                          <!-- <select class="form-select form-control" [(ngModel)]="userFormData.roleId"
                                                    name="roleId" #roleId="ngModel" id="roleId" required>
                                                    <option value="">Please Select</option>
                                                    <option *ngFor="let role of userRoleList" [ngValue]="role.id">
                                                        {{ role.roleName }}
                                                    </option>
                                                </select> -->
                          <select class="form-select form-control" formControlName="roleId">
                            <option disabled value="defaultRole" selected>Please Select</option>
                            <option *ngFor="let role of userRoleList" [ngValue]="role.id">
                              {{ role.roleName }}
                            </option>
                          </select>
                          <div *ngIf="(role.dirty || role.touched)" class="invalid-feedback d-block font-medium mb-2">
                            <div *ngIf="role.value=='defaultRole'">Role is required</div>
                          </div>
                          <!-- <div *ngIf="roleId.errors && (roleId.dirty || roleId.touched || frmUser.submitted)"
                                                    class="invalid-feedback d-block font-medium mb-2">
                                                    <div *ngIf="roleId.errors?.['required']">Role is required</div>
                                                </div> -->
                        </ng-container>
                      </ng-template>

                      <ng-template #editControlRoleName>
                        <!-- <select class="form-select form-control" [(ngModel)]="userFormData.roleId" name="roleId"
                                                #roleId="ngModel" id="roleId" required [disabled]="false">
                                                <option *ngFor="let role of userRoleList" [ngValue]="role.id">
                                                    {{ role.roleName }}
                                                </option>
                                            </select> -->
                        <select class="form-select form-control" formControlName="roleId" id="roleId">
                          <option *ngFor="let role of userRoleList" [ngValue]="role.id">
                            {{ role.roleName }}
                          </option>
                        </select>
                      </ng-template>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col">
                      <div class="d-grid d-flex gap-3 pt-5">
                        <!-- <button type="submit" *ngIf="btnText=='Update'"
                                                class="btn btn-primary btn-min-width btn-new"
                                                [disabled]="(frmUser.invalid) || (!frmUser.dirty) || (((userFormData.firstName.length > 20) && (firstName.dirty || firstName.touched || frmUser.submitted)) || ((userFormData.lastName.length > 20) && (lastName.dirty || lastName.touched || frmUser.submitted)) || ((userFormData.agency.length >=1 && userFormData.agency.length < 2 || userFormData.agency.length > 40) && (agency.dirty || agency.touched || frmUser.submitted)))"
                                                (click)="testFunc(userForm)" data-bs-dismiss="modal"
                                                aria-label="Close">{{btnText}} </button> -->
                        <!-- <button type="submit" *ngIf="btnText!='Update'"
                                                class="btn btn-primary btn-min-width btn-new"
                                                [disabled]="(frmUser.invalid) || (((userFormData.firstName.length > 20) && (firstName.dirty || firstName.touched || frmUser.submitted)) || ((userFormData.lastName.length > 20) && (lastName.dirty || lastName.touched || frmUser.submitted)) || ((userFormData.agency.length >=1 && userFormData.agency.length < 2 || userFormData.agency.length > 40) && (agency.dirty || agency.touched || frmUser.submitted)))"
                                                (click)="testFunc(userForm)" data-bs-dismiss="modal"
                                                aria-label="Close">{{btnText}} </button> -->
                        <button type="submit" *ngIf="btnText=='Update'" class="btn btn-primary btn-min-width btn-new"
                          [disabled]="(userForm.touched || userForm.dirty || cleared) ?
                                                !!(first.invalid || last.invalid || email.invalid || phone.invalid || agency.invalid || role.value=='defaultRole') : true"
                          (click)="submitMiddleware(userForm)" data-bs-dismiss="modal" aria-label="Close">
                          {{btnText}}
                        </button>
                        <button type="submit" *ngIf="btnText!='Update'" class="btn btn-primary btn-min-width btn-new"
                          [disabled]="role.value=='defaultRole' || userForm.invalid  || !userForm.touched"
                          (click)="submitMiddleware(userForm)" data-bs-dismiss="modal" aria-label="Close">
                          {{btnText}}
                        </button>
                        <button type="button" class="btn btn-secondary btn-min-width" (click)="resetUserFormData()"  [disabled]="userForm.pristine || !userForm.dirty">
                          Reset
                        </button>
                        <!-- <button *ngIf="btnText=='Update'" class="btn btn-secondary btn-min-width" data-bs-dismiss="modal"
                                                aria-label="Close" (click)="resetUserFormData()">
                                                Cancel
                                            </button> -->
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</p-dialog>
