import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-remove-asset-collection',
  templateUrl: './remove-asset-collection.component.html',
  styleUrls: ['./remove-asset-collection.component.scss']
})
export class RemoveAssetCollectionComponent {
  @Input() removeData: any;
  @Input() isDeleteCollectionModal: any;
  @Output() closedeleteModal = new EventEmitter<any>();
  @Output() deleteCollectionFromAsset = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}
  removeClosedeleteModal() {
    this.closedeleteModal.emit(true);
  }

  removeCollectionFromAsset() {
    this.deleteCollectionFromAsset.emit(true);
  }
}
