<app-spinner></app-spinner>
<div class="app-main" id="appmain" [ngClass]="overflow ? 'overflow' :'no-overflow'" ngClass=""
  [class.without-header]="hideHeader">
  <router-outlet></router-outlet>
</div>
<div [ngClass]="sessionTime==true?'opacity-1':'opacity-0'" class="container">
  <div id="dummyModal" role="dialog" class="modal fade modal-index" [ngClass]="sessionTime==true?'show d-block':''">
    <div class="modal-dialog">
      <div class="modal-content modal-index">
        <div class="modal-header">
          <h5 class="modal-title">Session Timeout</h5>
        </div>
        <div class="modal-body">
          <p>Your session will expire in 60 seconds and all the unsaved data will be lost. Do you want to extend the
            session?</p>
        </div>
        <div class="modal-footer">
          <button type="button" data-dismiss="modal" class="btn btn-outline-dark" (click)="logout()">Log Out</button>
          <button type="button" data-dismiss="modal" class="btn btn-outline-dark" (click)="extend()">Extend
            Session</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="showUploadModal">
  <app-global-upload [hidden]="!isGlobalUploadModalVisible"></app-global-upload>
</ng-container>
