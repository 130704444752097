<div class="preview-slider-modal d-block">
  <div (click)="closeSlider()">
    <button class="btn-close" type="button" (click)="closeSlider()"></button>
  </div>
  <div class="h-100">
    <div class="upper-slider-wrapper">
      <ngx-slick-carousel class="carousel upper-slider pb-0" #slickModal="slick-carousel" [config]="slideConfig"
        (beforeChange)="beforeChange($event)">
        <div ngxSlickItem *ngFor="let asset of assetImageList; let i = index " class="slide">
          <div class="m-2 my-0">
            <div *ngIf="asset?.assetType== 0 " class="text-center">
              <img [src]="appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName | formatS3Url"
                class="mw-100" alt="Img" />
            </div>
            <div *ngIf="asset?.assetType== 1 " class="text-center">
              <video id="media{{i}}"
                [src]="appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName | formatS3Url"
                class="mw-100 mh-100 m-auto" alt="" width="" height="" controls disablePictureInPicture
                controlsList="noplaybackrate nodownload">
              </video>
            </div>
            <div *ngIf="asset?.assetType== 3 " class="text-center h-auto w-100">
              <img src="assets/images/icons/audio_icon.svg" class="mw-100 mh-100 radius-12 bg-white mb-4 w-25"
                alt="Audio">
              <audio id="media{{i}}"
                [src]="appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName | formatS3Url"
                class="w-50 m-auto" alt="" width="200px" height="" controls controlsList="noplaybackrate nodownload">
              </audio>
            </div>
            <div class="events__item-title d-flex justify-content-between px-3">
              <span class="text-truncate w-75">{{asset?.title || asset?.assetName}}</span>
              <div>
                <i (click)="searchResult(asset._id, asset?.assetPath)" class="pe-3 prev_page_dd_info_icons">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                      d="M11.7 6.5H14.3V9.1H11.7V6.5ZM13 19.5C13.715 19.5 14.3 18.915 14.3 18.2V13C14.3 12.285 13.715 11.7 13 11.7C12.285 11.7 11.7 12.285 11.7 13V18.2C11.7 18.915 12.285 19.5 13 19.5ZM13 0C5.824 0 0 5.824 0 13C0 20.176 5.824 26 13 26C20.176 26 26 20.176 26 13C26 5.824 20.176 0 13 0ZM13 23.4C7.267 23.4 2.6 18.733 2.6 13C2.6 7.267 7.267 2.6 13 2.6C18.733 2.6 23.4 7.267 23.4 13C23.4 18.733 18.733 23.4 13 23.4Z"
                      fill="white"></path>
                  </svg>
                  <span class="hover-text-details">Details</span>
                </i>
                <i (click)="onActionChange(asset)" class="pe-2 prev_page_dd_info_icons">
                  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                    <path
                      d="M12.2309 19.1852C12.3316 19.2867 12.4514 19.3673 12.5834 19.4223C12.7154 19.4773 12.857 19.5056 13 19.5056C13.1431 19.5056 13.2846 19.4773 13.4167 19.4223C13.5487 19.3673 13.6685 19.2867 13.7692 19.1852L19.1859 13.7685C19.2869 13.6675 19.367 13.5476 19.4217 13.4156C19.4763 13.2836 19.5045 13.1422 19.5045 12.9994C19.5045 12.8565 19.4763 12.7151 19.4217 12.5831C19.367 12.4511 19.2869 12.3312 19.1859 12.2302C19.0849 12.1292 18.9649 12.0491 18.833 11.9944C18.701 11.9397 18.5596 11.9116 18.4167 11.9116C18.2739 11.9116 18.1324 11.9397 18.0004 11.9944C17.8685 12.0491 17.7485 12.1292 17.6475 12.2302L14.0834 15.8052V3.24935C14.0834 2.96203 13.9692 2.68648 13.7661 2.48332C13.5629 2.28015 13.2874 2.16602 13 2.16602C12.7127 2.16602 12.4372 2.28015 12.234 2.48332C12.0308 2.68648 11.9167 2.96203 11.9167 3.24935V15.8052L8.35254 12.2302C8.14854 12.0262 7.87187 11.9116 7.58337 11.9116C7.29488 11.9116 7.0182 12.0262 6.8142 12.2302C6.61021 12.4342 6.49561 12.7109 6.49561 12.9994C6.49561 13.2878 6.61021 13.5645 6.8142 13.7685L12.2309 19.1852ZM18.4167 21.666H7.58337C7.29605 21.666 7.0205 21.7802 6.81734 21.9833C6.61417 22.1865 6.50004 22.462 6.50004 22.7494C6.50004 23.0367 6.61417 23.3122 6.81734 23.5154C7.0205 23.7185 7.29605 23.8327 7.58337 23.8327H18.4167C18.704 23.8327 18.9796 23.7185 19.1827 23.5154C19.3859 23.3122 19.5 23.0367 19.5 22.7494C19.5 22.462 19.3859 22.1865 19.1827 21.9833C18.9796 21.7802 18.704 21.666 18.4167 21.666Z"
                      fill="white"></path>
                  </svg>
                  <span class="hover-text-details">Download</span>
                </i>
              </div>
            </div>
          </div>
        </div>
      </ngx-slick-carousel>
    </div>
  </div>
</div>
