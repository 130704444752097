import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  LoadingInterceptor,
  PassTokenInterceptor,
  RefreshTokenInterceptor,
} from './interceptors';
import * as fromServices from './services';
const interceptors = [
  LoadingInterceptor,
  PassTokenInterceptor,
  RefreshTokenInterceptor,
];

@NgModule({
  imports: [CommonModule, HttpClientModule],
  declarations: [],
  providers: [...fromServices.services, ...interceptors],
  exports: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
