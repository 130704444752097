import {
  Directive,
  Attribute,
  Renderer2,
  ElementRef,
  HostListener,
  OnInit,
} from '@angular/core';

@Directive({
  selector: '[appUiImageLoader]',
})
export class UiImageLoaderDirective implements OnInit {
  constructor(
    @Attribute('loader') public loader: string,
    @Attribute('onErrorSrc') public onErrorSrc: string,
    private renderer: Renderer2,
    private el: ElementRef
  ) {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      'assets/images/common/loading.gif'
    );
  }

  @HostListener('onload')
  onLoad() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      this.el.nativeElement.src
    );
  }

  @HostListener('error') onError() {
    this.renderer.setAttribute(
      this.el.nativeElement,
      'src',
      'assets/images/common/loading.gif'
    );
  }

  ngOnInit(): void {
    this.el.nativeElement.style.backgroundColor = '#ededed';
  }
}
