import { HttpEventType, HttpResponse } from '@angular/common/http';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { AppService } from 'src/app/core/services/app.service';
import { S3UploadServices } from '../../../core/services/s3-upload-services.service';
import { ClientadminService, LoaderService } from 'src/app/core/services';
import pLimit from 'p-limit';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  originUrl: String = window.location.origin;
  sharePopUpToggle: Boolean = false;
  @Input() childFolderId: any;
  @Input() assetPathofFolder: any;
  @Input() modalType: any;
  @Input() folderOrigin: any;
  @Output() closeModalEvent = new EventEmitter<any>();
  itemData: any;

  isMultiFolder: boolean = false;
  queryParams: any;
  progressInfos: any = [];
  message: string[] = [];
  assetArr: any = [];
  popupVisible = false;
  selectedFiles: File[] = [];
  selectedItemList: any = [];
  selectedItems: any = [];
  isModalClose: boolean = true;
  IsmodelShow: any = false;
  isuploadSelect: boolean = false;
  iszipUploadSelect: boolean = false;
  isSaveButtonClicked = false;
  assetPath: any;
  totalSize: any;
  videoLength: any;
  urlStr: any;
  assetUploadCount: any = 0;
  isSaveDisabled: boolean = false;
  isModalCopy: boolean = false;
  formD: any = '';
  progressInfosDetails: number = 0;
  failure: Boolean = false;
  success: Boolean = false;
  successFileNameArray: any = [];
  fileAlreadyExistsOrError: any = [];
  selectedFileNameArray: any = [];
  isCopyVisible: boolean = true;
  run: any;
  progressList: any = [];
  zipSize: any = 0;
  successFileDetails: any = [];
  assetNameArray: any;
  uploadDone: boolean = false;
  uploadDoneWithError: boolean = false;
  assetNames: any = [];
  internalServerErrorWithFile: any = [];
  unSupportedFile: any = [];
  allow: any = true;
  @ViewChild('fileInput') fileInput: ElementRef;
  zipSizeValue: boolean = false;
  progressOfUplodingFile: any = [];
  totalAssetSize: number = 0;
  selectedFilesList: File[] = [];
  userDetails: any;
  isFileZip: boolean = false;
  isUploadClicked: boolean = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private clientadminService: ClientadminService,
    private s3UploadService: S3UploadServices,
    public appService: AppService,
    public loader: LoaderService,
    private messageService: MessageService
  ) {
    this.s3UploadService.notificationForProgress.subscribe((data) => {
      const { i, progress } = data;
      // Check if the index exists in the array
      if (this.progressOfUplodingFile[i]) {
        // Update the progress if the index exists
        this.progressOfUplodingFile[i].progress = progress;
      } else {
        // If the index doesn't exist, initialize it with 0 progress
        this.progressOfUplodingFile[i] = { i, progress: 0 };
      }
    });
  }

  ngOnInit(): void {
    this.allow = this.modalType !== 'zip';
    this.userDetails = this.appService.getUserdata();
  }

  getUploadedFiles(files: Event) {
    this.selectFiles(files);
  }
  // ========== Browse files method for select single and multiple assets for upload=====
  selectFiles(event: any) {
    const processFilesChunk = async (
      files: any[],
      startIndex: number,
      chunkSize: number
    ) => {
      const endIndex = Math.min(startIndex + chunkSize, files.length);

      const filesToProcess = files.slice(startIndex, endIndex);
      await Promise.all(filesToProcess.map(processFile));

      if (endIndex < files.length) {
        await processFilesChunk(files, endIndex, chunkSize);
      } else {
        if (this.modalType === 'zip') {
          this.callUploadZipFile();
        }
      }
    };

    const processFile = async (file: any) => {
      try {
        // Processing logic for all file types
        const ext = file.name.substring(file.name.lastIndexOf('.') + 1);
        if (!this.selectedFileNameArray.includes(file.name) && !this.successFileNameArray.includes(file.name) && (ext != "zip" || this.modalType == "zip")) {
          this.isSaveDisabled = false;
           this.isSaveButtonClicked = false;
           this.uploadDone = false;
           this.isUploadClicked = false;
           this.progressOfUplodingFile = [];
          this.totalAssetSize += file.size;
          this.selectedFiles.push(file);
          this.selectedFilesList = [...this.selectedFiles]; // Update selectedFilesList
          this.selectedFileNameArray.push(file.name);
        }
        else if (this.selectedFileNameArray.includes(file.name) || this.successFileNameArray.includes(file.name)) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'File already exist in the list',
          });
        }
        else if(ext == "zip"){
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'Please use "Zip Upload" to upload zip file.',
          });
        }
      } catch (error) {
        console.error(error);
      }
    };

    const handleFiles = () => {
      if (event && event.target && event.target.files.length > 0) {
        const files = Array.from(event.target.files);
        const chunkSize = 100; // Batch process 100 files

        processFilesChunk(files, 0, chunkSize);
      } else {
        let dragArray: any = Array.from(event);
        this.selectedFiles.push(dragArray);
        this.selectedFiles = Array.prototype.concat.apply(
          [],
          this.selectedFiles
        );
      }
    };

    if (this.childFolderId || parseInt(this.childFolderId) === 0) {
      handleFiles();
    } else {
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Folder not selected, Please select the folder.',
      });
    }
    event = null;
  }

  getVideoDuration(video: any): Promise<number> {
    return new Promise((resolve) => {
      const tempVideo = document.createElement('video');
      tempVideo.src = window.URL.createObjectURL(video);
      tempVideo.preload = 'metadata';

      tempVideo.onloadedmetadata = () => {
        resolve(tempVideo.duration || 0);
      };
    });
  }

  callUploadZipFile() {
    if (this.selectedFiles && this.selectedFiles.length) {
      this.selectedFiles.forEach((element: any, ind: any) => {
        const ext = element.name.substring(element.name.lastIndexOf('.') + 1);
        let zipExtensions = '["zip"]';
        let fileSizeOrg = this.formatBytes(element.size, 2);
        this.isFileZip = !(zipExtensions.indexOf(ext.toLowerCase()) > -1);

        let fileSpitArray = fileSizeOrg.split(' ');
        this.zipSize = parseInt(fileSpitArray[0]);


        this.zipSizeValue = fileSpitArray[1] === 'MB' && this.zipSize > 100;
        if (!(zipExtensions.indexOf(ext.toLowerCase()) > -1)) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: 'Invalid File Extension, Zip file required.',
          });
          this.selectedFiles.splice(1, ind);
          // this.progressInfos = [];
          // this.selectedFileNameArray = [];
        } else if (
          fileSpitArray &&
          fileSpitArray[1] === 'GB' &&
          this.zipSize > 5
        ) {
          if (parseInt(fileSpitArray[0]) > 1) {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: 'File size must be less than 5GB.',
            });
            this.selectedFiles = [];
            this.progressInfos = [];
            this.selectedFileNameArray = [];
          }
        }
      });
    }
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))} ${
      sizes[i]
    }`;
  }
  openAssetsModal() {
    this.isSaveDisabled = false;
    if (!this.childFolderId) {
      this.isModalClose = false;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: 'Folder not selected, Please select the folder.',
      });
    } else {
      this.isModalClose = true;
    }
  }

  //----------------------------Function to upload multiple Files----------------------
  async uploadFiles() {
    this.isUploadClicked = true;
    this.isSaveDisabled = true;
    this.isSaveButtonClicked = true;
    this.message = [];

    if (!this.selectedFilesList || this.selectedFilesList.length === 0) {
      this.message.push('File Required');
      return;
    }

    const batchSize = 25; // Set batch size to 25
    const limit = pLimit(batchSize); // Limit concurrency to 25 uploads

    if (this.modalType === 'zip') {
      this.zipUpload(this.selectedFiles);
    } else {
      let ReUploadAssets: any = [];
      let i = 0;

      const uploadPromises = [];

      // Function to upload a single file and handle errors
      const uploadFile = async (file: any, index: any) => {
        try {
          if (this.uploadDoneWithError) {
            if (this.internalServerErrorWithFile.includes(file)) {
              const indexOfFile =
                this.internalServerErrorWithFile.indexOf(file);
              if (indexOfFile !== -1) {
                this.internalServerErrorWithFile.splice(indexOfFile, 1);
                this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
              }
              return await this.upload(file, index);
            }
          } else {
            if (!this.successFileNameArray.includes(file.name)) {
              return await this.upload(file, index);
            } else {
              this.progressList[index] = 0;
            }
          }
        } catch (error) {
          ReUploadAssets.push(file); // Collect failed uploads
          this.progressList[index] = 0;
        }
        return null;
      };

      while (i < this.selectedFilesList.length) {
        const batch = this.selectedFilesList.slice(i, i + batchSize);
        const uploadBatchPromises = batch.map((file, index) => {
          return limit(() => uploadFile(file, i + index));
        });
        await Promise.all(uploadBatchPromises);
        i += batchSize;
      }

      // Move failed files to the top
      await this.fileAlreadyExistsOrError.map((fileName : any) => {
        const index = this.selectedFilesList.findIndex(file => file.name === fileName);
        if (index !== -1) {
          const [removedFile] = this.selectedFilesList.splice(index, 1);
          ReUploadAssets.push(removedFile);
        }
      });
      this.selectedFilesList = ReUploadAssets.concat(this.selectedFilesList);
      if(this.internalServerErrorWithFile.length>0){
        this.uploadDoneWithError = true;
        this.isUploadClicked = false;
      }
      else{
        this.uploadDone = true;
        this.uploadDoneWithError = false;
      }  
    }
  }

  //----------------------------Single Asset Uplaod Function---------------------------------
  async upload(file: any, i: any) {
    return new Promise(async (resolve) => {
      this.isSaveButtonClicked = true;
      this.progressList[i] = 5;
      this.formD = new FormData();
      this.formD.append('folderId', this.childFolderId);
      this.formD.append('uploadedBy', '1');
      this.formD.append('assetPath', this.assetPathofFolder);
      this.formD.append('asset', file, file.name);
      this.formD.append('folderOrigin', this.folderOrigin);
      this.formD.append('fileType', file?.type);
  
      const params = {
        fileName: file?.name,
        folderId: this.childFolderId,
        uploadedBy: '1',
        assetPath: this.assetPathofFolder,
        fileType: file?.type,
        folderOrigin: this.folderOrigin,
        assetSize: file?.size,
      };
  
      this.progressInfos.push({
        name: file.name,
        index: i,
      });
  
      if (file) {
        try {
          let event = await this.s3UploadService.uploadFile(params, file, i);
  
          if (event.code === 200) {
            if (event.result[1] != null) {
              if (!this.successFileNameArray.includes(file.name)) {
                event.result[1].mimeType = file?.type;
                this.successFileDetails.push(event.result[1]);
                this.assetNames.push(event.result[2]);
                this.removeFileFromList(this.internalServerErrorWithFile, file.name);
                this.removeFileFromList(this.fileAlreadyExistsOrError, file.name);
              }
              this.successFileNameArray.push(file.name);
              resolve((this.success = true));
            } else if (event.result[0].length > 0) {
              this.fileAlreadyExistsOrError.push(file.name);
              resolve(false);
            }
          } else if (event.error) {
            this.handleUploadError(event, file, i);
            resolve(false);
          } else {
            this.handleUnknownError(file, i);
            resolve(false);
          }
  
          this.resetProgress(i);
        } catch (error) {
          console.error('Upload failed:', error);
          this.handleUnknownError(file, i);
          resolve(false);
        }
      }
    });
  }
  
  removeFileFromList(fileList: any[], fileName: string) {
    const index = fileList.indexOf(fileName);
    if (index !== -1) {
      fileList.splice(index, 1);
    }
  }
  
  handleUploadError(event: any, file: any, i: any) {
    const { code, message } = event.error;
    this.fileAlreadyExistsOrError.push(file.name);
  
    if (code === 400) {
      if (!this.unSupportedFile.includes(file.name)) {
        this.unSupportedFile.push(file);
      }
    } else if (code === 500) {
      if (!this.internalServerErrorWithFile.includes(file)) {
        this.internalServerErrorWithFile.push(file);
      }
    } else {
      const msg = message === 'Request Entity Too Large.'
        ? 'Please do not upload over 10 files at once.'
        : 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
      this.internalServerErrorWithFile.push(file);
      this.message.push(msg);
    }
  
    this.resetProgress(i);
  }
  
  handleUnknownError(file: any, i: any) {
    const msg = 'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
    this.fileAlreadyExistsOrError.push(file.name);
    this.internalServerErrorWithFile.push(file);
    this.message.push(msg);
    this.resetProgress(i);
  }
  
  resetProgress(i: any) {
    this.progressList[i] = 0;
    this.progressOfUplodingFile[i] = 0;
  }
  

  async saveFiles() {
    const uploadLimit = 1000; // Set the upload limit to 1000
    const successFiles = JSON.stringify(this.successFileDetails);
    const unlink = JSON.stringify(this.assetNames);
    const totalAssets = this.successFileDetails.length;

    if (totalAssets > uploadLimit) {
      const firstBatchSize = uploadLimit; // Set the size of the first batch to the upload limit
      const secondBatchSize = totalAssets - uploadLimit; // Calculate the size of the second batch

      try {
        for (let i = 0; i < 2; i++) {
          let currentBatch;
          let params;
          if (i === 0) {
            // First Batch
            currentBatch = this.successFileDetails.slice(0, firstBatchSize);
            params = {
              details: JSON.stringify(currentBatch),
              unlink: unlink,
            };
          } else {
            // Second Batch
            currentBatch = this.successFileDetails.slice(firstBatchSize);
            params = {
              details: JSON.stringify(currentBatch),
              unlink: unlink,
            };
          }

          this.clientadminService.saveFiles(params).subscribe((data: any) => {
            this.handleBatchResponse(data); // Handle response and reset variables
          });
        }
      } catch (err: any) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message,
        });
      }
    } else {
      let params = {
        details: successFiles,
        unlink: unlink,
      };

      try {
        this.clientadminService.saveFiles(params).subscribe((data: any) => {
          this.handleBatchResponse(data); // Handle response and reset variables
        });
      } catch (err: any) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: err.error.message,
        });
      }
    }
  }

  // Extract the common logic to a separate function for reusability
  handleBatchResponse(data: any) {
    this.successFileNameArray = [];
    this.assetNameArray = [];
    this.uploadDone = false;
    this.isModalClose = false;
    this.fileAlreadyExistsOrError = [];
    this.selectedFiles = [];
    this.progressInfosDetails = 0;
    this.message = [];
    this.selectedFileNameArray = [];
    this.isSaveButtonClicked = false;
    this.isModalCopy = false;
    this.assetNames = [];
    let params = {
      assetUploadCount: this.successFileDetails.length,
      folderId: this.successFileDetails[0].folderId,
    };
    this.closeModalEvent.emit(params);
    this.successFileDetails = [];
    if (data.code == 200) {
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail: 'Asset(s) uploaded successfully!',
      });
    }
  }

  // async saveFiles() {
  //   let successFiles = JSON.stringify(this.successFileDetails);
  //   let unlink = JSON.stringify(this.assetNames)
  //   let params = {
  //     "details": successFiles,
  //     "unlink": unlink
  //   }

  //   try {
  //     this.clientadminService.saveFiles(params).subscribe((data: any) => {
  //       this.successFileNameArray = [];
  //       this.assetNameArray = [];
  //       this.uploadDone = false;
  //       this.isModalClose = false;

  //       this.fileAlreadyExistsOrError = [];
  //       this.selectedFiles = [];
  //       this.progressInfosDetails = 0;
  //       this.message = [];
  //       this.selectedFileNameArray = [];
  //       this.isSaveButtonClicked = false;
  //       this.isModalCopy = false;
  //       this.assetNames = [];
  //       let params = { assetUploadCount:this.successFileDetails.length, folderId : this.successFileDetails[0].folderId}
  //       this.closeModalEvent.emit(params);
  //       this.successFileDetails = [];
  //       if (data.code == 200) {
  //         this.messageService.add({ severity: 'success', summary: 'Success!', detail: 'Asset(s) uploaded successfully!' });
  //       }
  //     });
  //   } catch (err: any) {
  //     this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: err.error.message });
  //   }
  // }

  zipUpload(file: any) {
    this.isSaveButtonClicked = true;
    this.formD = new FormData();
    this.formD.append('parentFolderId', this.childFolderId);
    this.formD.append('folderPath', this.assetPathofFolder + '/');
    this.formD.append('folderOrigin', this.folderOrigin);
    if (this.selectedFiles.length) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        this.formD.append('zipFolder', this.selectedFiles[i]);
        this.progressInfos.push({
          name: this.selectedFiles[i].name,
          index: i,
        });
      }
    }
    if (file) {
      let messageDisplayed = false;
      this.subscriptions.push(
        this.clientadminService.uploadZip(this.formD).subscribe({
          next: (event: any) => {
            messageDisplayed ?  this.loader.setLoading(true) :  this.loader.setLoading(false) ;
            if (event.type === HttpEventType.UploadProgress) {
              // this.progressList[0] = Math.round(100 * event.loaded / event.total);
              this.progressOfUplodingFile[0] = {
                i: 0,
                progress: Math.round((100 * event.loaded) / event.total),
              };
              if (this.progressOfUplodingFile[0].progress == 100 && !messageDisplayed) {
                messageDisplayed = true;
                this.loader.setLoading(true);
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success!',
                  detail:
                    'Zip file is being extracted please wait for a while...',
                });
              }
            } else if (event instanceof HttpResponse) {
              this.isuploadSelect = false;
              this.iszipUploadSelect = true;
              this.isSaveButtonClicked = false;
              setTimeout(() => {
                this.isModalClose = false;
                this.selectedFileNameArray = [];
                this.progressInfosDetails = 0;
                let params = {
                  success: true,
                  folderId: event.body.result.folderId,
                };
                this.closeModalEvent.emit(params);
              }, 500);
              if(event.body.result.isAssetSkipped === true){
                this.messageService.add({
                  severity: 'warn',
                  summary: 'Warning!',
                  detail: 'Uploaded successfully! \nHowever, Some files were skipped due to unsupported formats',
                });
              }
              else{
                this.messageService.add({
                  severity: 'success',
                  summary: 'Success!',
                  detail: 'Zip uploaded and Files extracted Successfully',
                });
              }
            }
          },
          error: (err) => {
            let msg = '';
            if (err.error.message[0] == 'Request Entity Too Large.') {
              msg = 'Please do not upload over 1 files at once.';
            } else if (err.error.message !== 'Cannot upload empty ZIP file.') {
              msg =
                'Something went wrong. Could not upload the file(s), please check your internet connection and try uploading again';
            }
            this.closeModalEvent.emit(false);
            this.message.push(msg);
            this.isSaveButtonClicked = false;

            setTimeout(() => {
              this.isModalClose = false;
              this.selectedFileNameArray = [];
              this.progressInfosDetails = 0;
              //BugFix - 164890=>"Zip Upload/Upload" button is not functioning
              let tempArr = this.assetPathofFolder.split('/');
              let params = {
                success: true,
                folderId: tempArr[tempArr.length[-1]],
              };
              //  this.closeModalEvent.emit(params);

              //endBugFix
            }, 1000);
            // this.closeModalEvent.emit(true);
            if (err.status === 400) {
              this.messageService.add({
                severity: 'warn',
                summary: 'Warning!',
                detail: err.error.message,
              });
            }
          },
        })
      );
    }
  }

  

  reset() {
    this.fileInput.nativeElement.value = '';
  }
  //================= delete selected file for media uploader============
  removeImage(media: any, idx: number) {
    this.progressInfos.splice(idx, 1);
    this.selectedFiles.splice(idx, 1);
    this.selectedFilesList.splice(idx, 1);
    this.message.pop();

    if(this.selectedFilesList.length == this.successFileDetails.length){
      this.uploadDone = true;
    }

    const removedFileSize = media.size;
    this.totalAssetSize -= removedFileSize;

    const fileName = media.name;
    const ext = media.name.substring(media.name.lastIndexOf('.') + 1);

    if (ext === 'zip') {
      this.subscriptions.forEach((s) => s.unsubscribe());
      this.uploadDone = false;
      this.success = false;
      this.selectedFiles = [];
      this.progressInfosDetails = 0;
      this.successFileDetails = [];
      this.message = [];
      this.selectedFileNameArray = [];
      this.isSaveButtonClicked = false;
      this.isModalCopy = false;
      this.assetNameArray = [];
      this.assetNames = [];
    } else {
      const successFileIndex = this.successFileNameArray.indexOf(fileName);
      if (successFileIndex !== -1) {
        const assetToBeDeleted: [] = this.successFileDetails.splice(
          successFileIndex,
          1
        );
        const assetPathToBeDeleted: [] = this.assetPathofFolder;

        const params = {
          assetArr: assetToBeDeleted,
          assetPath: assetPathToBeDeleted,
        };

        this.clientadminService.clearStorage(params).subscribe(() => {});

        this.successFileNameArray.splice(successFileIndex, 1);
      }
      const fileIndex = this.fileAlreadyExistsOrError.indexOf(fileName);
      if (fileIndex !== -1) {
        this.fileAlreadyExistsOrError.splice(fileIndex, 1);
      }
      const fileIndexOfError = this.internalServerErrorWithFile.indexOf(media);
      if(fileIndexOfError !== -1){
        this.internalServerErrorWithFile.splice(fileIndexOfError,1);
      }
      this.selectedFileNameArray.splice(idx, 1);
    }
    if(this.selectedFiles.length == 0 || this.selectedFilesList.length == 0){
      this.uploadDone = false;
      this.uploadDoneWithError = false;
      this.isUploadClicked = false;
      this.success = false;
      this.selectedFiles = [];
      this.progressInfosDetails = 0;
      this.successFileDetails = [];
      this.message = [];
      this.selectedFileNameArray = [];
      this.isSaveButtonClicked = false;
      this.isModalCopy = false;
      this.assetNameArray = [];
      this.assetNames = []; 
    }
    this.reset();
  }

  //=====================delete Assets after cancel===========================================

  delteAfterCancel() {
    let params = {
      assetArr: this.successFileDetails,
      assetPath: this.assetPathofFolder,
    };
    this.clientadminService.clearStorage(params).subscribe(() => {});
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    if (this.uploadDone == true || this.successFileDetails) {
      this.delteAfterCancel();
    }
    this.uploadDone = false;
    this.success = false;
    this.closeModalEvent.emit(false);
    this.isModalClose = false;
    this.selectedFiles = [];
    this.progressInfosDetails = 0;
    this.successFileDetails = [];
    this.message = [];
    this.selectedFileNameArray = [];
    this.isSaveButtonClicked = false;
    this.isModalCopy = false;
    this.assetNameArray = [];
    this.assetNames = [];
  }
  replaceName(name: any) {
    return (
      name.split(/\.(?=[^\.]+$)/)[0].replace(/[^A-Z0-9]+/gi, '_') +
      '.' +
      name.split(/\.(?=[^\.]+$)/)[1]
    );
  }

  async singleRetry(file: any, i: any) {
    this.message.pop();
    let indexOfFile = this.internalServerErrorWithFile.indexOf(file);
    if (indexOfFile !== -1) {
      this.internalServerErrorWithFile.splice(indexOfFile, 1);
      this.fileAlreadyExistsOrError.splice(indexOfFile, 1);
    }
    await this.upload(file, i);
    if(this.internalServerErrorWithFile.length>0){
      this.uploadDoneWithError = true;
      this.isUploadClicked = false;
    }
    else{
      this.uploadDone = true;
      this.uploadDoneWithError = false;
    }  
  }

  allowMultipleSelection(){
    if(this.modalType == 'zip'){
      return false
    }
    else{
      return "allow"
    }
  }
}