import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Renderer2,
  Inject,
  HostListener,
} from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { MessageService } from 'primeng/api';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientadminService } from '../../../core/services/clientadmin.service';
import {
  FolderMgmtUtill,
  IFolderDetails,
} from '../../../common/utils/folderMgmtUtill';
import { PermissionsEngine } from '../../../common/permission/permission';
import { GdriveShare } from '../../../common/gdriveshare/gdriveshare';
import { DOCUMENT } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { environment } from 'src/app/environment/environment';
import { Chips } from 'primeng/chips';
@Component({
  selector: 'app-dynamic-nested-list',
  templateUrl: './dynamic-nested-list.component.html',
  styleUrls: ['./dynamic-nested-list.component.css'],
})
export class DynamicNestedListComponent implements OnInit {
  s3PathToImport: string = '';
  firstItem: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    children: [],
  };
  firstItemsCoreAssets: IFolderDetails = {
    firstName: 'First',
    lastName: 'Folder',
    userId: 1,
    folderCount: '',
    assignedCount: '',
    parentId: 0,
    assetCounts: '',
    untaggedAssetsCount: '',
    roleId: 1,
    folderName: '',
    folderId: 0,
    isTaged: 0,
    createdAt: '',
    createByFirstname: '',
    createByLastname: '',
    accessType: 2,
    isCollapse: false,
    children: [],
  };

  @Output() refresh = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() folderRefreshMethod = new EventEmitter();
  @Output() destinationRefresh = new EventEmitter();
  @Output() refreshCopyFolder = new EventEmitter();
  @Output() clipboardCopy = new EventEmitter();
  @Input() items: any;
  @Input() itemsFolder: any;
  @Input() layer: any;
  @Input() isOpen: any;
  @Input() parentId: any;
  @Input() id: any;
  @Input() folderContextMenuList: any;
  @Input() userType: any;
  @Input() folderOrigin: any;
  @Input() assetCountDisableFlag: boolean;
  @Input() isOpenedTrhoughModal: any = false;
  @ViewChild('contextMenuRightClick') contextMenuRightClick: ElementRef;
  @ViewChild('chips') chips: Chips;
  queryParams: any;
  myparams: any = { limit: 10, pageno: 1 };
  userId: any;
  userName: any;
  userRole: any;
  userDetails: any;
  selectedItems: any = [];
  contextmenuX = 0;
  contextmenuY = 0;
  contextmenu: boolean = false;
  isDeleteModalShow: boolean = false;
  isImportFolderByS3PathShow: boolean = false;
  renameModal: boolean = false;
  folderArray: any = [];
  isFolderTag: boolean = false;
  popupFolderCreatedAt: any;
  renameForm: any;
  uploadTagsForm: any;
  folderPath: any;
  eventsList: any;
  submitted: boolean = false;
  assetsIdArray: any = [];
  showSuccessMessage: any = false;
  totalrecords: any;
  createdBy: any;
  IsmodelEventShow: boolean = false;
  event: any = {
    eventName: '',
    eventTime: '',
    description: '',
    eventLocation: '',
  };
  totalText = 1000;
  remainingText: number;
  eventId: any;
  folderName: any;
  assetsDetails: any = [];
  totalUntagRecords: any = 0;
  assetPendingCount: any = 0;
  assetCompleteCount: any = 0;
  assetStatus: any;
  assetTag: any = [0, 1];
  tagCategory: any = 0;
  createSubfolderForm: any;
  folderAccessList: any = [];
  parentFolderList: any = [];
  isCreateModalShow: boolean = false;
  hasAccess: boolean = false;
  isUploadModalClose: boolean = false;
  modalType: any;
  childFolderId = 0;
  assetUploadPath: any;
  folderDetails: any;
  objectForSourceTrack: any;
  destinationFolderId: any = '';
  destinationLocation: any = '';
  sourceLocation: any = '';
  sourceFolderId: any = '';
  assetsCopyType: any = 0;
  assetsHeader: any = '';
  assetsFooter: any = '';
  displayDialogBox: boolean = false;
  accessFolderRights: any;
  isbulkFolderAiTag: boolean = false;
  toastMsgbulkFolderAiTag: any;
  paramSub: any;
  importFromText =
    'Import Folder ' +
    (environment.infra === 'aws' ? 'by S3 Path' : 'from GCS');

  constructor(
    private eRef: ElementRef,
    @Inject(DOCUMENT) private document: Document,
    public renderer: Renderer2,
    public permissionsEngine: PermissionsEngine,
    private gdriveShare: GdriveShare,
    private clipboard: Clipboard,
    private folderMgmt: FolderMgmtUtill,
    public appService: AppService,
    private messageService: MessageService,
    private dashboardService: DashboardService,
    private clientadminService: ClientadminService
  ) {
    (this.renameForm = new FormGroup({
      updatedName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_ -]+( [a-zA-Z0-9_ -]+)*$'),
      ]),
    })),
      /* Add validation for mandatory fields */
      (this.uploadTagsForm = new FormGroup({
        //'assetStatus': new FormControl(0),
        assetTag: new FormControl(0),
        assetArr: new FormControl(''),
        tagArray: new FormControl([]),
        eventId: new FormControl(''),
        folderId: new FormControl(''),
      })),
      (this.userDetails = this.appService.getUserdata());
    if (this.userDetails) {
      this.userId = this.userDetails.userId;
      this.userName = '';
      this.userRole = this.userDetails.role;
    }
    /* Add create Subfolder Form validation for mandatory fields */
    this.createSubfolderForm = new FormGroup({
      folderPath: new FormControl(),
      folderName: new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(100),
        Validators.pattern('^[a-zA-Z0-9_-]+( [a-zA-Z0-9_-]+)*$'),
      ]),
      parentId: new FormControl(''),
      createdBy: new FormControl(),
      folderAccess: new FormControl(this.folderAccessList),
      folderOrigin: new FormControl(this.folderOrigin),
    });

    /* This events get called by all clicks on the page */
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.target !== this.contextMenuRightClick.nativeElement) {
        this.contextmenu = false;
        document
          .getElementsByTagName('body')[0]
          .classList.remove('context-menu-body-opened');
      }
    });
  }
  async ngOnInit() {
    this.assetUploadPath = this.folderDetails?.id_path.substring(
      0,
      this.folderDetails?.id_path.length - 1
    );
    this.paramSub = this.clientadminService.transferApiDataObservar$.subscribe(
      async (data) => {
        if (
          data &&
          data.data &&
          data.data.result &&
          data.data.result.length > 0 &&
          data.flag == true
        ) {
          await this.callFolderDetails(data.data, data.id);
          data.flag = false;
        }
      }
    );
  }
  // ================== isCollapse folder method for open parent folder ( dynamic list of all uploads/core uploads)
  openFolder(item: any) {
    this.items.children.forEach((item1: any) => {
      item1.folderId === item.folderId
        ? (item.isCollapse = !item.isCollapse)
        : (item1.isCollapse = false);
    });
    this.refresh.emit(item);
  }

  // ================== isCollapse sub-folder method for open sub-folder folder ( dynamic list of all uploads/core uploads)
  refreshed(event: any) {
    if (event) {
      this.refresh.emit(event);
    }
  }
  // ============= open sub folder click for right click copy/ move folder ===== //
  openCopyfolder(event: any) {
    if (event) {
      this.refreshCopyFolder.emit(event);
    }
  }
  // ============= open folder click for right click copy/ move folder ===== //
  openFolderRefreshMethod(event: any) {
    if (event) {
      this.folderRefreshMethod.emit(event);
    }
  }

  //get the window position and context menu position
  getPosition(e: any) {
    document
      .getElementsByTagName('body')[0]
      .classList.add('context-menu-body-opened');
    let windowHeight;
    let menuHeight;
    let menuHieghtDifference;

    menuHeight = this?.contextMenuRightClick.nativeElement.offsetHeight;
    windowHeight = window.innerHeight;

    menuHieghtDifference = windowHeight - e.clientY;
    this.contextmenuX = e.pageX;
    if (this.accessFolderRights == 4) {
      if (windowHeight > 575 && windowHeight < 747) {
        // if (menuHieghtDifference > 300 && menuHieghtDifference < 400) {
        //   this.contextmenuY = e.clientY - 300;
        // } else if (menuHieghtDifference > 150 && menuHieghtDifference < 299) {
        //   this.contextmenuY = e.clientY - 390;
        // } else if (menuHieghtDifference > 0 && menuHieghtDifference < 148) {
        //   this.contextmenuY = e.clientY - 510;
        // } else {
        //   this.contextmenuY = e.clientY - 200;
        // }
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight - 400
            ? windowHeight - 542
            : e.clientY;
      } else {
        // if (menuHieghtDifference > menuHeight) {
        //   this.contextmenuY = e.clientY - 150;
        // } else if (menuHieghtDifference > 401 && menuHieghtDifference < 591) {
        //   this.contextmenuY = e.clientY - 250;
        // } else if (menuHieghtDifference > 200 && menuHieghtDifference < 400) {
        //   this.contextmenuY = e.clientY - menuHieghtDifference - 230;
        // } else if (menuHieghtDifference > 150 && menuHieghtDifference < 125) {
        //   this.contextmenuY = e.clientY - menuHieghtDifference - 400;
        // } else {
        //   this.contextmenuY = e.clientY - menuHieghtDifference - 500;
        // }
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight - 600
            ? windowHeight - menuHeight
            : e.clientY;
      }
    } else if (this.accessFolderRights == 3) {
      // if (menuHieghtDifference > menuHeight) {
      //   this.contextmenuY = e.clientY - 200;
      // } else if (menuHieghtDifference > 351 && menuHieghtDifference < 591) {
      //   this.contextmenuY = e.clientY - 250;
      // } else if (menuHieghtDifference > 200 && menuHieghtDifference < 350) {
      //   this.contextmenuY = e.clientY - 350;
      // } else {
      //   this.contextmenuY = e.clientY - menuHieghtDifference - 350;
      // }
      if (windowHeight > 575 && windowHeight < 747) {
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight - 300
            ? windowHeight - 414
            : e.clientY;
      } else {
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight
            ? windowHeight - menuHeight
            : e.clientY;
      }
    } else if (this.accessFolderRights == 2) {
      // if (menuHieghtDifference > menuHeight) {
      //   this.contextmenuY = e.clientY-  150;
      // } else if (menuHieghtDifference > 351 && menuHieghtDifference < 591) {
      //   this.contextmenuY = e.clientY - 250;
      // } else if (menuHieghtDifference > 200 && menuHieghtDifference < 350) {
      //   this.contextmenuY = e.clientY - 250;
      // } else {
      //   this.contextmenuY = e.clientY - menuHieghtDifference - 250;
      // }
      if (windowHeight > 575 && windowHeight < 747) {
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight
            ? windowHeight - 325
            : e.clientY - 5;
      } else {
        this.contextmenuY =
          e.clientY + menuHeight > windowHeight
            ? windowHeight - menuHeight
            : e.clientY;
      }
    } else {
      this.contextmenuY =
        e.clientY + menuHeight > windowHeight
          ? windowHeight - 101
          : e.clientY - 20;
    }
  }
  // ============= right folder click when asset is selected ===== //
  onrightClick(event: any, asset: any) {
    if (asset.isCollapse) {
      this.folderMgmt.setTrackRecord(asset);
      this.objectForSourceTrack = asset;
      this.selectedItems = [];
      this.selectedItems.push(asset);
      this.childFolderId = this.selectedItems[0].folderId;
      // this.contextmenuX = event.pageX;
      // this.contextmenuY = event.pageY;

      this.getPosition(event);

      this.contextmenu = true;
      this.callFolderInfo();
    }
  }

  //=========== disable click out of context menu click=========//
  clickedOutsideMenu() {
    this.disableContextMenu();
  }

  //=========== disable context menu click=========//
  disableContextMenu() {
    this.contextmenu = false;
  }
  //=========== perfrom action based on context menu click=========//
  handleMenuSelection(menuselection: any) {
    switch (menuselection) {
      case 'createFolder':
        this.openCreateModal();
        break;
      case 'uploadAsset':
        this.openAssetsModal('image');
        break;
      case 'zipUpload':
        this.openAssetsModal('zip');
        break;
      case 'copyFolder':
        this.assetCopyFuction('');
        break;
      case 'moveFolder':
        this.assetMoveFuction('');
        break;
      case 'delete':
        this.opendeleteModal();
        //this.callFolderInfo()
        break;
      case 'download':
        this.onFolderDownload();
        break;
      case 'tagFolder':
        this.callInitItem();
        break;
      case 'rename':
        this.onRename();
        break;
      case 'importFolderByS3Path':
        this.openimportFolderByS3PathModal();
        break;
      case 'downloadFolderForBackup':
        this.downloadFolderForBackup();
        break;
      case 'share':
        this.shareFolderUrl();
        break;
      case 'whatsapp':
        this.whatsappShare();
        break;
      case 'mail':
        this.emailShare();
        break;
      case 'copyLink':
        this.clipboard.copy(this.shareFolderUrl());
        this.messageService.add({
          severity: 'success',
          summary: 'Success!',
          detail: 'Folder Link Copied!',
        });
        break;
      case 'gDrive':
        this.gdriveShareUploads();
        break;
      case 'bulkAiTag':
        this.callBulkAiTag();
        break;
      default:
        this.contextmenu = false;
        break;
    }
  }
  //=========== open rename modal =========//
  onRename() {
    this.contextmenu = false;
    this.renameModal = true;
    this.renameForm.patchValue({
      updatedName: this.folderName,
    });
  }

  //============= Get URL of the selected folder ================//
  shareFolderUrl() {
    let activeTab;
    let editUrl = '';
    if (this.folderOrigin === 0) {
      activeTab = 'all-uploads';
    } else {
      activeTab = 'core-uploads';
    }
    if (this.assetUploadPath !== undefined) {
      editUrl = this.assetUploadPath
        .split('/')
        .filter((segment: any) => segment !== '')
        .join(',');
    }
    let shareLink =
      window.location.origin +
      '/client-admin/assets/' +
      activeTab +
      '?folderPath=' +
      editUrl;
    return shareLink;
  }

  //=========== download folder call=========//
  onFolderDownload() {
    this.contextmenu = false;
    this.folderArray = [];
    if (this.selectedItems && this.selectedItems.length) {
      if (this.selectedItems[0].assetConts == 0) {
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail:
            'This folder is empty! Please add some assets before downloading it.',
        });
      } else {
        let id = this.selectedItems[0].folderId;
        let url = this.shareFolderUrl();
        this.clientadminService.setOpenQueueTrigger(true);
        this.dashboardService.folderDownload(id, url).subscribe({
          next: (res: any) => {
            // const a = document.createElement('a')
            // const objectUrl = URL.createObjectURL(res)
            // a.href = objectUrl
            // a.download = "attachment.zip";
            // a.click();
            // URL.revokeObjectURL(objectUrl);
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail:
                'Zip will be downloaded and will be sent to you via email.',
            });
            //this.clientadminService.setOpenQueueTrigger(true);
          },
          error: (error: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: error.error.message,
            });
          },
        });
      }
    }
  }
  //=========== Tag folder call=========//
  onFolderTag() {
    this.contextmenu = false;
    if (this.selectedItems && this.selectedItems.length) {
      if (this.totalUntagRecords === 0) {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: 'There are no assets in this folder to tag!',
        });
        //Change in Detail message and change severity of messageservice
      } else {
        this.isFolderTag = true;
        this.getEvents();
        this.popupFolderCreatedAt = this.selectedItems[0].createdAt;
      }
    }
  }
  //=========== rename folder call=========//
  updateName() {
    let uName = this.renameForm.value.updatedName.trim();
    let id = this.selectedItems[0].folderId;
    let parentId = this.selectedItems[0].parentId;

    let params = {
      id,
      uName,
      parentId,
    };

    this.clientadminService.callRenameFolder(params).subscribe({
      next: async (res: any) => {
        if (res.code === 200) {
          let objectForSourceTrack = this.objectForSourceTrack;
          objectForSourceTrack.folderName = uName;
          objectForSourceTrack.sourceLocation = this.sourceLocation;
          let params = { fileDetails: objectForSourceTrack, rename: 'rename' };
          this.folderRefreshMethod.emit(params);

          // let queryParam = { folderOrigin: this.selectedItems[0].folderOrigin, limit: 500, pageno: 1, parentId: this.selectedItems[0].parentId, userId: this.userId };
          // await this.folderMgmt.setFolderMgmtRightSideElementList(queryParam).then(obj => {
          //   this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).isCollapse = true;
          //   let countIncrement = this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).folderCount;
          //   countIncrement = countIncrement + 1;
          //   this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).folderCount = countIncrement;
          //   this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).children = this.folderMgmt.getRightSideElementList();
          // });
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Folder renamed successfully',
          });
          this.clientadminService.addAssetToSelectedAssets(new Map());
        }
        this.renameModal = false;
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
    this.renameForm.reset();
    this.renameModal = false;
  }
  //============= open delete folder confirmation modal  =========//
  opendeleteModal() {
    this.isDeleteModalShow = true;
    this.contextmenu = false;
  }
  //============= close delete folder modal  =========//
  closedeleteModal() {
    this.isDeleteModalShow = false;
    this.contextmenu = false;
  }
  //=============  folder delete method =========//
  async onDeleteFolder() {
    this.isDeleteModalShow = true;
    this.contextmenu = false;
    this.folderArray = [];
    this.selectedItems.forEach((ele: any) => {
      this.folderArray.push({
        folderId: ele.folderId,
        folderName: ele.folderName,
        folderPath: this.folderPath,
      });
    });
    const param: any = {
      folderArr: this.folderArray,
    };
    this.clientadminService
      .callDeleteAssets('folder/deleteFolder', param)
      .subscribe({
        next: async (data: any) => {
          if (data.code === 200) {
            if (this.selectedItems[0].parentId === 0) {
              let queryParam = {
                folderOrigin: this.selectedItems[0].folderOrigin,
                limit: 500,
                pageno: 1,
                parentId: this.selectedItems[0].parentId,
                userId: this.userId,
                isFolderSearch: false,
              };
              await this.folderMgmt
                .setFolderMgmtLeftSideElementList(queryParam)
                .then((obj) => {
                  this.folderMgmt.getTrackRecord(
                    this.selectedItems[0].parentId
                  ).children = this.folderMgmt.getLeftSideElementList();
                });
            } else {
              // let queryParam = { folderOrigin: this.selectedItems[0].folderOrigin, limit: 500, pageno: 1, parentId: this.selectedItems[0].parentId, userId: this.userId };
              // await this.folderMgmt.setFolderMgmtRightSideElementList(queryParam).then(obj => {
              //   if (parseInt(this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).folderCount) > 1) {
              //     this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).isCollapse = true;
              //   }
              //   else {
              //     this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).isCollapse = false;
              //   }
              //   this.folderMgmt.getTrackRecord(this.selectedItems[0].parentId).children = this.folderMgmt.getRightSideElementList();
              // });
              // this.clientadminService.notifyChange(this.selectedItems[0].parentId)
            }

            this.isDeleteModalShow = false;
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Folder deleted successfully',
            });
            let params = {
              fileDetails: this.objectForSourceTrack,
              delete: 'delete',
            };
            this.folderRefreshMethod.emit(params);
            // this.clientadminService.notifyChange(param)
          }
        },
        error: (err: any) => {
          this.isDeleteModalShow = false;
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: err.error.message,
          });
        },
      });
  }

  //============= Import Folder By S3 Path modal work start =========//
  openimportFolderByS3PathModal() {
    this.isImportFolderByS3PathShow = true;
    this.contextmenu = false;
  }
  closeimportFolderByS3PathModal() {
    this.isImportFolderByS3PathShow = false;
    this.contextmenu = false;
    //clean path
    this.s3PathToImport = '';
  }
  //hit api and get import folder form S3
  importFolderByS3Path() {
    let requestBody = {
      s3Key: '',
      folderId: Number,
    };
    requestBody.s3Key = this.s3PathToImport;
    requestBody.folderId = this.selectedItems[0].folderId;
    //hit api
    this.appService
      .post('folder/importFolderFromS3', requestBody, {})
      .subscribe({
        next: (data: any) => {
          this.closeimportFolderByS3PathModal();
          let params = {
            fileDetails: this.objectForSourceTrack,
            importFolderFromS3: 'importFolderFromS3',
          };
          this.folderRefreshMethod.emit(params);
          this.messageService.add({
            severity: 'success',
            summary: 'Folder Path is Accepted',
            detail: data.message,
          });
        },
        error: (error: any) => {
          this.closeimportFolderByS3PathModal();
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }
  //============= Import Folder By S3 Path modal work end =========//

  //============= Download Folder For Backup work start =========//
  folderbackup: any;
  async downloadFolderForBackup() {
    //hit api
    this.appService
      .post(
        'folder/folderBackup',
        { folderId: this.selectedItems[0].folderId },
        {}
      )
      .subscribe({
        next: (data: any) => {
          this.folderbackup = data.result.folderPath;
          // this.clipboardCopy.emit(this.folderbackup);
          navigator.clipboard.writeText(this.folderbackup);
          this.messageService.add({
            severity: 'success',
            summary: 'Success',
            detail: 'Folder path copied to clipboard',
          });
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
    this.contextmenu = false;
  }
  //============= Download Folder For Backup work end =========//

  //This function is used to check valid tags while tagging any asset
  checkInput(event: any) {
    if (
      event.value.replace(/[^a-z0-9\-\s]/gi, '').length < event.value.length
    ) {
      this.uploadTagsForm.value.tagArray.pop(); // remove last entry from values
    }
  }

  /* =======================Getting event list into dropdown==============================*/

  getEvents() {
    this.clientadminService.getEvents().subscribe({
      next: (data: any) => {
        if (data.code == 200) {
          this.eventsList = data.result;
          this.eventsList.filter((item: any) => {
            item['eventId'] = item.id;
          });
        }
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
  }

  /* =======================Function to update assetStatus of all untagged assets==============================*/
  updateAssetStatus() {
    const reqbody = {
      assetStatus: this.uploadTagsForm.value.assetStatus == true ? 1 : 0,
      assetId: this.assetsIdArray,
      // this.idarry
    };
    this.clientadminService.updateAssetStatus(reqbody).subscribe({
      next: (res: any) => {
        if (res.code === 200) {
          return;
        }
      },
      error: (error: any) => {
        this.messageService.add({
          severity: 'warn',
          summary: 'Warning!',
          detail: error.error.message,
        });
      },
    });
  }

  /* =======================Function to upload tags to assets==============================*/

  uploadTags() {
    this.submitted = true;
    this.uploadTagsForm.value.assetArr = this.assetsIdArray;
    //this.uploadTagsForm.value.assetStatus = (this.uploadTagsForm.value.assetStatus === null || this.uploadTagsForm.value.assetStatus === undefined || this.uploadTagsForm.value.assetStatus === '') ? 0 : this.uploadTagsForm.value.assetStatus;
    this.uploadTagsForm.value.assetTag =
      this.uploadTagsForm.value.assetTag === null ||
      this.uploadTagsForm.value.assetTag === undefined ||
      this.uploadTagsForm.value.assetTag === ''
        ? 0
        : this.uploadTagsForm.value.assetTag;
    this.uploadTagsForm.value.folderId = this.selectedItems[0].folderId;
    //set Default value for form attributes
    if (this.uploadTagsForm.value.tagArray == null) {
      this.uploadTagsForm.value.tagArray = [];
    }
    if (this.uploadTagsForm.value.assetTag == null) {
      this.uploadTagsForm.value.assetTag = 0;
    }
    this.clientadminService
      .uploadBulkTags(this.uploadTagsForm.value)
      .subscribe({
        next: async (data: any) => {
          if (data.code == 200) {
            //this.updateAssetStatus()
            this.submitted = false;
            this.uploadTagsForm.reset();
            this.uploadTagsForm.markAsPristine();
            this.uploadTagsForm.markAsUntouched();
            this.messageService.add({
              severity: 'success',
              summary: 'Success!',
              detail: 'Tags & Metadata updated successfully!',
            });
            this.objectForSourceTrack.isCollapse = false;
            let params = {
              fileDetails: this.objectForSourceTrack,
              tagFolder: 'tagFolder',
            };
            this.folderRefreshMethod.emit(params);
            this.isFolderTag = false;
            this.document.body.classList.remove('p-overflow-hidden-1');
          }
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: error.error.message,
          });
        },
      });
  }

  cancel() {
    this.renameForm.reset();
    this.renameModal = false;
    this.contextmenu = false;
    this.selectedItems = [];
  }
  openEventModal() {
    this.IsmodelEventShow = true;
  }

  goBack() {
    this.submitted = false;
    this.isFolderTag = false;
    //let assetStatus = this.uploadTagsForm.value.assetStatus;
    let assetTag = this.uploadTagsForm.value.assetTag;
    this.uploadTagsForm.reset();
    this.uploadTagsForm.markAsPristine();
    this.uploadTagsForm.markAsUntouched();
    this.uploadTagsForm.controls['assetTag'].setValue(assetTag);
    this.selectedItems = [];
    this.contextmenu = false;
    this.IsmodelEventShow = false;
    this.showSuccessMessage = false;
    if (this.isFolderTag == false) {
      this.document.body.classList.remove('p-overflow-hidden-1');
    } else {
      this.document.body.classList.toggle('p-overflow-hidden-1');
    }
    if(this.chips && this.chips.inputViewChild) {
      const inputElement = this.chips.inputViewChild.nativeElement;
      if(inputElement) {
        inputElement.value = '';
      }
    }
  }

  closeEventModal(event: any) {
    if (event && event.id) {
      this.uploadTagsForm.value.eventId = event.id;
      this.getEvents();
    }
    this.IsmodelEventShow = false;
    this.document.body.classList.add('p-overflow-hidden-1');
  }
  valueChange(value: any) {
    this.remainingText = this.totalText - value;
  }

  callFolderInfo() {
    this.isbulkFolderAiTag = false;
    if (this.selectedItems && this.selectedItems.length) {
      this.clientadminService
        .callGetQuery(
          '',
          'folder/getfolderinfo/',
          this.selectedItems[0].folderId
        )
        .subscribe((data: any) => {
          this.folderName = this.selectedItems[0].folderName;
          if (data && data.result.length && data.result[0]) {
            this.folderPath = data.result[0].id_path;
            this.assetUploadPath = this.folderPath.substring(
              0,
              this.folderPath.length - 1
            );
            this.createdBy = data.result[0].createdBy;
            // Bug 182945: DAM : Admin : User gets success toast for folder having zero assets and other than image assets after generating bulk AI tag.
            if (
              parseInt(data.result[0].assets) === 0 &&
              parseInt(data.result[0].imageCount) === 0
            ) {
              this.isbulkFolderAiTag = false;
              this.toastMsgbulkFolderAiTag =
                'The folder you are attempting to tag is empty';
            } else if (
              parseInt(data.result[0].assets) >= 1 &&
              parseInt(data.result[0].imageCount) === 0
            ) {
              this.isbulkFolderAiTag = false;
              this.toastMsgbulkFolderAiTag =
                'There is no image in the folder you are trying to tag';
            } else if (
              parseInt(data.result[0].assets) >= 1 &&
              parseInt(data.result[0].imageCount) >= 1
            ) {
              this.isbulkFolderAiTag = true;
            }
          }
        });
    }
  }

  callInitItem() {
    this.assetsIdArray = [];
    this.createdBy =
      this.selectedItems[0].firstName + ' ' + this.selectedItems[0].lastName;
    this.queryParams = {
      search: '',
      folderId: this.selectedItems[0].folderId,
      searchedBy: 1,
      assetType: [],
      sortBy: 'DESC',
      fromDate: '',
      toDate: '',
      // limit: this.myparams.limit,
      require: 'allRecords',
      pageno: this.myparams.pageno,
      tagCategory: 0,
      assetTag: this.assetTag,
    };
    this.clientadminService
      .callGetAllList('asset/getAssetsList', this.queryParams)
      .subscribe((data: any) => {
        this.totalUntagRecords = data.totalCount;
        if (data.code == 200) {
          this.assetPendingCount = data.result.filter(
            (x: any) => x.assetTag == 0
          ).length;
          this.assetCompleteCount = data.result.filter(
            (x: any) => x.assetTag == 1
          ).length;
          this.assetsDetails = data.result;
          if (!this.assetsDetails.length) {
            this.totalUntagRecords = 0;
          }
          if (this.assetsDetails && this.assetsDetails.length) {
            this.assetsDetails.forEach((element: { id: any }) => {
              this.assetsIdArray.push(element.id);
            });
          }
          this.onFolderTag();
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Warning!',
            detail: data.message,
          });
        }
      });
  }
  //============= Open create folder modal  =========//
  openCreateModal() {
    this.contextmenu = false;
    this.isCreateModalShow = true;
    this.getParentFolderAccess(this.selectedItems[0]);
  }
  //============= close create folder modal  =========//
  closeCreateModal() {
    this.isCreateModalShow = false;
    this.contextmenu = false;
    //clear folderName Value on cancle create folder popUp
    this.createSubfolderForm.get('folderName').setValue('');
  }
  //=========call Folder access api =========//
  getParentFolderAccess(item: any) {
    let folderId = item.folderId ? item.folderId : item;
    if (folderId !== 0) {
      this.clientadminService
        .callParentFolderAccess('folder/getParentFolderAccess', {
          folderId: folderId,
        })
        .subscribe((data: any) => {
          if (data.code === 200) {
            this.parentFolderList = data.result;
            this.parentFolderList.filter((newel: any) => {
              // if (this.userId === newel.assignedTo && newel.accessType === 2) {
              //   this.hasAccess = true;
              // }
            });
          }
        });
    }
  }
  //============= create folder api  =========//
  createSubfolder(frmFolder: any) {
    if (this.folderOrigin === 0) {
      this.createSubfolderForm.value.folderOrigin = this.folderOrigin;
      this.createSubfolderForm.value.parentId = this.selectedItems[0].folderId;
      this.createSubfolderForm.value.folderPath =
        this.folderPath.substring(0, this.folderPath.length - 1) + '/';
      this.parentFolderList.filter((el: any) => {
        this.folderAccessList.push({
          assignedTo: el.assignedTo,
          roleId: el.roleId,
          accessType: el.accessType,
        });
      });
      this.createSubfolderForm.value.folderAccess = this.folderAccessList;
    } else {
      this.createSubfolderForm.value.folderOrigin = this.folderOrigin;
      this.createSubfolderForm.value.parentId = this.selectedItems[0].folderId;
      if (this.selectedItems[0].folderId === 0) {
        this.folderAccessList.push({
          assignedTo: this.userDetails.userId,
          roleId: this.userDetails.roleId,
          accessType: 4,
        });
        this.createSubfolderForm.value.folderPath =
          this.createSubfolderForm.value.folderPath == null ||
          this.createSubfolderForm.value.folderPath == ''
            ? ''
            : this.createSubfolderForm.value.folderPath;
      } else {
        this.createSubfolderForm.value.folderPath =
          this.folderPath.substring(0, this.folderPath.length - 1) + '/';
        this.parentFolderList.filter((el: any) => {
          this.folderAccessList.push({
            assignedTo: el.assignedTo,
            roleId: el.roleId,
            accessType: el.accessType,
          });
        });
      }
      this.createSubfolderForm.value.folderAccess = this.folderAccessList;
    }
    if (frmFolder.valid) {
      this.clientadminService
        .callCreateQuery(this.createSubfolderForm.value, 'folder/createFolder')
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.isCreateModalShow = false;
              this.createSubfolderForm.reset();
              if (this.selectedItems[0].folderId === 0) {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  createroot: 'root',
                };
                this.folderRefreshMethod.emit(params);
              } else {
                let params = {
                  fileDetails: this.objectForSourceTrack,
                  create: 'create',
                };
                this.folderRefreshMethod.emit(params);
              }
              this.contextmenu = false;
              this.messageService.add({
                severity: 'success',
                summary: 'Successfull!!',
                detail: data.message,
              });
            }
          },
          error: (err: any) => {
            this.messageService.add({
              severity: 'warn',
              summary: 'Warning!',
              detail: err.error.message,
            });
          },
        });
    } else {
      setTimeout(function () {
        if (document.querySelector('.required')) {
          document.querySelector('required')?.scrollIntoView(true);
          window.scrollBy(0, -150);
        }
      }, 100);
    }
  }
  openAssetsModal(modalType: any) {
    this.modalType = modalType;
    this.isUploadModalClose = true;
    this.contextmenu = false;
  }
  closeModalEvent(event: any) {
    this.isUploadModalClose = false;
    this.contextmenu = false;
    if (event && this.modalType === 'zip') {
      let params = {
        fileDetails: this.objectForSourceTrack,
        zipUpload: 'zipUpload',
        folderId: event.folderId,
      };
      this.folderRefreshMethod.emit(params);
    } else if (event && this.modalType === 'image') {
      let params = { fileDetails: this.objectForSourceTrack };
      this.folderRefreshMethod.emit(params);
    } else {
      this.emptyFileList();
    }
  }
  //================= cancel btn click while assets upload============
  emptyFileList() {
    this.selectedItems = [];
    let tempParentFolder: IFolderDetails = this.objectForSourceTrack;
    while (tempParentFolder.parentId != 0) {
      tempParentFolder = this.folderMgmt.getTrackRecord(
        tempParentFolder.parentId
      );
      tempParentFolder.isCollapse = true;
    }
    this.firstItem.children.forEach((item1: any) => {
      item1.folderId === tempParentFolder.folderId
        ? tempParentFolder.isCollapse === true
        : (item1.isCollapse = false);
    });
    this.clientadminService.notifyChange(this.objectForSourceTrack);
    this.clientadminService.addAssetToSelectedAssets(new Map());
  }
  //this function works on clicking copy to button of right click
  assetCopyFuction(event: any) {
    this.contextmenu = false;
    if (this.selectedItems.length === 0) return;
    let params = {
      fileDetails: this.objectForSourceTrack,
      itemsCopyFolder: true,
      assetsHeader: 'Copy To',
      assetsFooter: 'Copy',
      assetsCopyType: 0,
      sourceFolderId: this.sourceFolderId,
      sourceLocation: this.sourceLocation,
    };
    this.refreshCopyFolder.emit(params);
  }
  //this function works on clicking move to button of right click
  assetMoveFuction(event: any) {
    this.contextmenu = false;
    if (this.selectedItems.length === 0) return;
    let params = {
      fileDetails: this.objectForSourceTrack,
      itemsCopyFolder: true,
      assetsHeader: 'Move to',
      assetsFooter: 'Move',
      assetsCopyType: 1,
      sourceFolderId: this.sourceFolderId,
      sourceLocation: this.sourceLocation,
    };
    this.refreshCopyFolder.emit(params);
  }
  //==============call Folder info api for getting source localtion=========//
  async callFolderDetails(data: any, id: any) {
    return new Promise(async (resolve) => {
      this.sourceFolderId = id;
      if (data && data.result.length && data.result[0]) {
        this.sourceLocation = data.result[0]?.id_path.substring(
          0,
          data.result[0]?.id_path.length - 1
        );
        this.accessFolderRights = data.result[0]?.accessType;
        if (this.accessFolderRights == 1) {
          this.contextMenuRightClick.nativeElement.classList.add(
            'read-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-delete-access-wrapper'
          );
        } else if (this.accessFolderRights == 2) {
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.add(
            'read-write-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-delete-access-wrapper'
          );
        } else if (this.accessFolderRights == 3) {
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.add(
            'read-write-upload-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-delete-access-wrapper'
          );
        } else if (this.accessFolderRights == 4) {
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.remove(
            'read-write-upload-access-wrapper'
          );
          this.contextMenuRightClick.nativeElement.classList.add(
            'read-write-upload-delete-access-wrapper'
          );
        }
      }
      resolve(false);
    });
  }

  //==============Folder access permission based on folderId=========//
  async getRightClickPermission(event: any, item: any) {
    //to check right click and selected item is same
    await this.permissionsEngine.setFolderlistByUserId();
    item.isCollapse &&
      this.folderContextMenuList.filter(
        (el: any) =>
          (el['hasPermission'] =
            this.permissionsEngine.folderAccessOnHasPermission(
              item.folderId,
              el.minPermission
            ))
      );
    this.onrightClick(event, item);
  }

  ngOnDestroy() {
    document
      .getElementsByTagName('body')[0]
      .classList.remove('context-menu-body-opened');
  }

  // function for share via Email

  emailShare() {
    // Encode the email body for the mailto link
    const encodedEmailBody = encodeURIComponent(this.shareFolderUrl());

    // Create the mailto link
    const mailtoLink = `mailto:?subject=${encodeURIComponent(
      'Folder Share'
    )}&body=${encodedEmailBody}`;

    // Open the mailto link in a new browser window or tab
    window.open(mailtoLink, '_blank');
  }

  // function for share via Whatsapp

  whatsappShare() {
    const sharingText = this.shareFolderUrl();
    const whatsappLink = `https://web.whatsapp.com/send/?text=${encodeURIComponent(
      sharingText
    )}`;
    window.open(whatsappLink);
  }

  // function share via gDrive

  async gdriveShareUploads() {
    let myTempParams = {
      search: '',
      folderId: this.sourceFolderId,
      searchedBy: 1,
      assetType: new Array(),
      sortBy: 'DESC',
      fromDate: '',
      toDate: '',
      pageno: 0,
      limit: 0,
      tagCategory: 0,
      assetStatus: [0, 1],
    };
    this.clientadminService
      .callGetAllList('asset/getAssetsList', myTempParams)
      .subscribe({
        next: (data: any) => {
          if (data.result.length === 0) {
            this.messageService.add({
              severity: 'warn',
              summary: 'No Asset Present',
              detail: `There is no asset present to be shared`,
            });
          } else {
            this.gdriveShare.handleAuthClickFolder(
              this.folderName,
              data.result
            );
          }
        },
        error: (error: any) => {},
      });
  }
  checkFolderAccessForModal(item: any) {
    if (this.isOpenedTrhoughModal) {
      return item.accessType != 1;
    } else {
      return true;
    }
  }
  //=============  bulk AI Tagging method =========//
  async callBulkAiTag() {
    if (this.isbulkFolderAiTag) {
      this.contextmenu = false;
      this.queryParams = {
        folderId: this.selectedItems[0].folderId,
      };
      this.messageService.add({
        severity: 'success',
        summary: 'Success!',
        detail:
          'AI tags are being generated. Please refresh later to view the tags.',
      });
      this.clientadminService
        .callGetAllList('elk/bulkAiTagging', this.queryParams)
        .subscribe({
          next: (data: any) => {
            if (data.code == 200) {
              // this.messageService.add({ severity: 'success', summary: 'Successfull!!', detail: data.message });
            }
          },
          error: (err: any) => {
            // Bug 182944: DAM : Admin : Two overlapping success and warning toasts are displayed for Bulk AI tagging.
            //this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: err.error.message });
          },
        });
    } else {
      this.contextmenu = false;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning!',
        detail: this.toastMsgbulkFolderAiTag,
      });
    }
  }

  @HostListener('document:contextmenu', ['$event'])
  onRightClick(event: MouseEvent) {
    let clickedInside = this.eRef.nativeElement.contains(event.target);
    if (!clickedInside) {
      this.contextmenu = false;
    }
  }
}
