import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  $headerSubject: Subject<any> = new Subject();
  headerData: any;

  constructor() {}

  emitHeaderSubject(data: any) {
    this.headerData = data;
    this.$headerSubject.next(data);
  }

  getHeaderData() {
    return this.headerData;
  }

  
}
