<div class="filemanagercntr sticky-top-bar">
  <div class="w-100 position-relative">
    <div class="navcntr d-flex justify-content-between position-absolute border-0 custome_context_menu_position ps-3">
      <div class="d-flex align-items-center flex-wrap" role="tablist">
        <!-- <ul class="dropdown-menu show border-0 d-flex p-0 flex-wrap create-folder-wrapper" *ngIf="
            permissionsEngine.hasPermission('folder', 'create_folder') &&
            isRootCreateFolder
          ">
          <li class="w-100">
            if folderOrigin is 1 create folder modal of core assets opens else create folder modal for all uploads opens
            <button class="dropdown-item p-0" (click)="
                folderOrigin ? openCreateModal() : openRootCreateFolderModal()" data-bs-original-title="Create Root Folder">
              <img src="assets/images/icons/ic_baseline-plus.svg" class="plus" alt="plus" />
            </button>
            <span class="hover-text-details">Create Root Folder</span>
          </li>
        </ul>
        <ul class="dropdown-menu show border-0 d-flex p-0 flex-wrap create-folder-wrapper" *ngIf="
            permissionsEngine.folderAccessOnHasPermission(
              childFolderId,
              permissionsEngine.folderAccessType[1]
            ) &&
            iszipUploadSelect &&
            isCollapse
          ">
          <li class="w-100">
            <button class="dropdown-item h-100 p-0" (click)="openCreateModal()" data-bs-original-title="Create Sub Folder">
              <svg _ngcontent-auh-c174="" xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 40 40"
                fill="none">
                <path _ngcontent-auh-c174=""
                  d="M31.6673 21.6634H21.6673V31.6634H18.334V21.6634H8.33398V18.3301H18.334V8.33008H21.6673V18.3301H31.6673V21.6634Z"
                  fill="black"></path>
              </svg>
            </button>
            <span class="hover-text-details">Create Sub Folder</span>
          </li>
        </ul> -->
        <!-- BUG-165672 Create Sub Folder with Folder Access End-->
        <ul class="upload-sec dropdown-menu show bg-transparent d-flex position-static p-0 border-0 flex-wrap my-0"
          *ngIf="
            permissionsEngine.folderAccessOnHasPermission(
              objectForSourceTrack.folderId,
              permissionsEngine.folderAccessType[1]
            ) &&
            (iszipUploadSelect || isCreateSelect) &&
            isCollapse &&
            selectedItems.length < 1
          ">
          <div class="d-flex align-items-center font-medium">
            <div class="dropdown" *ngIf="isCollapse && (isAssetView || iszipUploadSelect)">
              <button class="btn btn-primary d-btn-primary btn-upload ps__fs" type="button" data-bs-toggle="dropdown"
                aria-expanded="false">
                <img src="assets/images/icons/plus.png" />Upload
              </button>

              <ul class="dropdown-menu">
                <li *ngIf="isAssetView && isCollapse">
                  <a class="dropdown-item" (click)="openAssetsModal('image')"><span class="me-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M7.5 23C6.8125 23 6.22375 22.755 5.73375 22.265C5.24375 21.775 4.99917 21.1867 5 20.5V16.75H7.5V20.5H22.5V16.75H25V20.5C25 21.1875 24.755 21.7763 24.265 22.2663C23.775 22.7563 23.1867 23.0008 22.5 23H7.5ZM13.75 18V7.8125L10.5 11.0625L8.75 9.25L15 3L21.25 9.25L19.5 11.0625L16.25 7.8125V18H13.75Z"
                          fill="#203957" />
                      </svg> </span>Upload</a>
                </li>
                <li *ngIf="iszipUploadSelect && isCollapse">
                  <a class="dropdown-item" (click)="openAssetsModal('zip')"><span class="me-3">
                      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                        <path
                          d="M25 20.5H5V8H25M25 5.5H15L12.5 3H5C4.33696 3 3.70107 3.26339 3.23223 3.73223C2.76339 4.20107 2.5 4.83696 2.5 5.5V20.5C2.5 21.163 2.76339 21.7989 3.23223 22.2678C3.70107 22.7366 4.33696 23 5 23H25C25.663 23 26.2989 22.7366 26.7678 22.2678C27.2366 21.7989 27.5 21.163 27.5 20.5V8C27.5 7.33696 27.2366 6.70107 26.7678 6.23223C26.2989 5.76339 25.663 5.5 25 5.5ZM20 19.25H17.5V14.25H13.75L18.75 9.25L23.75 14.25H20V19.25Z"
                          fill="#203957" /></svg></span>Zip Upload</a>
                </li>
              </ul>
            </div>
          </div>
        </ul>
        <ul *ngIf="
            showAllAssetsActionButtons === true &&
            selectedItems &&
            selectedItems.length
          "
          class="viewrapper dropdown-menu show bg-transparent border-0 d-flex position-static p-0 d-flex align-items-center flex-wrap list-items-wrapper">
          <li *ngIf="
              permissionsEngine.folderAccessOnHasPermission(
                objectForSourceTrack.folderId,
                permissionsEngine.folderAccessType[2]
              )
            ">
            <button class="dropdown-item" *ngIf="
                userType != 'mediaUploader' &&
                selectedItems &&
                selectedItems.length > 1
              " (click)="menuSelection('bulkTagAsset')">
              <div class="d-flex align-items-center font-medium">
                <i class="nm_icon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="20" viewBox="0 0 22 20" fill="none">
                    <path
                      d="M21.0359 8.53175L20.2996 3.30501C20.2101 2.64955 19.8943 2.04061 19.4014 1.57326C18.9085 1.1059 18.2663 0.806432 17.5751 0.721567L12.0629 0.0233414C11.5846 -0.033643 11.0988 0.014045 10.643 0.162745C10.1871 0.311448 9.77322 0.557204 9.43309 0.881162L0.922927 9.00056C0.331947 9.56168 0 10.3223 0 11.1152C0 11.9082 0.331947 12.6688 0.922927 13.2299L7.13987 19.1249C7.73158 19.6853 8.53368 20 9.36997 20C10.2063 20 11.0084 19.6853 11.6001 19.1249L20.1102 10.9955C20.448 10.676 20.7064 10.2889 20.8666 9.86282C21.0267 9.4366 21.0845 8.98185 21.0359 8.53175ZM15.2503 7.00563C14.9382 7.00563 14.6331 6.91788 14.3736 6.75348C14.1142 6.58907 13.9119 6.3554 13.7925 6.082C13.6731 5.8086 13.6418 5.50777 13.7027 5.21753C13.7636 4.9273 13.9139 4.66069 14.1345 4.45145C14.3552 4.2422 14.6364 4.0997 14.9425 4.04197C15.2485 3.98424 15.5658 4.01386 15.8541 4.12711C16.1424 4.24036 16.3889 4.43212 16.5623 4.67818C16.7356 4.92423 16.8282 5.2135 16.8282 5.50942C16.8282 5.90624 16.662 6.28681 16.3661 6.5674C16.0702 6.848 15.6688 7.00563 15.2503 7.00563Z"
                      fill="#2C5F91" />
                  </svg>
                </i>
                <span class="icon-heading">Bulk Tag</span>
              </div>
            </button>
          </li>
          <li>
            <button class="dropdown-item" (click)="
                multiSharePopUpIsOpen == false
                  ? (multiSharePopUpIsOpen = true)
                  : (multiSharePopUpIsOpen = false)
              " [disabled]="selectedItems.length <= 1" *ngIf="
                userType != 'mediaUploader' &&
                selectedItems &&
                selectedItems.length > 1
              ">
              <div class="d-flex align-items-center font-medium">
                <i class="nm_icon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="fill: #2c5f91"
                    class="bi bi-share-fill" viewBox="0 0 16 16">
                    <path
                      d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z">
                    </path>
                  </svg>
                </i>
                <!-- // Bug-Fix-122377 :: DAM : Admin : Share pop up does not disappears when we move image from one folder to another. -->
                <span class="icon-heading" #toggleMultiShareButton>Share</span>
              </div>
            </button>
            <!-- Share Multiple Selected Assets PopUp -->
            <div class="custom-multishare-wrapper" *ngIf="Checkmultipleseledcted()" [ngClass]="{
                'openSharePopUp py-0': multiSharePopUpIsOpen,
                closeSharePopUp: !multiSharePopUpIsOpen
              }" (click)="
                [
                  multiSharePopUpIsOpen == false
                    ? (multiSharePopUpIsOpen = true)
                    : (multiSharePopUpIsOpen = false)
                ]
              ">
              <share-buttons [theme]="'circles-dark'" [include]="['whatsapp']" [show]="3"
                [url]="getAssertUrlsShareMultipleSelectedAssets()" [title]="'Share'" (click)="saveShareLink()">
              </share-buttons>
              <span class="gdrive" (click)="ShareMultipleSelectedAssetsOnGdrive()" (click)="saveShareLink()">
                <svg width="19" height="18" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_812_151258)">
                    <path
                      d="M2.11538 21.4262L3.34936 23.5576C3.60577 24.0063 3.97436 24.3589 4.40705 24.6153L8.8141 16.9871H0C0 17.4839 0.128205 17.9807 0.384615 18.4294L2.11538 21.4262Z"
                      fill="#0066DA"></path>
                    <path
                      d="M13.9904 8.01273L9.58333 0.384521C9.15064 0.640932 8.78205 0.993496 8.52564 1.44221L0.384615 15.5448C0.132921 15.9838 0.000335116 16.481 0 16.9871H8.8141L13.9904 8.01273Z"
                      fill="#00AC47"></path>
                    <path
                      d="M23.5737 24.6153C24.0064 24.3589 24.375 24.0063 24.6314 23.5576L25.1442 22.6762L27.5961 18.4294C27.8526 17.9807 27.9808 17.4839 27.9808 16.9871H19.166L21.0417 20.673L23.5737 24.6153Z"
                      fill="#EA4335"></path>
                    <path
                      d="M13.9905 8.01282L18.3976 0.384615C17.9649 0.128205 17.4681 0 16.9553 0H11.0258C10.513 0 10.0162 0.144231 9.5835 0.384615L13.9905 8.01282Z"
                      fill="#00832D"></path>
                    <path
                      d="M19.1668 16.9871H8.81428L4.40723 24.6153C4.83992 24.8717 5.33671 24.9999 5.84953 24.9999H22.1316C22.6444 24.9999 23.1412 24.8557 23.5739 24.6153L19.1668 16.9871Z"
                      fill="#2684FC"></path>
                    <path
                      d="M23.5255 8.4935L19.455 1.44221C19.1986 0.993496 18.83 0.640932 18.3973 0.384521L13.9902 8.01273L19.1665 16.9871H27.9646C27.9646 16.4903 27.8364 15.9935 27.58 15.5448L23.5255 8.4935Z"
                      fill="#FFBA00"></path>
                  </g>
                  <defs>
                    <clipPath id="clip0_812_151258">
                      <rect width="27.9808" height="25" fill="white"></rect>
                    </clipPath>
                  </defs>
                </svg>
              </span>
              <span class="copy-link" (click)="ShareMultipleSelectedAssetsOnMail()" (click)="saveShareLink()">
                <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="envelope-fill">
                    <path id="Subtract"
                      d="M1.05795 5.55538C1.3738 4.16381 2.61832 3.125 4.10547 3.125H22.8555C24.3426 3.125 25.5871 4.16381 25.903 5.55538L13.4805 13.1469L1.05795 5.55538ZM0.980469 7.33919V18.4384L10.048 12.8804L0.980469 7.33919ZM11.5452 13.7954L1.28027 20.0873C1.78141 21.1442 2.85809 21.875 4.10547 21.875H22.8555C24.1028 21.875 25.1795 21.1442 25.6807 20.0873L15.4158 13.7954L13.4805 14.9781L11.5452 13.7954ZM16.913 12.8804L25.9805 18.4384V7.33919L16.913 12.8804Z"
                      fill="white" />
                  </g>
                </svg>
              </span>
            </div>
          </li>
          <li>
            <button class="dropdown-item" (click)="menuSelection('download')" *ngIf="
                userType != 'mediaUploader' &&
                selectedItems &&
                selectedItems.length > 1
              ">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="22" viewBox="0 0 18 22" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.55273 0.90625C9.13954 0.90625 9.61524 1.36975 9.61524 1.94151V13.4181L14.1764 8.97389C14.5914 8.5696 15.2641 8.5696 15.679 8.97389C16.094 9.37818 16.094 10.0337 15.679 10.438L9.30404 16.6495C8.88911 17.0538 8.21636 17.0538 7.80143 16.6495L1.42643 10.438C1.0115 10.0337 1.0115 9.37818 1.42643 8.97389C1.84137 8.5696 2.5141 8.5696 2.92904 8.97389L7.49024 13.4181L7.49023 1.94151C7.49023 1.36975 7.96593 0.90625 8.55273 0.90625Z"
                      fill="#2C5F91" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M17.0527 20.0585C17.0527 20.6302 16.577 21.0938 15.9902 21.0938H1.11523C0.528431 21.0938 0.0527344 20.6302 0.0527344 20.0585C0.0527344 19.4867 0.528431 19.0232 1.11523 19.0232H15.9902C16.577 19.0232 17.0527 19.4867 17.0527 20.0585Z"
                      fill="#2C5F91" />
                  </svg>
                </i>
                <span class="icon-heading">Download</span>
              </div>
            </button>
          </li>
          <!--  <li>
            <button class="dropdown-item"
              *ngIf="userType!='mediaUploader'&& selectedItems && selectedItems.length===1 && selectedItems[0].assetType===0"
              (click)="menuSelection('genAiTag')">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <img src="assets/images/icons/gen-aitag.svg" alt="" class="w-21">
                </i>
                Gen. AiTag
              </div>
            </button>
          </li>
           <li><button class="dropdown-item"
              *ngIf="userType!='mediaUploader'&& selectedItems && selectedItems.length===1 && selectedItems[0].assetType===1"
              (click)="menuSelection('genTranscript')">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <img src="assets/images/icons/gen-transcript.svg" alt="">
                </i>
                Gen. Transcript
              </div>
            </button></li>
          <li>
            <button class="dropdown-item" *ngIf="userType!='mediaUploader'&& selectedItems && selectedItems.length===1 && selectedItems[0].assetStatus==1 && selectedItems[0].assetType!=2"
              (click)="menuSelection('trim/crop')">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <img src="assets/images/icons/trim-crop.svg" alt="" class="w-21">
                </i>
                Trim/Crop
              </div>
            </button>
          </li>-->
          <li>
            <button class="dropdown-item" (click)="clearAllAssests()" *ngIf="selectedItems && selectedItems.length > 1">
              <div class="d-flex align-items-center font-medium fs-16">
                <i class="nm_icon me-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                    <path d="M1.05273 1L13.0527 13M1.05273 13L13.0527 1" stroke="#2C5F91" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </i>
                <span class="icon-heading">Clear</span>
              </div>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

<div class="navcntr d-flex justify-content-between w-100 d-none">
  <div class="dropdown clearfix">
    <div class="d-flex nav nav-tabs" role="tablist">
      <ul *ngIf="
          show_assets_acn_btn === true && selectedItems && selectedItems.length
        " class="dropdown-menu show bg-transparent border-0 d-flex position-static">
        <li>
          <button class="dropdown-item" (click)="assetCopyFuction($event)">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/copy-to.svg" alt="" />
              </i>
              Copy to
            </div>
          </button>
        </li>

        <li>
          <button class="dropdown-item" (click)="assetMoveFuction($event)">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/move-to.svg" alt="" />
              </i>
              Move to
            </div>
          </button>
        </li>

        <li>
          <button class="dropdown-item" (click)="opendeleteModal()">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/recycle-gray.png" alt="" />
              </i>
              Delete
            </div>
          </button>
        </li>

        <li>
          <button class="dropdown-item" (click)="menuSelection('download')">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <svg width="15" height="19" viewBox="0 0 15 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M14.7913 6.875H10.6247V0.625H4.37467V6.875H0.208008L7.49967 14.1667L14.7913 6.875ZM0.208008 16.25V18.3333H14.7913V16.25H0.208008Z"
                    fill="#3D5876" />
                </svg>
              </i>
              Download
            </div>
          </button>
        </li>
        <li>
          <button class="dropdown-item" *ngIf="
              userType != 'mediaUploader' &&
              selectedItems &&
              selectedItems.length === 1
            " (click)="menuSelection('tagAsset')">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/tag-asset.svg" alt="" />
              </i>
              Tag Asset
            </div>
          </button>
        </li>
        <li>
          <!-- <button class="dropdown-item" *ngIf="userType!='mediaUploader'" (click)="menuSelection('share')">
                        <div class="d-flex align-items-center font-medium fs-14">
                            <i class="nm_icon me-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="fill: #505b6a;"
                                    class="bi bi-share-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z">
                                    </path>
                                </svg>
                            </i>
                            Share
                        </div>
                    </button> -->

          <button class="d-flex align-items-center font-medium btn-sm btn" (click)="
              multiSharePopUpIsOpen == false
                ? (multiSharePopUpIsOpen = true)
                : (multiSharePopUpIsOpen = false)
            " [disabled]="selectedItems.length <= 1" *ngIf="
              userType != 'mediaUploader' &&
              selectedItems &&
              selectedItems.length > 1
            ">
            <i class="nm_icon me-2">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" style="fill: #505b6a"
                class="bi bi-share-fill" viewBox="0 0 16 16">
                <path
                  d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z">
                </path>
              </svg>
            </i>
            Share
          </button>
          <!-- Share Multiple Selected Assets PopUp -->
          <div [ngClass]="{
              openSharePopUp: multiSharePopUpIsOpen,
              closeSharePopUp: !multiSharePopUpIsOpen
            }" (click)="
              [
                multiSharePopUpIsOpen == false
                  ? (multiSharePopUpIsOpen = true)
                  : (multiSharePopUpIsOpen = false)
              ]
            " style="position: absolute">
            <share-buttons [theme]="'circles-dark'" [include]="['whatsapp', 'email']" [show]="3"
              [url]="getAssertUrlsShareMultipleSelectedAssets()" [title]="'Share'"></share-buttons>
            <span class="gdrive" (click)="ShareMultipleSelectedAssetsOnGdrive()">
              <svg width="19" height="18" viewBox="0 0 28 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_812_151258)">
                  <path
                    d="M2.11538 21.4262L3.34936 23.5576C3.60577 24.0063 3.97436 24.3589 4.40705 24.6153L8.8141 16.9871H0C0 17.4839 0.128205 17.9807 0.384615 18.4294L2.11538 21.4262Z"
                    fill="#0066DA"></path>
                  <path
                    d="M13.9904 8.01273L9.58333 0.384521C9.15064 0.640932 8.78205 0.993496 8.52564 1.44221L0.384615 15.5448C0.132921 15.9838 0.000335116 16.481 0 16.9871H8.8141L13.9904 8.01273Z"
                    fill="#00AC47"></path>
                  <path
                    d="M23.5737 24.6153C24.0064 24.3589 24.375 24.0063 24.6314 23.5576L25.1442 22.6762L27.5961 18.4294C27.8526 17.9807 27.9808 17.4839 27.9808 16.9871H19.166L21.0417 20.673L23.5737 24.6153Z"
                    fill="#EA4335"></path>
                  <path
                    d="M13.9905 8.01282L18.3976 0.384615C17.9649 0.128205 17.4681 0 16.9553 0H11.0258C10.513 0 10.0162 0.144231 9.5835 0.384615L13.9905 8.01282Z"
                    fill="#00832D"></path>
                  <path
                    d="M19.1668 16.9871H8.81428L4.40723 24.6153C4.83992 24.8717 5.33671 24.9999 5.84953 24.9999H22.1316C22.6444 24.9999 23.1412 24.8557 23.5739 24.6153L19.1668 16.9871Z"
                    fill="#2684FC"></path>
                  <path
                    d="M23.5255 8.4935L19.455 1.44221C19.1986 0.993496 18.83 0.640932 18.3973 0.384521L13.9902 8.01273L19.1665 16.9871H27.9646C27.9646 16.4903 27.8364 15.9935 27.58 15.5448L23.5255 8.4935Z"
                    fill="#FFBA00"></path>
                </g>
                <defs>
                  <clipPath id="clip0_812_151258">
                    <rect width="27.9808" height="25" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
            </span>
          </div>
        </li>
        <li>
          <button class="dropdown-item" *ngIf="
              userType != 'mediaUploader' &&
              selectedItems &&
              selectedItems.length === 1 &&
              selectedItems[0].assetType === 0
            " (click)="menuSelection('genAiTag')">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/gen-aitag.svg" alt="" />
              </i>
              Gen. AiTag
            </div>
          </button>
        </li>
        <!-- <li><button class="dropdown-item"
            *ngIf="userType!='mediaUploader'&& selectedItems && selectedItems.length===1 && selectedItems[0].assetType===1"
            (click)="menuSelection('genTranscript')">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/gen-transcript.svg" alt="">
              </i>
              Gen. Transcript
            </div>
          </button></li> -->
        <li>
          <button class="dropdown-item" *ngIf="
              userType != 'mediaUploader' &&
              selectedItems &&
              selectedItems.length === 1 &&
              (selectedItems[0].assetType === 1 ||
                selectedItems[0].assetType === 0 ||
                selectedItems[0].assetType === 3)
            " (click)="menuSelection('trim/crop')">
            <div class="d-flex align-items-center font-medium fs-14">
              <i class="nm_icon me-2">
                <img src="assets/images/icons/trim-crop.svg" alt="" />
              </i>
              Trim/Crop
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</div>

<!-- Modal Copy  -->
<p-dialog class="copy-move-popup" [draggable]="false" [header]="assetsHeader" [modal]="true"
  [(visible)]="displayDialogBox" [resizable]="false" [breakpoints]="{ '500px': '90vw' }" [style]="{ width: '500px' }"
  [showHeader]="true" (onHide)="emptyFileList()">
  <div class="tree-view-nav">
    <h3 class="modal-inner-title" *ngIf="displayCoreAssetsFolderStructure === true">
      All Uploads
    </h3>
    <ul id="myUL" class="tree-ul">
      <li>
        <div>
          <app-dynamic-nested-list [items]="firstItem" [layer]="1" [isOpen]="firstItem.isCollapse"
            [id]="firstItem.folderId" [parentId]="firstItem.parentId" (refresh)="openFolder($event)">
          </app-dynamic-nested-list>
        </div>
      </li>
    </ul>
    <!-- core assets folder structure tree -->
    <h3 class="modal-inner-title" *ngIf="displayCoreAssetsFolderStructure === true">
      Core Assets
    </h3>
    <ul *ngIf="displayCoreAssetsFolderStructure === true" id="myUL" class="tree-ul">
      <li>
        <div>
          <app-dynamic-nested-list [items]="firstItemsCoreAssets" [layer]="1" [isOpen]="firstItemsCoreAssets.isCollapse"
            [id]="firstItemsCoreAssets.folderId" [parentId]="firstItemsCoreAssets.parentId"
            (refresh)="openFolder($event)">
          </app-dynamic-nested-list>
        </div>
      </li>
    </ul>
  </div>
  <div class="d-flex upload-asset-modal-btn d-gird gap-3 justify-content-md-end">
    <button type="button" class="btn btn-secondary btn-min-width" (click)="emptyFileList()"
      style="color: #116acc; border: 1px solid #116acc">
      Cancel
    </button>
    <button type="button" class="btn btn-primary btn-min-width" (click)="submitCopyData()">
      {{ assetsFooter }}
    </button>
  </div>
</p-dialog>

<!-- dialog box for delete confirmation -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isDeleteModalShow" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '600px' }" [showHeader]="false"
  (onHide)="closedeleteModal()">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h5 class="font-bold"></h5>
    <button type="button" (click)="closedeleteModal()" class="btn-close" aria-label="Close"></button>
  </div>
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content radius-24 p-3">
      <div class="modal-body">
        <h5 class="font-bold">
          Are you sure you want to delete the selected assets?
        </h5>
        <span class="d-block space-32 space-h lh_space"></span>
      </div>
      <div class="modal-footer border-0">
        <button (click)="closedeleteModal()" type="button" class="btn btn-secondary btn-min-width"
          data-bs-dismiss="modal">
          No
        </button>
        <button type="submit" class="btn btn-primary btn-min-width ms-3" (click)="onItemDeleted()">
          Yes
        </button>
      </div>
    </div>
  </div>
</p-dialog>

<!-- dialog box for upload assets -->
<app-upload *ngIf="isUploadModalClose" (closeModalEvent)="closeModalEvent($event)" [childFolderId]="childFolderId"
  [assetPathofFolder]="assetUploadPath" [modalType]="modalType" [folderOrigin]="folderOrigin"></app-upload>

<!-- dialog box for creating sub-folder -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isCreateModalShow" class="onboarduser-form" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '600px' }" [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form [formGroup]="creareSubfolderForm">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="font-regular mb-1 mt-1">Create New Folder</h3>
            <button type="button" (click)="closeCreateModal()" class="btn-close" aria-label="Close"></button>
          </div>

          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-9">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2 field-title">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" formControlName="folderName" name="folderName" required
                          maxlength="100" />
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm.controls['folderName'].hasError(
                              'required'
                            ) &&
                            creareSubfolderForm.controls['folderName'].dirty
                          ">
                          Folder Name is required
                        </div>
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm
                              .get('folderName')
                              .hasError('minlength')
                          ">
                          Folder Name should range from 2 to 100 characters
                        </div>
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm
                              .get('folderName')
                              .hasError('maxlength')
                          ">
                          Folder Name should be of less than 100 characters
                          long.
                        </div>
                        <div class="invalid-feedback fs-12 mt-2 d-block font-medium mb-2" *ngIf="
                            creareSubfolderForm
                              .get('folderName')
                              .hasError('pattern')
                          ">
                          Only - and _ are allowed as special characters.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                (click)="createSubfolder(creareSubfolderForm)" [disabled]="
                  creareSubfolderForm.invalid ||
                  (creareSubfolderForm.controls['folderName'].length >= 1 &&
                    creareSubfolderForm.controls['folderName'].length < 2 &&
                    (creareSubfolderForm.controls['folderName'].dirty ||
                      creareSubfolderForm.controls['folderName'].touched ||
                      creareSubfolderForm.submitted)) ||
                  handleButtonClicked
                ">
                Create
              </button>
              <button type="button" (click)="closeCreateModal()" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</p-dialog>

<!-- dialog box for creating folder with assignee -->
<p-dialog [modal]="true" [draggable]="false" [visible]="isRootCreateFolderModalShow" class="onboarduser-form"
  [resizable]="false" [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '800px' }"
  [showHeader]="false">
  <div class="onboard-user-form-wrapper">
    <form #frmFolder="ngForm" role="form" novalidate>
      <!-- start new html-->
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-lg">
        <div class="modal-content radius-24 p-0">
          <div class="modal-header border-0 pb-0">
            <h3 class="mb-1 font-regular">Create Folder</h3>
            <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn-close"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-4 pt-3">
            <div class="row pt-2">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-5">
                    <div class="mb-4">
                      <div class="mb-4">
                        <label class="mb-2">Folder Name<span style="color: #ff0000">*</span></label>
                        <input type="text" class="form-control" [(ngModel)]="folder.folderName" name="folderName"
                          #folderName="ngModel" id="folderName" required placeholder="Enter Folder Name"
                          pattern="^[a-zA-Z0-9_ -]+$" [disabled]="isDisabled" />
                        <div class="invalid-feedback d-block font-medium mb-2" *ngIf="folder.folderName && 
                            ((folder?.folderName?.length >= 1 &&
                              folder?.folderName?.length < 2) ||
                              folder.folderName.length > 100) &&
                            (folderName.dirty ||
                              folderName.touched ||
                              frmFolder.submitted)
                          ">
                          Folder Name should range from 2 to 100 characters
                        </div>
                        <div class="invalid-feedback d-block font-medium mb-2 textarea-error-space-msg w-75"
                          *ngIf="folderName.errors?.['pattern']">
                          Only - and _ are allowed as special characters.
                        </div>

                        <div *ngIf="folder.folderName && 
                            folderName.errors &&
                            (folderName.dirty ||
                              folderName.touched ||
                              frmFolder.submitted)
                          " class="invalid-feedback d-block font-medium mb-2">
                          <div *ngIf="folderName.errors?.['required']">
                            Folder Name is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5"></div>
                  <div class="col-12">
                    <ng-container *ngIf="folder.folderAccess.length > 1">
                      <label class="mb-2 col-lg-5">Assign to<span class="text-danger">*</span></label>
                      <label class="mb-2 col-lg-5 ps-3">Access Type<span class="text-danger">*</span></label>
                    </ng-container>
                    <div class="row" *ngFor="let row of folder.folderAccess; let i = index">
                      <!-- <div class="row" [hidden]="type==='add' && row.roleId===1"> -->
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          <span class="placeholder-text font-regular" *ngIf="!row.assignedTo">Select</span>
                          <select [hidden]="row.roleId === 1" [disabled]="
                              (row.roleId === 2 || row.roleId === 4) &&
                              row.assignedTo === this.userDetails.userId
                            " class="form-select form-control custom-select" [(ngModel)]="row.assignedTo"
                            #assignedTo="ngModel" name="assignedTo_{{ i }}" (blur)="onKeyDowns($event, i)"
                            (change)="changeUser($event, i)" required>
                            <option value="Select" disabled>Select</option>
                            <option *ngFor="let item of userList" [ngValue]="item.id" [disabled]="item.isDisabled">
                              {{ item.firstName + " " + item.lastName }} [{{item.email}}]
                            </option>
                          </select>
                          <div *ngIf="frmFolder.submitted" class="invalid-feedback d-block font-medium mb-2">
                            <div *ngIf="assignedTo.errors?.['required']">
                              Assign to is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5">
                        <div class="mb-2 position-relative">
                          <span class="placeholder-text font-regular" *ngIf="!row.accessType">Select</span>
                          <select [hidden]="row.roleId === 1" [disabled]="
                              (row.roleId === 2 || row.roleId === 4) &&
                              row.assignedTo === this.userDetails.userId
                            " class="form-select form-control custom-select"
                            class="form-select form-control custom-select" [(ngModel)]="row.accessType"
                            #accessType="ngModel" name="accessType_{{ i }}" required>
                            <option value="Select" disabled>Select</option>
                            <option *ngFor="let item1 of accessTypeList" [ngValue]="item1.id">
                              {{ item1.name }}
                            </option>
                          </select>
                          <div *ngIf="frmFolder.submitted" class="invalid-feedback d-block font-medium mb-2">
                            <div *ngIf="accessType.errors?.['required']">
                              Access Type is required
                            </div>
                          </div>
                        </div>
                      </div>
                      <div [ngClass]="i == 0 ? 'delete-row-icon' : ''"
                        class="col-md-1 d-flex justify-content-end align-items-center" *ngIf="
                          folder.folderAccess.length > 1 &&
                          !(
                            (row.roleId === 1 ||
                              row.roleId === 2 ||
                              row.roleId === 4) &&
                            row.assignedTo === userDetails.userId
                          )
                        ">
                        <a role="button" class="d-flex align-items-center ms-3 trash-row"
                          (click)="removeUserOnfolderAccessList(i)">
                          <img src="assets/images/icons/delete.svg" class="ms-1" alt="Delete" />
                        </a>
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                  <div class="col-12">
                    <a (click)="addItem()" class="text-decoration-none fs-16 font-medium dam-primary-btn-color">
                      <img src="assets/images/icons/Add-user.svg" class="ms-1" alt="add new user" />Add New User
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-start align-items-start px-4 pt-2 pb-4">
            <div class="d-grid d-flex gap-3 flex-wrap">
              <button type="button" (click)="closeRootCreateModal(frmFolder)" class="btn btn-secondary btn-min-width"
                style="color: #116acc; border: 1px solid #116acc">
                Cancel
              </button>
              <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
                [disabled]="frmFolder.invalid || !frmFolder.dirty ||(((folder?.folderName?.length >= 1 && folder?.folderName?.length < 2) || folder?.folderName?.length > 100) && (folderName.dirty || folderName.touched || frmFolder.submitted))"
                (click)="submitFormDataToCreateFolder(frmFolder)">
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- end new html-->
    </form>
  </div>
</p-dialog>
<!-- <p-toast></p-toast> -->

<!----------------Modal to tak assets in bulk (BULK TAG)------------------->
<p-dialog [modal]="true" [draggable]="false" [visible]="isBulkAssetTag"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '90%' }" [showHeader]="false"
  (onHide)="emptyFileList()" class="bulk-tagging-popup-wrapper" [resizable]="false" [draggable]="false">
  <div class="container-fluid">
    <div class="d-flex justify-content-end px-3 mt-3">
      <button type="button" (click)="goBack()" class="btn-close" aria-label="Close"></button>
    </div>
    <div class="row">
      <form [formGroup]="uploadBulkTagsForm">
        <div class="col">
          <div class="detail-card">
            <div class="row">
              <div class="col-lg-8 pe-4">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-none d-lg-block">
                    <h2 class="font-medium">BULK TAGGING</h2>
                  </div>
                  <div>
                    <button class="btn btn-outline-tag btn-min-width" style="
                        border-color: rgb(25 25 25 / 25%);
                        pointer-events: none;
                      ">
                      Total Selected: {{ selectedItems.length }}
                    </button>
                  </div>
                </div>

                <div class="card bulk-details-sec">
                  <div class="card-body bulk-details-container overflow-auto">
                    <div class="bulkdetails font-bold">
                      <ul>
                        <li *ngFor="let element of assetNameArray">
                          {{ element }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 mt-4 mt-lg-0 img-detail-sec">
                <nav>
                  <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link w-50" id="nav-home-tab" type="button" role="tab" (click)="isActiveTab = 1"
                      [ngClass]="{ active: isActiveTab === 1 }">
                      Details
                    </button>
                    <button class="nav-link w-50" id="nav-profile-tab" type="button" role="tab"
                      (click)="isActiveTab = 2" [ngClass]="{ active: isActiveTab === 2 }">
                      Tagging
                    </button>
                  </div>
                </nav>

                <div class="tab-content" id="nav-tabContent">
                  <div class="tab-pane fade" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
                    *ngIf="isActiveTab === 1" [ngClass]="{ 'show active': isActiveTab === 1 }">
                    <div class="row row flex-column d-flex">
                      <div class="col d-flex justifnav-home-taby-content-end justify-content-end">
                        <!-- <button class="btn btn-primary btn-sm new-btn mt-4"
                          (click)="isViewMode = !isViewMode; editclicked()" *ngIf="isViewMode">
                          <span class="nm_icon me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 17 15"
                              fill="none">
                              <path
                                d="M12.2034 0L10.1695 1.875L14.2373 5.625L16.2712 3.75L12.2034 0ZM8.1356 3.75L0 11.25V15H4.0678L12.2034 7.5L8.1356 3.75Z"
                                fill="white" />
                            </svg>
                          </span>
                          Edit
                        </button> -->
                      </div>

                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="!isViewMode">
                        <div class="col-12">
                          <h3 _ngcontent-vvf-c90="" class="txtheading">
                            Asset Details
                          </h3>
                          <div class="form-outline mt-3">
                            <textarea rows="3" class="form-control mt-1 mb-2" aria-describedby="assest"
                              placeholder="Title" maxlength="{{ totalText }}"
                              pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetTitle"></textarea>
                          </div>
                          <span class="remaning">{{
                              uploadBulkTagsForm.get("assetTitle").value
                                ?.length || 0
                            }}
                            / {{ totalText }}</span>
                          <div class="form-outline mt-3 mb-3">
                            <textarea rows="5" class="form-control mt-1 mb-2" aria-describedby=" AssestDescription"
                              placeholder="Description" maxlength="{{ totalText }}"
                              pattern="^[a-zA-Z0-9_,.@-]+( [a-zA-Z0-9_,.@-]+)*$"
                              formControlName="assetDescription"></textarea>
                          </div>
                          <span class="remaning">{{
                              uploadBulkTagsForm.get("assetDescription").value
                                ?.length || 0
                            }}
                            / {{ totalText }}</span>
                          <!-- <span class="font-bold mt-2" style="color: #788596; font-size: 16;">Upload Date :  15-Aug-2022</span> -->
                        </div>
                        <div>
                          <h3 class="txtheading">Event</h3>
                          <div class="row my-5">
                            <div class="col-12">
                              <div class="mb-2 fs-18-14">Event Name</div>
                            </div>
                            <div class="col-12">
                              <div class="form-check ps-0">
                                <div class="col-12">
                                  <p-dropdown [options]="eventsList" placeholder="Select an event"
                                    optionLabel="eventName" optionValue="id" formControlName="eventId"
                                    [showClear]="true" [style]="{ width: '100%' }" scrollHeight="150px"
                                    [required]="true" [filter]="true" [resetFilterOnHide]="true">
                                  </p-dropdown>
                                </div>
                              </div>
                            </div>
                            <div class="col-12 create-event-block mt-3">
                              <div class="col d-flex justify-content-end">
                                <button type="button" class="btn btn-primary d-btn-primary border-0"
                                  data-bs-toggle="modal" (click)="openEventModal()" data-bs-target="#AddEventModal">
                                  <i class="nm_icon fs-24">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                      fill="none">
                                      <g id="Add" clip-path="url(#clip0_214_22660)">
                                        <path id="Vector"
                                          d="M24 13.7143H13.7143V24H10.2857V13.7143H0V10.2857H10.2857V0H13.7143V10.2857H24V13.7143Z"
                                          fill="white" />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_214_22660">
                                          <rect width="24" height="24" fill="white" />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </i>
                                  Create Event
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 mt-5 mb-3">
                          <button type="button" class="btn btn-secondary btn-min-width me-3"
                            (click)="isViewMode = !isViewMode" style="color: #116acc; border: 1px solid #116acc">
                            Cancel
                          </button>
                          <button type="button"
                            class="btn btn-primary new-btn btn-min-width cursorpointer addtocollection"
                            (click)="SaveBulkTagInfo()" [disabled]="
                              uploadBulkTagsForm.pristine ||
                              !isuploadBulkTagsForm
                            ">
                            Save
                          </button>
                        </div>
                      </div>

                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="isViewMode">
                        <div>
                          <h3 class="txtheading">Asset Details</h3>
                          <h4 class="imgheading text-break">
                            {{ assetTitle || "No Asset Title available" }}
                          </h4>
                          <p class="imgparagrph text-break">
                            {{
                              assetDescription ||
                                "No Asset Description available"
                            }}
                          </p>
                        </div>
                        <div>
                          <h3 class="txtheading">Event</h3>
                          <table class="table table-borderless infotable">
                            <tbody>
                              <tr>
                                <td class="title">Event Name</td>
                                <td class="colon">:</td>
                                <td class="detail text-break">
                                  {{
                                    event.eventName || "No Event Name available"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td class="title">Event Date</td>
                                <td class="colon">:</td>
                                <td class="detail text-break">
                                  {{
                                    formattedDate || "No Event Date available"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td class="title">Event Location</td>
                                <td class="colon">:</td>
                                <td class="detail text-break">
                                  {{
                                    event.eventLocation ||
                                      "No Event Location available"
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td class="title">Event Description</td>
                                <td class="colon">:</td>
                                <td class="detail text-break">
                                  {{
                                    event.eventDescription ||
                                      "No Event Description available"
                                  }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab"
                    *ngIf="isActiveTab === 2" [ngClass]="{ 'show active': isActiveTab === 2 }">
                    <div class="row flex-column d-flex">
                      <div class="col d-flex justifnav-home-taby-content-end justify-content-end">
                        <button class="btn btn-primary btn-sm new-btn mt-4" (click)="isViewMode = !isViewMode"
                          *ngIf="isViewMode">
                          <span class="nm_icon me-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 17 15"
                              fill="none">
                              <path
                                d="M12.2034 0L10.1695 1.875L14.2373 5.625L16.2712 3.75L12.2034 0ZM8.1356 3.75L0 11.25V15H4.0678L12.2034 7.5L8.1356 3.75Z"
                                fill="white" />
                            </svg>
                          </span>
                          Edit
                        </button>
                      </div>
                      <div class="col-sm-12 mt-4 flex-column" *ngIf="!isViewMode">
                        <div class="col-12">
                          <div class="form-outline">
                            <label class="form-label fs-18-14" for="textAreaExample">Manual Tags:</label>
                            <div class="card border-0 radius-8 h-100 overflow-visible">
                              <div class="p-0 dam-neutral-80">
                                <div>
                                  <div class="d-grid d-flex flex-wrap gap-2 py-2">
                                    <p-chips class="w-100 bulk-tag-m" [allowDuplicate]="false"
                                      formControlName="tagArray" type="reset" placeholder="Add Tags" [required]="true"
                                      [addOnBlur]="true" [addOnTab]="true" (keydown)="onKeyDown($event)"
                                      (onAdd)="handleAdd($event)"></p-chips>
                                    <span class="note">
                                      Note: Press enter or Tab key after you
                                      added the tag name.
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 my-5">
                          <div>
                            <h5 class="mb-2 fs-18-14">Tagging Status</h5>
                            <div class="d-flex justify-content-start align-items-center">
                              <div class="radio">
                                <input id="radio-1" type="radio" class="me-1" name="assetTag" [value]="1"
                                  [(ngModel)]="assetTag" formControlName="assetTag" />
                                <label for="radio-1" class="radio-label text-nowrap">Complete</label>
                              </div>
                              <div class="radio">
                                <input id="radio-2" type="radio" class="me-1" name="assetTag" [value]="0"
                                  [(ngModel)]="assetTag" formControlName="assetTag" />
                                <label for="radio-2" class="radio-label text-nowrap">Pending</label>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 mt-5">
                            <button type="button" class="btn btn-secondary btn-min-width me-3"
                              (click)="resetBulkTagForm()" style="color: #116acc; border: 1px solid #116acc">
                              Reset
                            </button>
                            <button type="button" class="btn btn-primary new-btn btn-min-width"
                              (click)="SaveBulkTagInfo()">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="col flex-column d-flex justify-content-start img-details" *ngIf="isViewMode">
                        <div class="tags-box">
                          <h3 class="txtheading">Manual Tags</h3>
                          <div class="tags-container d-flex flex-wrap card-title manualtagcontainer"
                            *ngIf="tagArray && tagArray.length">
                            <!-- <ng-container *ngFor="let tag of shortTagArrManual">
                              <div class="tag m-tag text-break">
                                {{ tag || "No Tags available" }}
                              </div>
                            </ng-container> -->

                            <div class="tag m-tag moretags custom-tooltip-hover position-relative"
                              *ngIf="tagArray && tagArray.length > 10">
                              +{{ tagArray.length - shortTagArrManual.length }}
                              <span class="hover-text-details">{{
                                longTagArrManual
                              }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="tags-box mt-3">
                          <p class="fs-6" *ngIf="assetTag == 1">
                            Tagging status -
                            <span class="text-success">Complete</span>
                          </p>
                          <p class="fs-6" *ngIf="assetTag == 0">
                            Tagging status -
                            <span class="text-danger">Pending</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--row end-->
          </div>
          <!--detail-card end-->
          <!-- <div class="row">
            <div class="col">
              <div class="d-grid d-flex gap-3">
                <button type="button" class="btn btn-outline-primary btn-min-width"
                  (click)="goBack()" style="color: #116ACC; border: 1px solid #116ACC;">Cancel</button>
                <button type="button" class="btn btn-primary new-btn btn-min-width"
                  (click)="uploadBulkTag()">Approve</button>
              </div>
            </div>
          </div> -->
        </div>
      </form>
    </div>
  </div>
</p-dialog>

<!--  -->
<!---Modal for video/audio trimming-->
<p-dialog [modal]="true" [draggable]="false" [visible]="isTrimVideo" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '850px', height: '100%' }"
  [showHeader]="false">
  <div class="modal-header flex-column border-0 ng-tns-c54-22 align-items-start">
    <span class="d-block space-32 space-h lh_space"></span>
    <h3 class="font-bold mb-4">Trim Asset</h3>
  </div>

  <div class="container-fluid" *ngFor="let asset of selectedItems">
    <app-video-trim #trimvideo (showPreview)="showPreview($event)" assetType="{{ asset.assetType }}" [source]="
        appService?.s3BaseUrl + asset?.assetPath + '/' + asset?.assetName
          | formatS3Url
      " assetName="{{ asset?.assetName }}" isSaved="isSaved" assetPath="{{ asset?.assetPath }}"
      referenceAssetId="{{ asset?.id }}" *ngIf="asset.assetType == 1 || asset.assetType == 3" class="w-100">
    </app-video-trim>
  </div>
</p-dialog>

<!-- Preview for Image assets name-->
<p-dialog [modal]="true" [draggable]="false" [visible]="isTrimImage" [resizable]="false"
  [breakpoints]="{ '1030px': '850px', '991px': '90vw' }" [style]="{ width: '600px' }" [showHeader]="false">
  <div class="d-flex justify-content-between flex-row pt-4 pb-2">
    <h5 class="font-bold">Add Name</h5>
  </div>
  <form [formGroup]="trimImageFrom">
    <div class="row align-items-start">
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="imageName" class="form-label font-bold">Name
            <span class="invalid-feedback font-medium d-inline-block w-auto">*</span>
          </label>
          <input type="text" class="form-control" formControlName="imageName" name="imageName" required
            placeholder="Enter Name" [(ngModel)]="inputTagAssetDefaultName" />
          <div class="invalid-feedback d-block font-medium mb-2" *ngIf="
              trimImageFrom.controls['imageName'].hasError('required') &&
              trimImageFrom.controls['imageName'].dirty
            ">
            Name is required
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('minlength')">
            Name must be at least 2 characters long.
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('maxlength')">
            Name cannot exceed 100 characters.
          </div>
          <div class="invalid-feedback d-block font-medium mb-2"
            *ngIf="trimImageFrom.get('imageName').hasError('pattern')">
            Only - and _ are allowed as special characters.
          </div>
        </div>
      </div>
      <div class="col-sm">
        <div class="form-group mb-0">
          <label for="VideoName" class="form-label font-bold">Type
            <span class="invalid-feedback font-medium d-inline-block w-auto"></span>
          </label>
          <input type="text" class="form-control" placeholder="{{ imageExt ? imageExt : '' }}" disabled />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="d-grid d-flex gap-3 pt-3 flex-wrap justify-content-center">
          <button type="submit" class="btn btn-primary d-btn-primary border-0 btn-min-width"
            (click)="submitFormDataImage(trimImageFrom)" [disabled]="
              trimImageFrom.invalid ||
              (((trimImageFrom.controls['imageName'].length >= 1 &&
                trimImageFrom.controls['imageName'].length < 2) ||
                trimImageFrom.controls['imageName'].length > 100) &&
                (trimImageFrom.controls['imageName'].dirty ||
                  trimImageFrom.controls['imageName'].touched ||
                  trimImageFrom.submitted))
            ">
            Save
          </button>
          <button type=" button" (click)="closeTrimImage(trimImageFrom)"
            class="btn btn-primary justify-content-center save-cropped-img me-2" data-bs-toggle="modal"
            data-bs-target="#trimvideo">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>

<!-- dialog box for creating event -->
<app-add-event-modal *ngIf="IsmodelEventShow" (closeEvent)="closeEventModal($event)"></app-add-event-modal>
