import { Injectable } from '@angular/core';
import { ClientadminService } from 'src/app/core/services';
import { Router } from '@angular/router';
import { PermissionsEngine } from '../permission/permission';
import { LoggerService } from 'src/app/core/services';

export interface IqueryParams {
  limit: Number;
  pageno: Number;
  userId: Number;
  parentId: Number;
  isTaged?: Number;
  folderOrigin?: Number;
  isFolderSearch?: boolean;
}

export interface IFolderDetails {
  firstName: String;
  lastName: String;
  userId: Number;
  folderCount: string;
  assignedCount: String;
  parentId: Number;
  assetCounts: string;
  untaggedAssetsCount: String;
  roleId: Number;
  folderName: String;
  folderId: Number;
  isTaged: Number;
  createdAt: String;
  createByFirstname: String;
  createByLastname: String;
  accessType: Number;
  //frontEnd side variables
  isCollapse: Boolean;
  children: Array<IFolderDetails>;
}

@Injectable()
export class FolderMgmtUtill {
  private queryParam: IqueryParams = {
    limit: 1000,
    pageno: 1,
    parentId: 0,
    userId: 0,
    isTaged: 0,
    isFolderSearch: false,
  };
  private leftSideElementList: Array<IFolderDetails> = [];
  private leftSideElementList2: Array<IFolderDetails> = [];
  //core assets left side folder structure list
  private leftSideElementListCoreAssets: Array<IFolderDetails> = [];
  private rightSideElementList: Array<IFolderDetails> = [];
  private trackRecord: Map<Number, IFolderDetails> = new Map<
    Number,
    IFolderDetails
  >();

  constructor(
    private permissionsEngine: PermissionsEngine,
    private clientadminService: ClientadminService,
    private router: Router,
    private logger: LoggerService
  ) {}

  public getLeftSideElementList(): Array<IFolderDetails> {
    return this.leftSideElementList;
  }

  public getLeftSideElementList2(): Array<IFolderDetails> {
    return this.leftSideElementList2;
  }

  public getLeftSideElementListCoreAssets(): Array<IFolderDetails> {
    return this.leftSideElementListCoreAssets;
  }
  public getRightSideElementList(): Array<IFolderDetails> {
    return this.rightSideElementList;
  }

  public setLeftSideElementList(leftSideElementList: Array<IFolderDetails>) {
    this.rightSideElementList = leftSideElementList;
  }
  public setRightSideElementList(rightSideElementList: Array<IFolderDetails>) {
    this.rightSideElementList = rightSideElementList;
  }

  public setTrackRecord(folder: IFolderDetails) {
    this.trackRecord.set(folder.folderId, folder);
  }

  public getTrackRecord(parentId: Number): IFolderDetails {
    let folder = this.trackRecord.get(parentId);
    return folder == undefined ? ({} as IFolderDetails) : folder;
  }

  public async openFolder(item: IFolderDetails) {
    //toggle folder isCollapse
    this.leftSideElementList.forEach((folderitem: IFolderDetails) => {
      folderitem.folderId === item.folderId
        ? (folderitem.isCollapse = true)
        : (folderitem.isCollapse = false);
    });
    if (item.folderCount && parseInt(item.folderCount) >= 1) {
      //get folder list
      await this.setFolderMgmtRightSideElementList({
        isTaged: 0,
        limit: 1000,
        pageno: 1,
        parentId: item.folderId,
        userId: 1,
        isFolderSearch: false,
      });
      this.router.navigate([`client-admin/tagging-assets/list/`]);
    } else {
      //navigate to asset list page
      this.router.navigate([`client-admin/tagging-assets/assetList/`]);
    }
  } //openFolder

  private async getFolderListByQueryParam(
    queryParam: IqueryParams
  ): Promise<IFolderDetails[]> {
    let folderList: Array<IFolderDetails> = [];
    try {
      await new Promise((resolve, reject) =>
        setTimeout(() => {
          this.clientadminService
            .callGetAllList('folder/getMediaValidatorFoldersCount', queryParam)
            .subscribe({
              next: (data: any) => {
                if (data.code == 200) {
                  folderList = data.result;
                }
                resolve(folderList);
              },
              error: (error: any) => {
                reject(error);
              },
            });
        })
      );
    } catch (error: any) {
      // this.messageService.add({ severity: 'warn', summary: 'Warning!', detail: error.message });
      this.logger.log('folderMgmtUtill says:: ', error);
    }
    return folderList;
  } //getFolderListByQueryParam

  public async setFolderMgmtLeftSideElementList(queryParam: IqueryParams) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.leftSideElementList = data;
    });
  } //setFolderMgmtLeftSideList

  public async setFolderMgmtLeftSideElementList2(queryParam: any) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.leftSideElementList2 = data;
    });
  } //setFolderMgmtLeftSideList

  public async setFolderMgmtLeftSideElementListCoreAssets(
    queryParam: IqueryParams
  ) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.leftSideElementListCoreAssets = data;
    });
  } //setFolderMgmtLeftSideList

  public async setFolderMgmtRightSideElementList(queryParam: IqueryParams) {
    await this.getFolderListByQueryParam(queryParam).then((data) => {
      this.rightSideElementList = data;
    });
  } //setFolderMgmtLeftSideList
} //FolderMgmtUtill
