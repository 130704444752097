<div class="main-container">
    <div class="container-not-found mt-5">
        <div class="row-not-found row-cols-1 flex-column justify-content-center align-items-center">
            <div class="col col-md-6 m-auto">
                <span>
                    <img src="./assets/images/img-404.svg" class="" alt="" />
                </span>
            </div>
            <div class="col text-center" style="color: #407bff">
                <h1 class="text-uppercase font-bold">Asset not found</h1>
                <p class="py-3 fs-5 font-regular text-dark">
                    The requested asset cannot be found. It may have been deleted or is no longer available.
                </p>
            </div>
        </div>
    </div>
</div>