<div class="d-lg-block d-none">
  <span class="d-block space-64 space-h lh_space"></span> 
</div>
<footer class="footer-section dam-bg-primary-100">
  <div class="footer-inner py-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 m-auto text-center">
          <p class="mb-0 text-white fs-18 font-bold">Shri. Narendra Modi <span class="text-white font-regular">Digital Asset Management Portal</span></p>
          <!-- <h2 class="text-white fs-4 opacity-75">Shri. Narendra Modi</h2>
          <h3 class="text-white fs-2 font-bold">Digital Asset Management Portal</h3> -->
        </div>
        <div class="col-lg-8 d-none">
          <div class="row gy-4">
            <div class="col-lg-3">
              <h4 class="mb-3 text-white font-bold">Office</h4>
              <div class="text-white opacity-75">
                Web Information Manager<br>
                South Block, <br>
                Raisina Hill <br>
                New Delhi-110011
              </div>
            </div>
            <div class="col-lg-3">
              <h4 class="mb-3 text-white font-bold">Mail Us</h4>
              <ul class="list-unstyled m-0 opacity-75">
                <li class="pb-2"><a href="mailto:pmo.office@gmail.com">pmo.office@gmail.com</a></li>
                <li class="pb-2"><a href="mailto:pmo.office@gmail.com">pmo.office@gmail.com</a></li>
              </ul>
            </div>
            <div class="col-lg-3 ">
              <h4 class="mb-3 text-white font-bold">Phone</h4>
              <ul class="list-unstyled m-0 opacity-75">
                <li class="pb-2"><a href="tel:+91-2323232323">+91 - 2323232323</a></li>
                <li class="pb-2"><a href="tel:+91-3232323232">+91 - 3232323232</a></li>
              </ul>
            </div>
            <div class="col-md-3 col-6">
              <h4 class="mb-3 text-white font-bold">Follow us</h4>
              <ul class="list-unstyled m-0 d-grid d-flex gap-4">
                <li class="pb-2">
                  <a>
                    <img src="assets/images/icons/fb.png" alt="">
                  </a>
                </li>
                <li class="pb-2">
                  <a>
                    <img src="assets/images/icons/twitter.png" alt="">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom d-none">
    <div class="container-fluid">
      <div class="d-flex justify-content-lg-end py-4 footer-bottom-inner">
        <div class="d-flex align-items-center">
          <a>
            Policies
          </a>
          <div class="vr mx-2"></div>
          <a>
            Terms &amp; Conditions
          </a>
        </div>
      </div>
    </div>
  </div>


</footer>