import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/core/services/app.service';
import { environment } from 'src/app/environment/environment';
import * as CryptoJS from 'crypto-js';
import { DashboardService } from 'src/app/dashboard/dashboard.service';

@Component({
  selector: 'app-shared-asset',
  templateUrl: './shared-asset.component.html',
  styleUrls: ['./shared-asset.component.scss'],
})
export class SharedAssetComponent {
  //variables
  flagDataNotFound: Boolean = true;
  public key = environment.secretKey;
  s3AssetUrl = environment.s3BaseUrl;
  backendApiUrl = environment.apiUrl;
  encryptedAssetId: string;
  urlStr: any;
  assetDetail: {
    assetName: string;
    assetSize: number;
    assetPath: string;
    assetType: number;
    eventName: string;
    description: string;
    eventLocation: string;
    eventDate: string;
  };

  constructor(
    public appService: AppService,
    private sanitise: DomSanitizer,
    private dashboardService: DashboardService,
    private route: ActivatedRoute
  ) {
    //get encryptedAssetId
    this.encryptedAssetId =
      this.route.snapshot.paramMap.get('encryptedAssetId') + '';
  } //constructor

  ngOnInit(): void {
    this.getAssetDetails();
  } //ngOnInit

  getAssetDetails() {
    //call getSharedAssetDetails api
    this.appService
      .get('publicapis/getSharedAssetDetails/' + this.encryptedAssetId)
      .subscribe({
        next: (data: any) => {
          if (data.code == 200) {
            this.assetDetail = data.result[0];
            this.flagDataNotFound = false;
          } else {
            this.flagDataNotFound = true;
          }
        },
        error: (err) => {
          this.flagDataNotFound = true;
        }
  });
  } //getAssetDetails

  downloadAssets() {
    const a = document.createElement('a');
    a.href =
      this.backendApiUrl +
      'publicapis/shareMediaDownload/' +
      this.encryptedAssetId +
      '/' +
      this.assetDetail.assetName;
    a.download = this.assetDetail.assetName;
    a.click();
  } //downloadAssets

  docsPreviewUrl(url: any) {
    let str = this.appService.s3BaseUrl + url.assetPath + '/' + url.assetName;
    this.urlStr = this.sanitise.bypassSecurityTrustResourceUrl(
      'https://docs.google.com/gview?url=' + str
    );
    window.open(this.urlStr.changingThisBreaksApplicationSecurity, '_blank');
  } //docsPreviewUrl

  encrypt(txtToEncrypt: string): any {
    let encryptedTxt = CryptoJS.AES.encrypt(
      JSON.stringify(txtToEncrypt),
      this.key
    )
      .toString()
      .replace(/\+/g, '3r21Jk')
      .replace(/\//g, 'PoMl3rd')
      .replace(/=/g, 'l3r21L');
    return encryptedTxt;
  } //encrypt
} //SharedAssetComponent
