import { Injectable } from '@angular/core';
import { AppService } from './app.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  switchMap,
  timer,
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/app/environment/environment';
export class ImageRevStatusData {
  processed: number;
  total: number;
}
@Injectable({
  providedIn: 'root',
})
export class AppDataService {
  private flagSubject = new BehaviorSubject<ImageRevStatusData>({
    processed: 0,
    total: 0,
  });
  flag$ = this.flagSubject.asObservable();
  imageRevPollingStatus: any;
  private apiSubscription: Subscription;
  private timerSubscription: Subscription;

  constructor(private appService: AppService, private httpClient: HttpClient) {}

  getUserDetails() {
    let userData = this.appService.getUserdata();
    return this.appService.get('user/userDetails/' + userData.userId);
  }

  uploadRevImages(payload: any) {
    return this.appService.post('reverseSearch/uploadRevImages', payload, '');
  }

  getImageRevStatus() {
    return this.appService.get('reverseSearch/getStatus');
  }

  deleteImageRevJobs(payload: any) {
    return this.appService.post('reverseSearch/deleteJobs', payload, '');
  }

  startPolling(): Observable<any> {
    return timer(0, 60000).pipe(
      switchMap(() =>
        this.httpClient.get<any>(environment.apiUrl + 'reverseSearch/getStatus')
      )
    );
  }

  stopPolling(): void {
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe();
    }
  }

  startPollingFlag(): void {
    this.apiSubscription = this.startPolling().subscribe((response: any) => {
      if (response.result) {
        this.updatePollingStatus(response.result);
      } else {
        this.stopPollingFlag();
      }
    });
  }

  updatePollingStatus(data: any[]) {
    const processedCount = data.filter((item: any) => item.status === 1);
    this.flagSubject.next({
      processed: processedCount.length,
      total: data.length,
    });
  }

  stopPollingFlag(): void {
    if (this.apiSubscription) {
      this.apiSubscription.unsubscribe();
    }
    this.stopPolling();
  }

  clearFlag(): void {
    this.flagSubject.next({ processed: 0, total: 0 });
  }
}
