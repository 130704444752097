import { Injectable } from '@angular/core';
import { HttpHeaders  } from '@angular/common/http';

import { AppService } from '../core/services/app.service';

// const headers = new Headers({
//   'Content-Type': 'application/json',
//   Authorization: `Bearer ${''}`,
// });
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
@Injectable({

  providedIn: 'root'
})
export class AuthService {
  constructor(private appService:AppService) { }
authSignup(formdata:any) {
  // now returns an Observable of Config
  return this.appService.post("auth/signUp",formdata,httpOptions);
}
authLogin(formdata:any){
  return this.appService.post("login",formdata,httpOptions);
}
loginForgotPassword(formdata:any){
  return this.appService.post("login/forgotPassword",formdata,httpOptions);
}
loginChangepassword(formdata:any){
  return this.appService.post( "login/changePassword",formdata,httpOptions);
}
getRoles(){
  // let token='';
  // let http_opj=new HttpHeaders().set("Authorization","bearer"+token)
  return this.appService.get("master/getRoles");
}

refreshToken(token: string) {
  return this.appService.post('login/refreshToken',{refreshToken: token}, httpOptions);
}

callGetQuery(type: any, itemId: any) {
  return this.appService.get(type + itemId);
}
callUpdateQuery(param: any, type: any) {
  return this.appService.put(type, param)
}
getRolesConfiguration(formdata: any) {
  return this.appService.post("role/getRoles", formdata, httpOptions);

}
logout(formdata: any){
  return this.appService.post("logout", formdata, httpOptions);
}

}
