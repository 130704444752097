import { Component } from '@angular/core';

@Component({
  selector: 'app-modal-delete',
  templateUrl: './modal-delete.component.html',
  styleUrls: ['./modal-delete.component.css']
})
export class ModalDeleteComponent {
  onClose: any;
  confirmMessage: any = "Are you sure?";
  data: any = {
    name: 'this',
    field: 'Item'
  };
  
}
