<header class="header-panel header_fixed d-flex align-items-center">
  <nav class="navbar navbar-expand-lg w-100 navbar-dark">
    <div class="container-fluid">
      <div class="align-items-center d-flex header-nav-wrapper">
        <div class="header_logo">
          <a routerLink="/dashboard/home" class="navbar-brand text-white text-decoration-none">
            <span class="logo_img">
              <!-- <img src="assets/images/logo-dam.svg" alt=""> -->
              <h5 class="text-white font-bold">DAM</h5>
            </span>
            <span class="logo_text">
              Digital Asset<br class="hide_in_mobile">
              Management
            </span>
          </a>
        </div>

      </div>
    </div>
  </nav>
</header>
<div class="d-lg-block d-none">
  <span class="d-block space-64 space-h lh_space"></span>
</div>
<div class="main-container-admin">
  <div class="admin-wrapper py-5" style="background-color: #F1F1F1;">
    <span class="d-block space-32 space-h lh_space"></span>
    <div class="container-fluid" *ngIf="!flagDataNotFound">
      <div class="row">
        <div class="col">
          <div class="admin-wrapper d-flex flex-row">
            <div class="assets-folder-wrapper">
              <div class="container-fluid">
                <div class="row">
                  <div class="col">
                    <div class="detail-card">
                      <div class="row">
                        <div class="col-lg-8">
                          <div class="card detail-card-image">
                            <div class="card-body" readonly>
                              <div class="card-image overflow-hidden radius-32">
                                <div class="card-image-wrap">
                                  <img crossOrigin="anonymous" class="mw-100"
                                    src="{{s3AssetUrl}}{{assetDetail?.assetPath}}/{{assetDetail?.assetName}}" alt=""
                                    #image1 id="dowloadImage" *ngIf="assetDetail.assetType==0">
                                  <video crossOrigin="anonymous" #video
                                    src="{{s3AssetUrl}}{{assetDetail?.assetPath}}/{{assetDetail?.assetName}}"
                                    style="width: 100%;max-height: 100%;" *ngIf="assetDetail.assetType==1" controls
                                    disablePictureInPicture controlsList="noplaybackrate nodownload"></video>

                                  <div class="h-100 w-100" *ngIf="assetDetail.assetType==3">
                                    <img src="assets/images/icons/ic_audio 60 px.svg"
                                      class="mw-100 mh-100 w-25 h-auto bottom-0 end-0 m-auto" alt="Audio">
                                    <audio crossOrigin="anonymous" #video
                                      src="{{s3AssetUrl}}{{assetDetail?.assetPath}}/{{assetDetail?.assetName}}"
                                      style="width: 99%;height: calc(100% - 10px);" controls class="m-auto d-flex">
                                    </audio>
                                  </div>
                                  <img src="assets/images/icons/ic_document 60 px.svg"
                                    class="mw-100 mh-100 w-25 h-auto bottom-0 end-0 m-auto" alt="" width="" height=""
                                    *ngIf="assetDetail.assetType==2">
                                  <img src="assets/images/icons/previewDisabled.svg"
                                    class="mw-100 mh-100 w-25 h-auto bottom-0 end-0 m-auto" alt="" width="" height=""
                                    *ngIf="assetDetail.assetType==4">
                                </div>

                                <span class="card-top-overlay"></span>
                                <div class="card-img-overlay d-flex flex-column z-1 card-overlay-cta">

                                </div>
                                <span class="card-bottom-overlay"></span>
                              </div>
                              <div class="card-info d-lg-none d-block">
                                <button class="btn w-100 btn-secondary btn-min-width mb-2"
                                  (click)="docsPreviewUrl(assetDetail)" *ngIf="assetDetail.assetType==2"> Preview
                                </button>
                                <button type="button" title="Download" (click)="downloadAssets()"
                                  class="btn btn-primary w-100"> Download
                                </button>
                              </div>
                            </div>
                          </div>

                          <div class="image-info pt-4">
                            <div class="row">
                              <div class="col-lg-5">
                                <div>
                                  <table class="table table-borderless fs-14">
                                    <tr>
                                      <td class="font-medium align-top">Event Date
                                      </td>
                                      <td class="align-top">:</td>
                                      <td class="align-top text-break">
                                        {{(assetDetail.eventDate==null)?'Date
                                                                                Not
                                                                                Available':(assetDetail.eventDate+''|date
                                                                                :
                                                                                "dd-MM-yyyy":"UTC")}}</td>
                                    </tr>
                                    <tr>
                                      <td class="font-medium align-top">Location
                                      </td>
                                      <td class="align-top">:</td>
                                      <td class="align-top text-break">
                                        {{assetDetail.eventLocation || 'Data not
                                                                                available'}}</td>
                                    </tr>
                                    <tr>
                                      <td class="font-medium align-top">Folder
                                      </td>
                                      <td class="align-top">:</td>
                                      <td class="align-top text-break">
                                        {{assetDetail.assetPath || 'Data not
                                                                                available'}}</td>
                                    </tr>
                                    <tr>
                                      <td class="font-medium align-top">Size</td>
                                      <td class="align-top">:</td>
                                      <td class="align-top text-break">
                                        {{(assetDetail.assetSize/1000000) |
                                                                                number:'1.0-2'}} MB</td>
                                    </tr>
                                  </table>
                                </div>
                              </div>
                              <div class="col-lg-7">
                                <div class="description-panel text-break">
                                  <h3 class="fs-20-18 font-medium">
                                    Description
                                  </h3>
                                  <div class="fs-16-14">
                                    {{assetDetail.description || 'No data available
                                                                        to show in
                                                                        description'}}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4">
                          <div class="d-none d-lg-block">
                            <div class="row">
                              <div class="col-lg-8">
                                <div class="d-grid gap-3">
                                  <button class="btn w-100 btn-secondary mb-2" (click)="docsPreviewUrl(assetDetail)"
                                    *ngIf="assetDetail.assetType==2">
                                    Preview
                                  </button>
                                  <button type="button" title="Download" (click)="downloadAssets()"
                                    class="btn btn-secondary"> Download
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid" *ngIf="flagDataNotFound">
      <h2 class="text-center mt-5">No Data Found</h2>
    </div>
  </div>
</div>
<footer class="footer-section dam-bg-primary-100">
  <div class="footer-inner py-3">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-12 m-auto text-center">
          <p class="mb-0 text-white fs-18">Shri. Narendra Modi <span class="text-white font-bold">Digital
              Asset Management </span></p>
        </div>
        <div class="col-lg-8 d-none">
          <div class="row gy-4">
            <div class="col-lg-3">
              <h4 class="mb-3 text-white font-bold">Office</h4>
              <div class="text-white opacity-75">
                Web Information Manager<br>
                South Block, <br>
                Raisina Hill <br>
                New Delhi-110011
              </div>
            </div>
            <div class="col-lg-3">
              <h4 class="mb-3 text-white font-bold">Mail Us</h4>
              <ul class="list-unstyled m-0 opacity-75">
                <li class="pb-2"><a href="mailto:pmo.office@gmail.com">pmo.office@gmail.com</a></li>
                <li class="pb-2"><a href="mailto:pmo.office@gmail.com">pmo.office@gmail.com</a></li>
              </ul>
            </div>
            <div class="col-lg-3 ">
              <h4 class="mb-3 text-white font-bold">Phone</h4>
              <ul class="list-unstyled m-0 opacity-75">
                <li class="pb-2"><a href="tel:+91-2323232323">+91 - 2323232323</a></li>
                <li class="pb-2"><a href="tel:+91-3232323232">+91 - 3232323232</a></li>
              </ul>
            </div>
            <div class="col-md-3 col-6">
              <h4 class="mb-3 text-white font-bold">Follow us</h4>
              <ul class="list-unstyled m-0 d-grid d-flex gap-4">
                <li class="pb-2">
                  <a>
                    <img src="assets/images/icons/fb.png" alt="">
                  </a>
                </li>
                <li class="pb-2">
                  <a>
                    <img src="assets/images/icons/twitter.png" alt="">
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom d-none">
    <div class="container-fluid">
      <div class="d-flex justify-content-lg-end py-4 footer-bottom-inner">
        <div class="d-flex align-items-center">
          <a>
            Policies
          </a>
          <div class="vr mx-2"></div>
          <a>
            Terms &amp; Conditions
          </a>
        </div>
      </div>
    </div>
  </div>
</footer>
