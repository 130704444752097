import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppService } from '../core/services/app.service';
import { catchError, throwError } from 'rxjs';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  folderId: any;

  constructor(private appService: AppService, private http: HttpClient) {}

  assetsListSearch(formdata: any) {
    return this.appService
      .post('asset/getAssetsList', formdata, httpOptions)
      .pipe(catchError(this.handleError));
  }

  popularSearchList(formdata: any) {
    return this.appService.post(
      'asset/getPopularSearchList',
      formdata,
      httpOptions
    );
  }

  private handleError(error: any) {
    return throwError(error.message);
  }

  assetDetail(formdata: any) {
    return this.appService.post('asset/getAssetDetail', formdata, httpOptions);
  }

  downloadAssets(param: any , download?:any) {
    if(download){
      return this.appService.post('asset/download', param, {
        responseType: 'blob',
        // reportProgress: true,
        // observe: 'events',
        // headers: new HttpHeaders().append('content-type', 'application/json'),
      });
    }
    else{
      return this.appService.post('asset/download', param, {
        responseType: 'blob',
        reportProgress: true,
        observe: 'events',
        headers: new HttpHeaders().append('content-type', 'application/json'),
      });  
    }
    // return this.appService.get("asset/download/" + param);
    
  }

  muiltDownloadAssets(assets: any, folderID: any) {
    let params = {
      assetArr: assets,
      OriginPath: folderID,
    };
    //return this.appService.get("asset/download/" + param);
    return this.appService.post('asset/getMultiAssetDownload', params, {
      responseType: 'blob',
      headers: new HttpHeaders().append('content-type', 'application/json'),
    });
  }

  folderDownload(folderId: any, url: string) {
    let params = {
      folderId: folderId,
      folderURL: url,
    };
    //return this.appService.get("asset/download/" + param);
    return this.appService.post('folder/getFolderDownload', params, {
      responseType: 'blob',
      headers: new HttpHeaders().append('content-type', 'application/json'),
    });
  }

  saveCollections(param: any) {
    return this.appService.post(
      'collections/addToCollection',
      param,
      httpOptions
    );
  }

  reassignAssetsToACollection(param: any) {
    return this.appService.post(
      'collections/reassignAssetsToACollection',
      param,
      httpOptions
    );
  }

  getCollectionsLists(formdata: any) {
    return this.appService.get('collections/getCollectionList');
  }

  trimVideo(param: any) {
    return this.appService.post('asset/trimVideo', param, '');
  }

  downloadTranscript(param: any) {
    return this.appService.post('asset/downloadTranscript', param, {
      responseType: 'blob',
      headers: new HttpHeaders().append('content-type', 'application/json'),
    });
  }

  facefinderSearch(param: any) {
    return this.appService.post('asset/facefinderSearch', param, httpOptions);
  }

  getvideotranscription(param: any) {
    return this.appService.post('transcript/getvideotranscription', param, {
      // responseType : 'blob',
      // headers : new HttpHeaders().append('content-type','application/json')
    });
  }

  downloadtranscript(param: any) {
    return this.appService.post('transcript/downloadtranscript', param, {
      // responseType : 'blob',
      // headers : new HttpHeaders().append('content-type','application/json')
    });
  }

  prioritizeTranscription(param: any) {
    return this.appService.post('transcript/prioritizeTranscription', param, httpOptions);
  }

  transcriptStatus(param: any) {
    return this.appService.post('transcript/transcriptStatus', param, httpOptions);
  }

  parseSubtitle(srtContent: string): string[] {
    // Implement your SRT parsing logic here
    // For simplicity, let's assume each subtitle is separated by a newline character
    return srtContent.split('\n\n\n');
  }

  getSubtitle(url: string): Observable<string> {
    return this.http.get(url, { responseType: 'text' });
  }

  updateFaceFinderTags(param: any) {
    return this.appService.put('asset/updateFaceFinderTags', param);
  }
}
