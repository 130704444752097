import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppService } from '../services/app.service';
import { environment } from '../../environment/environment';

@Injectable()
export class PassTokenInterceptor implements HttpInterceptor {
  urlsToNotUse: Array<string>;

  constructor(
    private appService: AppService
  ) {
    this.urlsToNotUse = ['login'];
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.includes('/publicapis/shareMediaDownload') ||
      request.url.includes('publicapis/getSharedAssetDetails') ||
      request.url.includes('auth/passwordReset') ||
      request.url.includes('role/getRoles') ||
      request.url.includes('auth/signUp') ||
      request.url.includes(environment.s3PresignedBaseUrl) ||
      request.url.includes(environment.TRANSFER_ACCELERATED_URL) 
     // request.url.includes("https://storage.googleapis.com/jio-dam-test-bucket/")
    ) {
      return next.handle(request);
    }

    if (this.isValidRequestForInterceptor(request.url)) {
      var modifiedRequest = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.appService.getUserdata().token}`,
        },
      });
      return next.handle(modifiedRequest);
    }
    return next.handle(request);
  }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    for (let address of this.urlsToNotUse) {
      if (new RegExp(address).test(requestUrl)) {
        return false;
      }
    }
    return true;
  }
}
export const PassTokenInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: PassTokenInterceptor, multi: true },
];
