import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: 'input[type="date"][appDateMaxLength]'
})
export class DateMaxLengthDirective {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any): void {
    const inputElement: HTMLInputElement = this.el.nativeElement;
    const currentValue: string = inputElement.value;
    // Delay the execution to ensure the value is updated after (change) event
    setTimeout(() => {
      // Check if the entered value has more than 10 characters for 'YYYY-MM-DD'
      if (currentValue.length > 10) {
        inputElement.value = currentValue.slice(0, 10);
      }
      // Check if the entered value is a year and limit it to four digits
      if (/^\d{1,4}$/.test(currentValue)) {
        inputElement.value = currentValue.slice(0, 4);
      }
    });
  }
}
