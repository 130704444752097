<aside>
    <div class="assets-sidebar-nav h-100">
        <div class="card h-100 radius-24 overflow-hidden border-0">
            <div class="card-header">
                <div>Welcome <span class="font-bold">{{getUserName}}</span> </div>
                <div class="text-black font-bold">{{isUserRole}}</div>
            </div>
            <div class="card-body">
                <div class="admin-sidebar-nav">
                    <ul class="nav flex-column">
                        <ng-container *ngFor="let item of routerConfig">
                            <li class="nav-item" *ngIf="item.isShowMenu">
                                <a class="nav-link" routerLink="/{{item.url}}" routerLinkActive="active"
                                    title="{{item.label}}">
                                    <span>
                                        {{item.label}}
                                    </span></a>
                            </li>
                        </ng-container>
                        <!-- <li class="nav-item">
                            <div class="collapse-link">
                                <a class="nav-link " data-bs-toggle="collapse" href="#collapseExample" role="button"
                                    aria-expanded="false" aria-controls="collapseExample">
                                    Master Management
                                    <i class="collapse-arrow"></i>
                                </a>
                                <div class="collapse" id="collapseExample">
                                    <div class="card card-body">
                                        Some placeholder content for the collapse component. This panel is hidden by
                                        default but revealed when the user activates the relevant trigger.
                                    </div>
                                </div>
                            </div>
                        </li> -->
                        <!-- <li class="nav-item">
                            <a class="nav-link" >Recycle Bin</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="card-footer">
                <a class="logout_link font-medium d-inline-flex align-items-center" data-bs-toggle="modal" data-bs-target="#logoutModal"  >
                    <i class="nm_icon me-2">
                     <img src="assets/images/icons/logout.png " alt="">
                    </i>
                     Logout
                 </a>
            </div>
        </div>
    </div>

</aside>

<!---Modal for Logout Added -->
<div class="modal fade" id="logoutModal" tabindex="-1" aria-labelledby="logoutModalLabel" aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered">
        <div class="modal-content radius-24 p-3">
            <div class="modal-header border-0">
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <h4 class="font-bold">Are you sure you want to log out?
                </h4>
                <span class="d-block space-32 space-h lh_space"></span>
            </div>
            <div class="modal-footer border-0 ">
                <button type="button" class="btn btn-secondary btn-min-width" data-bs-dismiss="modal">No</button>
                <button type="submit" class="btn btn-primary btn-min-width" data-bs-dismiss="modal" (click)="logout()">Yes</button>
            </div>
        </div>
    </div>
</div>