import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { CalendarModule } from 'primeng/calendar';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { PaginatorModule } from 'primeng/paginator';
import { SliderModule } from 'primeng/slider';
import { ToastModule } from 'primeng/toast';
import { TreeModule } from 'primeng/tree';
import * as fromPipes from '../shared/pipes';
import * as fromComponents from './components';
import * as fromDirectives from './directives';
import { NoPreviewComponent } from './components/no-preview/no-preview.component';

@NgModule({
  declarations: [
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives,
    NoPreviewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PaginatorModule,
    NgbCollapseModule,
    PaginationModule,
    TreeModule,
    DialogModule,
    SliderModule,
    ReactiveFormsModule,
    ToastModule,
    ShareButtonsModule.withConfig({
      debug: true,
    }),
    ShareIconsModule,
    ChipsModule,
    SlickCarouselModule,
    CalendarModule,
  ],
  exports: [
    ...fromComponents.components,
    ...fromPipes.pipes,
    ...fromDirectives.directives,
  ],
})
export class SharedModule {}
