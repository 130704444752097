export class CommonUtils {
  public constructor() {}

  public isObject(arg: any) {
    return arg !== null && typeof arg === 'object';
  }
  arrayToObject(array: any) {
    array.reduce((obj: any, item: any) => {
      obj[item.id] = item;
      return obj;
    }, {});
  }

  getCharCount(str: any, letter: any) {
    let letterCount = 0;
    for (let position = 0; position < str.length; position++) {
      if (str.charAt(position) === letter) {
        letterCount += 1;
      }
    }
    return letterCount;
  }

  getKeyValuePairsFromArray(
    data: any[],
    conditionKey: string,
    conditionValue: any,
    extractKeys: any[]
  ) {
    return data
      .filter((obj) => obj[conditionKey] === conditionValue)
      .map((obj) =>
        extractKeys.reduce(
          (acc: any, key: any) => ({ ...acc, [key]: obj[key] }),
          {}
        )
      );
  }

  getValuesFromArray(
    data: any[],
    conditionKey: string,
    conditionValue: any,
    extractKey: string
  ) {
    if (conditionKey) {
      return data
        .filter((obj) => obj[conditionKey] === conditionValue)
        .map((obj) => obj[extractKey]);
    } else {
      return data.map((obj) => obj[extractKey]);
    }
  }

  scrollToAppMain() {
    let ele = document.getElementById('appmain');
    if (ele) {
      ele.scrollTo(0, 0);
    }
  }

  removeDuplicates(arr: any, key: any): any {
    const uniqueKeys = new Set();
    const uniqueItemsMap = new Map();
    for (const item of arr) {
      const keyValue = item[key];
      if (!uniqueKeys.has(keyValue)) {
        uniqueKeys.add(keyValue);
        uniqueItemsMap.set(keyValue, item);
      }
    }
    return uniqueKeys || [];
  }

  removeDuplicatesByKey(arr: any, key: any, outputKey: any) {
    const uniqueValues = new Set();
    const result = [];

    for (const item of arr) {
      const keyValue = item[key];
      if (!uniqueValues.has(keyValue)) {
        uniqueValues.add(keyValue);
        if (outputKey) {
          result.push(item[outputKey]);
        } else {
          result.push(item);
        }
      }
    }

    return result;
  }

  removeDuplicateValues(arr: any, key: any) {
    const uniqueIds = new Set<number>();
    return (arr.filter((obj:any) => {
      if(!uniqueIds.has(obj[key])){
        uniqueIds.add(obj[key]);
        return true;
      }
      return false;
    }));
  }
  getFileExt(asset: any) {
    return asset.assetName.substring(asset.assetName.lastIndexOf('.') + 1);
  }
}
