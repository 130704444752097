import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FIELDS } from 'src/app/core/constants';
import { ClientadminService } from 'src/app/core/services';

export interface IsearchWithAdvProperty {
  adv_search?: string;
  exact_phrase_search: Boolean;
  single_asset_from_single_folder: Boolean;
  approved_assets: Boolean;
  search_within: {
    ai_tags: Boolean;
    manual_tags: Boolean;
    description: Boolean;
    event: Boolean;
    location: Boolean;
    title: Boolean;
    default_tags: Boolean;
    asset_id: Boolean;
    srt:Boolean;
  };
  search_in: {
    core_assets: Boolean;
    all_uploads: Boolean;
  };
  asset_type: {
    image: Boolean;
    video: Boolean;
    audio: Boolean;
    doc: Boolean;
    other: Boolean;
  };
  asset_size: {
    size: String;
    sizeInBytes: Number;
    size_for: 'less_than' | 'greater_than' | 'equal_to' | 'null';
  };
  date: {
    start_date: String;
    end_date: String;
  };
}
export interface IElk_searchRequestBody {
  searchString: String;
  filters: Array<any>;
  range: {
    assetSize: {
      gt: Number;
      lt: Number;
    };
  }; //range
  sort: {};
  size: number;
  from: number;
  // "folderId": number
}

@Component({
  selector: 'app-advance-filters',
  templateUrl: './advance-filters.component.html',
  styleUrls: ['./advance-filters.component.scss'],
})
export class AdvanceFiltersComponent implements OnInit {
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  @Input() searchWithAdvProperty: IsearchWithAdvProperty;
  fields = FIELDS;
  filterForm: FormGroup;
  sortByAttributeName: any = '';
  sortByOrderName: any = 'desc';
  @Input() elk_searchRequestBody: IElk_searchRequestBody;
  searchInputText: any;

  constructor(
    private fb: FormBuilder,
    private clientAdminService: ClientadminService
  ) {
    const storedSearchWithAdvProperty = localStorage.getItem(
      'searchWithAdvProperty'
    );
    const storedAppliedFilter = localStorage.getItem('appliedFilter');

    if (storedAppliedFilter) {
      this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
      this.clientAdminService.setFlag(true);
    } else {
      this.searchWithAdvProperty = storedSearchWithAdvProperty
        ? JSON.parse(storedSearchWithAdvProperty)
        : {
            adv_search: '',
            exact_phrase_search: false,
            single_asset_from_single_folder: false,
            approved_assets: false,
            search_within: {
              ai_tags: false,
              manual_tags: false,
              description: false,
              event: false,
              location: false,
              title: false,
              default_tags: false,
              asset_id: false,
              srt:false,
            },
            search_in: {
              core_assets: false,
              all_uploads: false,
            },
            asset_type: {
              image: false,
              video: false,
              audio: false,
              doc: false,
              other: false,
            },
            asset_size: {
              size: '',
              size_for: 'greater_than',
            },
            date: {
              start_date: '',
              end_date: '',
            },
          };
    }
  }

  ngOnInit(): void {}

  async replaceAdvSearchWithMainSearch() {
    if (!this.searchWithAdvProperty.adv_search || this.checkDateValues() || this.searchWithAdvProperty.adv_search.trim() == '') {
      return; //adding this case to avoid the search result from enter key press when apply button is disabled
    }
    this.searchInputText =
      this.searchWithAdvProperty.adv_search == undefined
        ? ''
        : this.searchWithAdvProperty.adv_search;
    localStorage.setItem('searchtext', this.searchInputText);
    localStorage.setItem(
      'appliedFilter',
      JSON.stringify(this.searchWithAdvProperty)
    );
    this.passEntry.emit({
      type: 'apply',
      data: {
        model: this.searchWithAdvProperty,
      },
    });
    this.closeAdvanceFilters();
    // await this.callElkSearchWithResponseBody(this.elk_searchRequestBody);
  }

  closeAdvanceFilters() {
    document.getElementById('closeAdvFilter')?.click();
  }

  openAdvanceFilterModal(data: any) {
    if (data) {
      this.searchInputText = data;
      this.searchWithAdvProperty.adv_search = data;
      //initialize searchWithAdvProperty
      const storedSearchWithAdvProperty = localStorage.getItem(
        'searchWithAdvProperty'
      );
      const storedAppliedFilter = localStorage.getItem('appliedFilter');

      if (storedAppliedFilter) {
        this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
        this.searchWithAdvProperty.adv_search = data;
      } else {
        this.searchWithAdvProperty = storedSearchWithAdvProperty
          ? JSON.parse(storedSearchWithAdvProperty)
          : {
              adv_search: data,
              exact_phrase_search: false,
              single_asset_from_single_folder: false,
              approved_assets: false,
              search_within: {
                ai_tags: false,
                manual_tags: false,
                description: false,
                event: false,
                location: false,
                title: false,
                default_tags: false,
                asset_id: false,
                srt:false,
              },
              search_in: {
                core_assets: false,
                all_uploads: false,
              },
              asset_type: {
                image: false,
                video: false,
                audio: false,
                doc: false,
                other: false,
              },
              asset_size: {
                size: '',
                size_for: 'null',
              },
              date: {
                start_date: '',
                end_date: '',
              },
            };
        this.searchWithAdvProperty.adv_search = data;
      }
    }else {
      this.searchWithAdvProperty.adv_search = data;
    }
  }

  changeRadioButtonForAssetSize(value: any) {
    if (
      value == 'less_than' &&
      this.searchWithAdvProperty.asset_size.size_for == 'less_than'
    ) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
      return;
    }
    if (
      value == 'greater_than' &&
      this.searchWithAdvProperty.asset_size.size_for == 'greater_than'
    ) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
      return;
    }
  }

  convertMegabyteToByte(e: any) {
    let valueConvertedToBytes = 0;
    if (!isNaN(e)) {
      valueConvertedToBytes = e * 1000 * 1000;
    }
    this.searchWithAdvProperty.asset_size.sizeInBytes = valueConvertedToBytes;
  }

  onInputChanged(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.value !== '') {
      // Remove non-numeric and non-decimal point characters
      input.value = input.value.replace(/[^0-9.]/g, '');

      // Ensure there's only one decimal point
      const parts = input.value.split('.');
      if (parts.length > 2) {
        input.value = parts[0] + '.' + parts.slice(1).join('');
      }
      if (input.value === '.' || input.value.startsWith('.')) {
        input.value = '';
      }

      // Update the form control value
      this.searchWithAdvProperty.asset_size.size = input.value;
    }
    if (!this.searchWithAdvProperty.asset_size.size) {
      this.searchWithAdvProperty.asset_size.size_for = 'null';
    }
  }

  async resetSearchWithAdvProperty() {
    const storedSearchWithAdvProperty = localStorage.getItem(
      'searchWithAdvProperty'
    );
    const storedAppliedFilter = localStorage.getItem('appliedFilter');

    if (storedAppliedFilter) {
      this.searchWithAdvProperty = JSON.parse(storedAppliedFilter);
      // this.clientAdminService.setFlag(true);
    } else {
      this.searchWithAdvProperty = storedSearchWithAdvProperty
        ? JSON.parse(storedSearchWithAdvProperty)
        : {
            adv_search: this.searchInputText,
            exact_phrase_search: false,
            single_asset_from_single_folder: false,
            approved_assets: false,
            search_within: {
              ai_tags: false,
              manual_tags: false,
              description: false,
              event: false,
              location: false,
              title: false,
              default_tags: false,
              asset_id: false,
              srt:false,
            },
            search_in: {
              core_assets: false,
              all_uploads: false,
            },
            asset_type: {
              image: false,
              video: false,
              audio: false,
              doc: false,
              other: false,
            },
            asset_size: {
              size: '',
              size_for: '',
            },
            date: {
              start_date: '',
              end_date: '',
            },
          };
    }
    this.passEntry.emit({
      type: 'reset',
      data: {
        model: this.searchWithAdvProperty,
      },
    });
  }
  //resetting searchTextField
  resetSearchTextField() {
    if (this.searchWithAdvProperty.search_within.asset_id == true) {
      this.searchWithAdvProperty.adv_search = "";
      this.searchWithAdvProperty.exact_phrase_search = false;
      this.searchWithAdvProperty.single_asset_from_single_folder = false;
      this.searchWithAdvProperty.approved_assets = false;

      this.searchWithAdvProperty.search_within.ai_tags = false;
      this.searchWithAdvProperty.search_within.manual_tags = false;
      this.searchWithAdvProperty.search_within.description = false;
      this.searchWithAdvProperty.search_within.event = false;
      this.searchWithAdvProperty.search_within.location = false;
      this.searchWithAdvProperty.search_within.title = false;
      this.searchWithAdvProperty.search_within.default_tags = false;
      this.searchWithAdvProperty.search_within.srt = false;
   

      this.searchWithAdvProperty.search_in.core_assets = false;
      this.searchWithAdvProperty.search_in.all_uploads = false;

      this.searchWithAdvProperty.asset_type.image = false;
      this.searchWithAdvProperty.asset_type.video = false;
      this.searchWithAdvProperty.asset_type.audio = false;
      this.searchWithAdvProperty.asset_type.doc = false;
      this.searchWithAdvProperty.asset_type.other = false;

      this.searchWithAdvProperty.asset_size.size = '';
      this.searchWithAdvProperty.asset_size.size_for = 'null';

      this.searchWithAdvProperty.date.start_date = '';
      this.searchWithAdvProperty.date.end_date = '';
  }
}

onKeyDown(event: KeyboardEvent) {
  if (this.searchWithAdvProperty.search_within.asset_id) {
    const invalidChars = ['-', '+', 'e', 'E','ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'];
    if (invalidChars.includes(event.key)) {
      event.preventDefault();
    }
  }
}

onModelChange(event: any) {
    if (this.searchWithAdvProperty.search_within.asset_id) {
        this.searchWithAdvProperty.adv_search = this.searchWithAdvProperty.adv_search?.replace(/\D/g, '');
    }
}

checkDateValues(): boolean {
  const startDate: any = this.searchWithAdvProperty.date.start_date;
  const endDate: any = this.searchWithAdvProperty.date.end_date;
  if (!startDate && endDate) {
    return true;
  }
  if (startDate <= this.getCurrentDate() && endDate >= this.getStartDate() && endDate<= this.getEndDate()) {
    return false;
  }
  else{
    return true;
  }
}

getCurrentDate() {
  const currentDate = new Date();
  // Get the current date in YYYY-MM-DD format
  const year = currentDate.getFullYear();
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  const day = ('0' + currentDate.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

getStartDate(){
  return this.searchWithAdvProperty.date.start_date;
}

getEndDate(){
  const currentDate = new Date();
  // Get the current date in YYYY-MM-DD format
  const year = currentDate.getFullYear() + 1;
  const month = ('0' + (currentDate.getMonth() + 1)).slice(-2);
  const day = ('0' + currentDate.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
}

}

