<p-dialog [modal]="true" [(visible)]="show" [showHeader]="false" class="onboard-user-popup" [resizable]="false"
  [draggable]="false" [style]="{'width': '500px','boarder-radius':'5px'}" [dismissableMask]="false">
  <div class="p-3">
    <div class="border-0 pb-0 float-end">
      <button type="button" class="btn-close " aria-label="Close" (click)="close()"></button>
    </div>

    <div class="modal-body p-4 pt-3 mt-4">
      <h5 class="font-medium text-center">{{title}}</h5>
      <div class="sub-title text-center" *ngIf="subTitle">{{subTitle}}</div>
    </div>

    <div class="modal-footer justify-content-center align-items-start p-0 border-0 gap-sm-3 gap-1 mt-3">
      <button type="button" class="btn btn-primary d-btn-primary border-0 btn-min-width"
        (click)="confirm()">Yes</button>
      <button type="button" class="btn btn-secondary btn-min-width"
        (click)="close()">No</button>

    </div>
  </div>
</p-dialog>
