import { Injectable } from '@angular/core';
import { AppService } from 'src/app/core/services/app.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export interface AssetsMasterFilter {
  assetTag: String;
}

@Injectable({
  providedIn: 'root',
})
export class ClientadminService {
  //behavior subject for storing the map object send from the asset-master
  private selectedAssets = new BehaviorSubject<any>(new Map());
  selectedAssetsByUser = this.selectedAssets.asObservable();
  //function to set new map object for subscribers
  addAssetToSelectedAssets(newAsset: any) {
    this.selectedAssets.next(newAsset);
  }
  //behavior subject to share the sourcefolder information
  private shareSourceFolderInfo$ = new BehaviorSubject<any>({});
  shareSourceFolderInfoObservar$ = this.shareSourceFolderInfo$.asObservable();
  setNewSourceFolderInfo(val: any) {
    this.shareSourceFolderInfo$.next(val);
  }
  //behaviour subject to open queue list on folder download
  private openQueueList$ = new BehaviorSubject<any>({});
  openQueueListModal$ = this.openQueueList$.asObservable();
  setOpenQueueTrigger(val: any) {
    this.openQueueList$.next(val);
  }

  //behavior subject to call asset-master method
  private makeCallToMethod$ = new BehaviorSubject<any>(false);
  makeCallToTheMethodOfAssetMaster$ = this.makeCallToMethod$.asObservable();
  setToCallMethodTrigger(val: any) {
    this.makeCallToMethod$.next(val);
  }

  //behavior subject to share the sourcefolder information
  private transferApiData$ = new BehaviorSubject<any>({});
  transferApiDataObservar$ = this.shareSourceFolderInfo$.asObservable();
  setTransferApiData(val: any) {
    this.shareSourceFolderInfo$.next(val);
  }

  fileSizeUnit: number = 1024;
  public isApiSetup = true;

  constructor(
    private appService: AppService,
    private http: HttpClient,
    private router: Router
  ) {}

  private dataSource = new BehaviorSubject<any>('null');
  currentData = this.dataSource.asObservable();

  private assets = new BehaviorSubject<any>('null');
  public currentAssets = this.assets.asObservable();

  private folderIdData = new BehaviorSubject<any>('');
  public currentFolderIdData = this.folderIdData.asObservable();

  private isAdvFilterApplied: boolean = false;
  private isHeader: boolean = false;
  private assetsMasterFilter: AssetsMasterFilter;

  getFlag() {
    return this.isAdvFilterApplied;
  }

  setFlag(value: boolean) {
    this.isAdvFilterApplied = value;
  }

  setHeader(value: boolean) {
    this.isHeader = value;
  }

  getHeader() {
    return this.isHeader;
  }

  //observable created for listening to add to collection button click and its subsequent color change
  private addToCollectionSubject = new Subject<any>();
  setAddtoCollectionEvent(data: any) {
    this.addToCollectionSubject.next(data);
  }
  getAddtoCollectionEvent() {
    return this.addToCollectionSubject.asObservable();
  }
  //observable created for the use of custom-context menu
  custom_context_menu_currentAssetArray: any = [];
  private custom_context_menu_assets = new BehaviorSubject<any>('null');
  public custom_context_menu_currentAssets =
    this.custom_context_menu_assets.asObservable();
  changeValueMethod(val: boolean) {
    this.custom_context_menu_assets.next(val);
  }
  //method to get the current selected array of object for custom-context-menu
  getSelectedArray() {
    return this.custom_context_menu_currentAssetArray;
  }

  //observable created to call the method from asset master component from custom context menu
  private changeSubject = new BehaviorSubject<any>('null');
  changeOccurred = this.changeSubject.asObservable();

  notifyChange(data: any) {
    this.changeSubject.next(data);
  }

  private changeFilter = new BehaviorSubject<any>('null');
  filterChanged = this.changeFilter.asObservable();

  filterChangedNoti(data: any) {
    this.changeFilter.next(data);
  }

  changeData(data: any) {
    this.dataSource.next(data);
  }

  private dataAssets = new BehaviorSubject<any>('null');
  currentDataAssets = this.dataAssets.asObservable();

  changeDataAssets(data: any) {
    this.dataAssets.next(data);
  }
  sendData(data: any) {
    this.assets.next(data);
  }
  getData(): Observable<any> {
    return this.assets.asObservable();
  }

  getCurrentFolderDetails(data: any) {
    this.folderIdData.next(data);
  }

  getAssetsFilterState(): AssetsMasterFilter {
    return this.assetsMasterFilter;
  }

  setAssetsFilterState(filters: AssetsMasterFilter): void {
    this.assetsMasterFilter = filters;
  }

  // getUserRoles() {
  //   return this.appService.get("user/getUserRoles");
  // }

  getStatus(params: any) {
    return this.appService.post('dashboard/getStatus', params, httpOptions);
  }

  getUserRoleList(formdata: any) {
    return this.appService.post('user/getUserRoles', formdata, httpOptions);
  }
  getRoles() {
    return this.appService.get('master/getRoles');
  }
  tagUntaggedAssets(formdata: any) {
    return this.appService.post(
      'folder/getUntaggedAssetsByFolder',
      formdata,
      httpOptions
    );
  }
  getRolesConfiguration(formdata: any) {
    return this.appService.post('role/getRoles', formdata, httpOptions);
  }
  getUserListByRole(formdata: any) {
    return this.appService.post(
      'user/getUserListByRole',
      formdata,
      httpOptions
    );
  }
  userList(formdata: any) {
    return this.appService.post('user/userList', formdata, httpOptions);
  }
  getEventsList(formdata: any) {
    return this.appService.post('event/getEventsList', formdata, httpOptions);
  }
  createRole(formdata: any) {
    // now returns an Observable of Config
    return this.appService.post('master/createRole', formdata, httpOptions);
  }
  getUserListUsers() {
    return this.appService.get('user/getUserList');
  }
  // activeUser(formdata: any) {
  //   return this.appService.patch("user/active", formdata);
  // }
  getDownloadCounts() {
    return this.appService.get('asset/getDownloadCount');
  }
  getTotalAssets(formdata: any) {
    return this.appService.post(
      'asset/getAssetCountByType',
      formdata,
      httpOptions
    );
  }
  createFolder(formdata: any) {
    // now returns an Observable of Config
    return this.appService.post('folder/createFolder', formdata, httpOptions);
  }
  MediaValidatorFolders(formdata: any) {
    return this.appService.post(
      'folder/getMediaValidatorFoldersCount',
      formdata,
      httpOptions
    );
  }
  uploadAssets(formdata: any) {
    const HttpUploadOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    };
    return this.appService.post(
      'asset/uploadAssets',
      formdata,
      HttpUploadOptions
    );
  }
  getAssetsCounts(formdata: any) {
    return this.appService.post('asset/getAssetsCounts', formdata, httpOptions);
  }
  getUsersList(formdata: any) {
    return this.appService.post('asset/getUsersList', formdata, httpOptions);
  }

  deleteCollection(formdata: any) {
    return this.appService.post(
      'collections/deleteCollection',
      formdata,
      httpOptions
    );
  }

  uploadTags(formdata: any) {
    return this.appService.post('asset/uploadTags', formdata, httpOptions);
  }
  uploadBulkTags(formdata: any) {
    return this.appService.post('asset/uploadBulkTags', formdata, httpOptions);
  }
  reAssignTagToAsset(formdata: any) {
    return this.appService.post('asset/reTagToAsset', formdata, httpOptions);
  }
  reAssignEventToAsset(formdata: any) {
    return this.appService.post(
      'event/reassignEventToAsset',
      formdata,
      httpOptions
    );
  }
  getAssetsByTag(formdata: any) {
    return this.appService.post('asset/getAssetsByTag', formdata, httpOptions);
  }
  getAssetsByEvent(formdata: any) {
    return this.appService.post(
      'event/getAssetsByEvent',
      formdata,
      httpOptions
    );
  }
  approveAssets(formdata: any) {
    return this.appService.post('asset/approveAssets', formdata, httpOptions);
  }
  getTagLists(formdata: any) {
    return this.appService.post('asset/getTagList', formdata, httpOptions);
  }
  getEvents() {
    return this.appService.get('event/GetEvents');
  }
  getassetsDetails() {
    return this.appService.get('asset/getAssetsList');
  }
  getEventDetailsById() {
    return this.appService.get('event/getEventDetailsById');
  }
  createEvent(formdata: any) {
    return this.appService.post('asset/getTagList', formdata, httpOptions);
  }
  callAssetsDetailsCount(params: any) {
    return this.appService.post('asset/getAssetsCounts', params, httpOptions);
  }
  callGetDetailsQuery(param: any, type: any) {
    return this.appService.getbyId(type, param);
  }
  callGetQuery(params: any, type: any, itemId: any) {
    return this.appService.get(type + itemId);
  }
  callCreateQuery(param: any, type: any) {
    return this.appService.post(type, param, '');
  }
  callUpdateQuery(param: any, type: any) {
    return this.appService.put(type, param);
  }
  callGetAllList(url: any, params: any) {
    return this.appService.post(url, params, '');
  }
  callGetAll(type: any, params: any) {
    return this.appService.get(type);
  }
  callDeleteQuery(type: any, itemId: any) {
    return this.appService.delete(type + itemId);
  }
  uploadTrimImage(params: any) {
    return this.appService.post('asset/uploadAssets', params, '');
  }
  saveFiles(params: any) {
    return this.appService.post('asset/saveUploadsAsync', params, '');
  }
  uploadImage(params: any) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    return this.appService.post('asset/uploadAssets', params, {
      reportProgress: true,
      observe: 'events',
    });
  }
  uploadZip(params: any) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    return this.appService.post('folder/uploadzipFolder', params, {
      reportProgress: true,
      observe: 'events',
    });
  }

  uploadTrimmedAsset(params: any) {
    const header = new HttpHeaders();
    header.append('Content-Type', 'multipart/form-data');
    return this.appService.post('asset/saveTrimmedAsset', params, {
      reportProgress: true,
      observe: 'events',
    });
  }

  getFileSize(fileSize: number): number {
    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSize = parseFloat((fileSize / this.fileSizeUnit).toFixed(2));
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSize = parseFloat(
          (fileSize / this.fileSizeUnit / this.fileSizeUnit).toFixed(2)
        );
      }
    }

    return fileSize;
  }

  getFileSizeUnit(fileSize: number) {
    let fileSizeInWords = 'bytes';

    if (fileSize > 0) {
      if (fileSize < this.fileSizeUnit) {
        fileSizeInWords = 'bytes';
      } else if (fileSize < this.fileSizeUnit * this.fileSizeUnit) {
        fileSizeInWords = 'KB';
      } else if (
        fileSize <
        this.fileSizeUnit * this.fileSizeUnit * this.fileSizeUnit
      ) {
        fileSizeInWords = 'MB';
      }
    }

    return fileSizeInWords;
  }

  uploadMedia(formData: any) {
    // const headers = new HttpHeaders().set('Content-Type', 'application/json');
    const headers = new HttpHeaders();

    headers.append('Content-Type', 'multipart/form-data');

    return this.http.post('asset/uploadAssets', formData, {
      headers,
      reportProgress: true,
      observe: 'events',
    });
  }
  callParentFolderAccess(type: any, params: any) {
    return this.appService.post(type, params, httpOptions);
  }
  callCopyOrMoveAsset(type: any, params: any) {
    return this.appService.post(type, params, httpOptions);
  }
  callDeleteAssets(type: any, params: any) {
    return this.appService.post(type, params, httpOptions);
  }
  callGetApprovedAssetsCount() {
    return this.appService.post(
      'asset/getApprovedAssetsCount',
      {},
      httpOptions
    );
  }
  callGetUntaggedAssetsCount() {
    return this.appService.post(
      'asset/getUntaggedAssetsCount',
      {},
      httpOptions
    );
  }
  callCoreAssetsPendingApproveCount(params: any) {
    return this.appService.post(
      'asset/coreAssetsPendingApprovedCount',
      params,
      httpOptions
    );
  }
  coreAssetsDashboardInfo() {
    return this.appService.post(
      'asset/coreAssetsDashboardInfo',
      {},
      httpOptions
    );
  }

  callRenameFolder(params: any) {
    return this.appService.post('folder/renameFolder', params, httpOptions);
  }
  //data structure to store info of opened folder
  //behavior subject to share the sourcefolder information
  public infoOfOpenedFolder$ = new BehaviorSubject<any>([]);
  infoOfOpenedFolderObservable$ = this.infoOfOpenedFolder$.asObservable();
  arrFolderInfoDS = new Array();
  //this method is being used for adding element to the stack data structure based on some conditions
  addItemToOpenedFolderDataStruct(item: any) {
    let newSet = new Set();

    //add items children to the set
    if (item.children) {
      item.children.map((itemChild: any) => {
        newSet.add(itemChild.folderId);
      });
    }
    let tempObject = {
      folderItem: item,
      setDS: newSet,
    };
    //now add tempObject to the current array that is our stack
    let idx = this.arrFolderInfoDS.length - 1;
    let flag = true;
    while (
      idx > -1 &&
      this.arrFolderInfoDS[idx].setDS.has(item.folderId) === false
    ) {
      if (this.arrFolderInfoDS[idx].folderItem.folderId === item.folderId) {
        this.arrFolderInfoDS.pop();
        flag = false;
        break;
      } else {
        this.arrFolderInfoDS.pop();
        idx = this.arrFolderInfoDS.length - 1;
      }
    }
    if (flag === true) {
      this.arrFolderInfoDS.push(tempObject);
    }

    this.infoOfOpenedFolder$.next(this.arrFolderInfoDS);
  }
  //function to make our data structure empty.
  resetTheFolderInfoDS() {
    this.arrFolderInfoDS = new Array();
  }

  updateAssetStatus(reqbody: any) {
    return this.appService.post(
      'asset/updateAssetStatus',
      reqbody,
      httpOptions
    );
  }

  updateTags(reqbody: any) {
    return this.appService.post('asset/updateTagName', reqbody, httpOptions);
  }
  clearStorage(params: any) {
    return this.appService.post('asset/clearStorage', params, httpOptions);
  }

  uploadTagsSearchDetails(formdata: any) {
    //return this.appService.post("asset/uploadTagsSearchDetails", formdata, httpOptions);
    return this.appService.post('asset/uploadTags', formdata, httpOptions);
  }

  generateRenditions(formdata: any) {
    return this.appService.post(
      'asset/generateRenditions',
      formdata,
      httpOptions
    );
  }

  getShareDownloadHistory(formdata: any) {
    return this.appService.post(
      'dashboard/getDownloadHistory',
      formdata,
      httpOptions
    );
  }

  saveShareLink(formdata: any) {
    return this.appService.post('asset/saveShareLink', formdata, httpOptions);
  }

  multipleAssetDownload(formdata: any) {
    return this.appService.post(
      'dashboard/getQueueStatus',
      formdata,
      httpOptions
    );
  }

  syncAssetsElk(type: any, itemId: any) {
    return this.appService.get(type + itemId);
  }

  /*validation for asset title and description*/
  assetInputValidator(
    maxLength: number
  ): (control: AbstractControl) => { [key: string]: any } | null {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value && control.value.length > maxLength) {
        return { maxLengthExceeded: true };
      }
      return null;
    };
  }
  updateClusterName(param: any) {
    return this.appService.post(
      'peoplemanagement/renameCluster',
      param,
      httpOptions
    );
  }
  downloadCollection(type: any, params: any) {
    return this.appService.post(type, params, {
      responseType: 'blob',
      headers: new HttpHeaders().append('content-type', 'application/json'),
    });
  }

  getObjectUrl(assetS3Url: any) {
    try {
      const urls = new URL(assetS3Url);
      const hurl =
        urls.origin +
        assetS3Url
          .split(urls.origin)
          .filter(Boolean)
          .join('')
          .split('/')
          .map((v: any) => encodeURIComponent(v))
          .join('/');
      console.log('Encoded Object URL:', hurl);
      return hurl;
    } catch (error) {
      console.error('Error generating object URL:', error);
      return assetS3Url;
    }
  }

  downloadCategory(type: any, params: any) {
    return this.appService.post(type, params, {
      responseType: 'blob',
      headers: new HttpHeaders().append('content-type', 'application/json'),
    });
  }

  getCategories() {
    return this.appService.get('categories/getCategories');
  }
  checkFolderExist(reqbody: any) {
    return this.appService.post(
      'folder/isFolderExistInDam',
      reqbody,
      httpOptions
    );
  }

  // function to redirected to search result page
  searchResult(id: any, queryParams: any, target?: any) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/dashboard/' + id + '/search-result'], {
        queryParams: { ...queryParams },
      })
    );
    window.open(url, target ? target : '_self');
  }
}
