import {
  Component,
  ViewChild,
  ElementRef,
  Input,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
declare var MediaRecorder: any;
import { AppService } from 'src/app/core/services/app.service';
import { MessageService } from 'primeng/api';
import { ClientadminService } from 'src/app/core/services';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { FormatS3UrlPipe } from '../../pipes';

@Component({
  selector: 'app-video-trim',
  templateUrl: './video-trim.component.html',
  styleUrls: ['./video-trim.component.css'],
})
export class VideoTrimComponent {
  @ViewChild('video') videoElement: ElementRef<HTMLVideoElement> | undefined;
  @Output() showPreview = new EventEmitter();
  @Input() isTrimclicked: any;
  @Output() isvideoeditorclosed = new EventEmitter();
  private mediaSource = new MediaSource();
  private stream: any;
  private mediaRecorder: any;
  private recordedBlobs: any;
  private superBuffer: any;
  previewFile: any;
  private initTime = 0;
  public lastTime = 300;
  public initialText: String = '';
  startTimeFormatted: string = '00:00:00';
  endTimeFormatted: string = '00:00:00';
  enableRecord = false;
  startTime: number = 2;
  endTime: number = 5;
  currentTime: number;
  @ViewChild('video')
  video!: ElementRef<HTMLVideoElement>;
  //@ViewChild('video2') video2:ElementRef<HTMLVideoElement>;
  fileUrl: any;
  @Input('source')
  source!: string;
  private sourceBuffer: any;
  @Input('assetName')
  assetName!: string;
  @Input('assetPath')
  assetPath!: string;
  @Input('referenceAssetId')
  referenceAssetId!: string;
  @Input('assetType')
  assetType!: string;
  @Input('folderId') assetfolderId!: string;
  // trimSource: any;
  @Output('base64') base64: EventEmitter<any> = new EventEmitter();
  trimsliderPreview: boolean = true;
  duration = 0;
  IspreviewShow: boolean = false;
  IsVideoSave: any = 0;
  trimAssetId: any;
  videoUrl: any = '';
  videoType: any = '';
  formAssetsName: any = '';
  videoFrom: any = '';
  assetArray: any = [];
  inputTagAssetDefaultName: string = '';
  trimVideofolderId: any = 0;
  formatS3UrlPipe = new FormatS3UrlPipe();

  constructor(
    private dashboardService: DashboardService,
    private clientadminService: ClientadminService,
    public appService: AppService,
    private messageService: MessageService,
    private cdRef: ChangeDetectorRef
  ) {
    /* Add Reactive Form for trim video validation */
    this.videoFrom = new FormGroup({
      videoName: new FormControl('', [
        Validators.required,
        Validators.maxLength(100),
        Validators.minLength(2),
        // Validators.pattern("^[a-zA-Z0-9:_-]+( [a-zA-Z0-9:_-]+)*$")
      ]),
    });
  }
  //to delete trimed video/audio on right finger swipe gesture
  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.closePreviewModal();
  }
  ngOnInit() {
    this.videoFrom.controls['videoName'].enable();
    this.videoUrl = this.formatS3UrlPipe.transform(
      this.appService.s3BaseUrl + this.assetPath + '/' + this.assetName
    );
    this.videoType = this.videoUrl.substring(
      this.videoUrl.lastIndexOf('.') + 1
    );
    if (parseInt(this.assetType) === 1) {
      this.formAssetsName = 'Video Name';
    } else {
      this.formAssetsName = 'Audio Name';
    }
  }
  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.mediaSource.addEventListener(
      'sourceopen',
      this.handleSourceOpen,
      false
    );
    this.video.nativeElement.ontimeupdate = () => {
      if (
        this.lastTime &&
        this.video.nativeElement.currentTime >= this.lastTime
      ) {
        this.video.nativeElement.pause();
        if (this.enableRecord) {
          this.stopRecording();
          this.enableRecord = false;
          this.cdRef.detectChanges();
        }
      }
    };
    this.video.nativeElement.onloadeddata = () => {
      this.duration = Math.round(this.video.nativeElement.duration - 0.5);
      this.cdRef.detectChanges();
    };
  }
  secondsToHms(d: any) {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    return (
      ('0' + h).slice(-2) +
      ':' +
      ('0' + m).slice(-2) +
      ':' +
      ('0' + s).slice(-2)
    );
  }
  play() {
    this.video.nativeElement.currentTime = this.initTime;
    this.video.nativeElement.play();
    if (this.enableRecord) {
      this.startRecording();
    }
  }

  trimVideo() {
    this.enableRecord = true;
    this.play();
  }
  setTimeInit(value: any) {
    if (this.video && this.video.nativeElement.duration) {
      this.initialText = '';
      this.initTime = value;
      this.video.nativeElement.currentTime = this.initTime;
    }
  }

  setTimeLast(value: any) {
    let timeFinish = value;
    this.lastTime = timeFinish;
    let videosDummyName = this.assetName.slice(0, 76).split('.')[0];
    this.initialText =
      videosDummyName +
      '_' +
      this.secondsToSpecifiedFormat(this.initTime) +
      '_' +
      this.secondsToSpecifiedFormat(this.lastTime);
    this.videoFrom.controls['videoName'].setValue(this.initialText);
  }

  handleSourceOpen() {
    this.sourceBuffer = this.mediaSource.addSourceBuffer(
      'video/webm; codecs="vp8"'
    );
  }

  handleDataAvailable(event: any) {
    if (event.data && event.data.size > 0) {
      this.recordedBlobs.push(event.data);
    }
  }
  setCurrentTime(data: any) {
    this.currentTime = data.target.currentTime;
  }
  handleStop(event: any) {
    this.superBuffer = new Blob(this.recordedBlobs, { type: 'video/mp4' });
    //this.video2.nativeElement.src = window.URL.createObjectURL(this.superBuffer);
    const reader = new FileReader();
    reader.readAsDataURL(this.superBuffer);
    reader.onloadend = () => {
      let base64data = reader.result;
      const newstr = base64data
        .toString()
        .replace('data:video/mp4;base64,', '');
      const binaryData = atob(newstr);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
        byteArray[i] = binaryData.charCodeAt(i);
      }
      const blob = new Blob([byteArray], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = videoUrl;
      a.download = 'trimmed_' + this.assetName;
      a.click();
      URL.revokeObjectURL(videoUrl);
      const url = URL.createObjectURL(blob);
      this.showPreview.next(this.superBuffer);
    };
  }
  pause() {
    if ((<any>this.video.nativeElement).captureStream) {
      this.stream = (<any>this.video.nativeElement).captureStream();
    } else if ((<any>this.video.nativeElement).mozCaptureStream) {
      this.stream = (<any>this.video.nativeElement).mozCaptureStream();
    }
    this.mediaRecorder = new MediaRecorder(this.stream);
  }
  startRecording() {
    let options = { mimeType: 'video/webm' };
    this.recordedBlobs = [];
    if ((<any>this.video.nativeElement).captureStream) {
      this.stream = (<any>this.video.nativeElement).captureStream();
    } else if ((<any>this.video.nativeElement).mozCaptureStream) {
      this.stream = (<any>this.video.nativeElement).mozCaptureStream();
    }
    try {
      this.mediaRecorder = new MediaRecorder(this.stream, options);
    } catch (e0) {
      try {
        options = { mimeType: 'video/webm,codecs=vp9' };
        this.mediaRecorder = new MediaRecorder(this.stream, options);
      } catch (e1) {
        try {
          options = <any>'video/vp8'; // Chrome 47
          this.mediaRecorder = new MediaRecorder(this.stream, options);
        } catch (e2) {
          alert(
            'MediaRecorder is not supported by this browser.\n\n' +
              'Try Firefox 29 or later, or Chrome 47 or later, ' +
              'with Enable experimental Web Platform features enabled from chrome://flags.'
          );
          return;
        }
      }
    }
    this.mediaRecorder.onstop = (event: any) => {
      this.handleStop(event);
    };
    this.mediaRecorder.ondataavailable = (event: any) => {
      this.handleDataAvailable(event);
    };
    this.mediaRecorder.start(100); // collect 100ms of data
  }

  stopRecording() {
    this.mediaRecorder?.stop();
  }

  trimmedVideofile: any;
  submitFormData(formVideo: any) {
    this.trimsliderPreview = false;
    if (formVideo.valid) {
      let myparams = {
        trimmedAssetName: this.videoFrom.value.videoName,
        folderId: this.assetfolderId,
        referenceAssetId: parseInt(this.referenceAssetId),
        videoUrl: this.formatS3UrlPipe.transform(
          this.appService.s3BaseUrl + this.assetPath + '/' + this.assetName
        ),
        videoStartTime: this.secondsToHms(
          this.video.nativeElement.currentTime.toString()
        ),
        videoDuration: this.lastTime - this.video.nativeElement.currentTime,
      };
      this.videoFrom.controls['videoName'].disable();
      this.dashboardService.trimVideo(myparams).subscribe({
        next: (res: any) => {
          if (res.code === 200) {
            this.IsVideoSave = 1;
            this.trimAssetId = res.result.id;
            this.previewFile = this.formatS3UrlPipe.transform(
              this.appService.s3BaseUrl +
                res.result.assetPath +
                '/' +
                res.result.assetName
            );
            this.videoPreview();
          }
        },
        error: (err) => {
          this.trimsliderPreview = true;
          this.videoFrom.controls['videoName'].enable();
          this.previewFile = '';
          this.videoFrom.reset();
          this.messageService.add({
            severity: 'error',
            summary: 'Error!',
            detail: err.error.message,
          });
        },
      });
    }
  }
  videoPreview() {
    this.IspreviewShow = true;
  }
  closePreviewModal() {
    this.trimsliderPreview = true;
    this.IspreviewShow = false;
    if (this.previewFile) {
      this.videoFrom.controls['videoName'].enable();
      this.assetArray = [];
      this.assetArray.push({
        assetId: this.trimAssetId,
        folderId: this.assetfolderId,
      });
      const param: any = {
        assetArr: this.assetArray,
      };
      this.clientadminService
        .callDeleteAssets('asset/deleteAsset', param)
        .subscribe({
          next: (data: any) => {
            if (data.code === 200) {
              this.previewFile = '';
              // this.videoFrom.reset();
              // this.videoFrom.markAsPristine();
              // this.videoFrom.markAsUntouched();
            }
          },
          error: (err) => {},
        });
    }
    this.videoFrom.reset();
  }
  saveCroppedVideo() {
    this.trimsliderPreview = true;
    document
      .getElementsByTagName('body')[0]
      .classList.remove('p-overflow-hidden');
    let msg: string;
    if (parseInt(this.assetType) === 1) {
      msg = 'Video trimmed successfully!';
    } else {
      msg = 'Audio trimmed successfully!';
    }
    this.messageService.add({
      severity: 'success',
      summary: 'Success!',
      detail: msg,
    });
    this.showPreview.next(false);
    this.IspreviewShow = false;
    this.previewFile = '';
    this.isvideoeditorclosed.emit(true);
  }
  cancelVideo(videoFrom: any) {
    this.isvideoeditorclosed.emit(true);
    if (this.previewFile) {
      this.videoFrom.controls['videoName'].enable();
      this.IspreviewShow = false;
      this.showPreview.next({
        trimAssetId: this.trimAssetId,
        IsVideoSave: this.IsVideoSave,
      });
    } else {
      this.videoFrom.controls['videoName'].enable();
      this.showPreview.next(true);
    }
  }
  secondsToSpecifiedFormat(seconds: number): string {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const hDisplay = h > 0 ? (h < 10 ? '0' : '') + h + ':' : '00:';
    const mDisplay = m > 0 ? (m < 10 ? '0' : '') + m + ':' : '00:';
    const sDisplay = s > 0 ? (s < 10 ? '0' : '') + s : '00';
    const mSDisplay = ':00';
    return hDisplay + mDisplay + sDisplay + mSDisplay;
  }
}
